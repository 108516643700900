@import "common/colors.sass";
@import "common/mixins.sass";
@import "common/variables.sass";

#task-wrapper {
	// #task-expanded-wrapper

	/* buttons */
	.row-buttons {
		[class*='col-'] {
			&:nth-child(3), &:nth-child(4) {
				padding-top: 10px; }
			@media all and (min-width: $screen-sm) {
				&:nth-child(3), &:nth-child(4) {
					padding-top: 0; } }
			@media all and (min-width: $screen-lg) {
				&:nth-child(3), &:nth-child(4) {
					padding-top: 10px; } } } } }

.task-container {
	padding: 10px 20px;
	background-color: white;
	border: 1px solid $gray-lighter;
	// @include specanim(max-height, 300ms, ease)
	&, &.selected-remove, &.selected-remove-active {
 }		// max-height: 60px
	&.selected {
 }		// max-height: 600px
	&:hover, &:active, &.active, &:focus {
		border: 1px solid rgba($highlight, 0.65);
		cursor: pointer; }
	&.selected {
		border-color: rgba($highlight, 0.65); } }

// task expanded
.form-replacer {
	font-size: 1.0666666666666em;
	padding: 0.25em 0 0 0;
	color: $gray-base;
	&.big-form-replacer {
		font-size: 1.25em; }
	.pale {
		color: $text-color; } }

.toggleCc {
	float: right; }

.cc-reply {
	float: right; }

.gray-task-row {
	margin-left: -20px;
	margin-right: -20px;
	margin-bottom: -10px;
	padding-bottom: 15px;
	padding-top: 15px;
	background-color: #f2f2f2;
	@media screen and (min-width: 1200px) {
		margin-top: -10px;
		margin-left: -10px;
		margin-bottom: -10px;
		padding-top: 5px;
		min-height: 320px; } }

.form-control.big-input.no-underline {
	border-bottom-style: none; }

.scroller {
	overflow-y: hidden;
	position: relative;
	div {
		white-space: normal; } }

.black {
	color: #000; }

a.sort-dir, a.sort-btn {
	padding-left: 5px;
	padding-right: 1px;
	text-decoration: none;
	font-weight: bold;
	color: $gray-light2; }

a.sort-dir {
	top: 10px;
	display: inline-block; }

a.sort-dir:hover {
	text-decoration: none;
	cursor: pointer; }

a.sort-dir:hover {
	color: $gray-light2; }

.sort-dir.noshow {
	visibility: hidden; }

.radio.vertical {
	display: block;
	margin-bottom: 10px; }

/* Inbox list  - centers task description vertically */

.h2-container {
	position: relative;
	width: auto;
	height: 38px;
	margin-left: 40px;
	&.no-left-margin {
		margin-left: 0; }

	h2.h2-task {
		display: block;
		position: absolute;
		width: 100%;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		margin: 0;
		font-size: 18px;
		line-height: 1.25;
		color: $gray-base; } }

.hidden-all {
	display: none; }

.task-checkbox {
	cursor: pointer; }

label.task-checkbox.large {
	margin-top: 0; }

.task-checkbox {
	float: left;
	margin: 0; }

.task-checkbox .mt-checkbox, .checkbox-icon {
	position: relative;
	width: 28px;
	height: 28px;
	display: inline-block;
	border: 0;
	background: transparent;
	cursor: pointer;
	-webkit-appearance: none; }

.task.row .task-checkbox .mt-checkbox {
	top: 5px; }

.task-checkbox.large .mt-checkbox {
	width: 40px;
	height: 40px;
	margin-left: -5px;
	margin-top: 0;
	padding-top: 0; }

.task-checkbox .mt-checkbox:before, .checkbox-icon:before {
	display: table;
	width: 100%;
	height: 100%;
	border-width: 1px;
	border-style: solid;
	border-radius: 28px;
	background: #fff;
	content: ' ';
	-webkit-transition: background-color 20ms ease-in-out;
	transition: background-color 20ms ease-in-out; }

// Checkbox styling
.task-checkbox .mt-checkbox.checked:after {
	-webkit-transition: opacity 200ms ease-in-out;
	transition: opacity 200ms ease-in-out;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	position: absolute;
	top: 33%;
	left: 25%;
	display: table;
	width: 14px;
	height: 6px;
	border: 2px solid transparent;
	border-top: 0;
	border-right: 0;
	content: ' '; }

// Turn off check for all except ACCEPTED and COMPLETED
.task-checkbox .mt-checkbox.task-completed.checked:after {
	border-color: #fff; }


//- Cancelled checkbox styling
.task-checkbox .mt-checkbox.task-cancelled:after {
	content: '\F00D';
	font: normal normal normal 1.5em/1 FontAwesome;
	position: absolute;
	top: 3px;
	left: 5px;
	width: 1.5em;
	height: 1.5em;
	color: rgba(#ababab, 0.4); }

.codeblue {
	&.task-checkbox .mt-checkbox.task-cancelled:after {
		color: rgba($codeBLUE, 0.4); } }
.met {
	&.task-checkbox .mt-checkbox.task-cancelled:after {
		color: rgba($MET, 0.4); } }
.premet {
	&.task-checkbox .mt-checkbox.task-cancelled:after {
		color: rgba($preMET, 0.4); } }
.urgent {
	&.task-checkbox .mt-checkbox.task-cancelled:after {
		color: rgba($urgent, 0.4); } }


.task-checkbox .mt-checkbox.task-new.checked:after {
	border: none; }

.task-checkbox.large .mt-checkbox:after {
	width: 20px;
	height: 8px;
	border-width: 2px; }

.task-checkbox .mt-checkbox:before, .checkbox .checkbox-icon:before {
	-o-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	border-color: #ababab;
	border-width: 2px;
	border-style: solid; }

*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box; }

.task-checkbox .mt-checkbox:before, .task-checkbox .checkbox-icon:before {
	@include border-radius(3px);
	border-color: #ababab;
	border-width: 2px;
	border-style: solid; }

.task-checkbox .mt-checkbox.task-new:before {
	border-style: dashed; }

.task-checkbox {
	width: 42px; }

.label {
	font-family: 'Helvetica Neue', Helvetica, 'Segoe UI', Arial, sans-serif;
	display: inline-block;
	padding: 3px 5px;
	margin-right: 4px;
	margin-left: 4px;
	font-size: 11px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 13px;
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
	white-space: nowrap;
	vertical-align: baseline;
	background-color: rgba(black, 0.3);
	&.label-new {
		background-color: rgba(black, 0.5); }
	@include border-radius(3px);
	&:last-child {
		margin-right: 0; }
	&.outline {
		background-color: transparent;
		text-shadow: none;
		text-transform: uppercase;
		padding: 0 2px;
		color: rgba(black, 0.25);
		border: 1px solid rgba(black, 0.1); } }

.label {
	&.premet, &.met, &.urgent, &.codeblue {
		color: white; }
	&.codeblue {
		background-color: $codeBLUE; }
	&.met {
		background-color: $MET; }
	&.premet {
		background-color: $preMET; }
	&.urgent {
		background-color: $urgent; } }

.white-container.input-search {
	padding-top: 10px; }

article.observations {
	margin-top: 22px;
	font-size: 15px;
	color: $gray-copy;
	.responsive-inliner {
		display: inline;
		@media screen and (min-width: 1200px) and (max-width: 1300px) {
			display: block;
			margin-left: 27px; } } }

article.custom {
	padding: 10px;
	color: $gray-copy;
	border: 1px solid #d4d4d4;
	background-color: #f2f2f2;
	margin-bottom: 8px;
	.custom-label {
		font-size: 13px;
		font-weight: bold; }
	.custom-value, .custom-coding {
		font-size: 13px;
		color: #333; }

	.custom-multicoding {
		display: inline-block;
		background-color: $gray-light5;
		color: #333;
		border-radius: 3px;
		margin-right: 5px;
		margin-bottom: 4px;
		position: relative;
		top: 1px;
		font-size: 12px;
		padding: 2px 6px 2px 6px; } }

article.task-description {
	color: black;
	margin: 0 0 10px 0;
	width: auto;

	&.urgent {
		background: repeating-linear-gradient(180deg, rgba($urgent, 0.05), rgba($urgent, 0.05) 29px, rgba($urgent, 0.2) 29px, rgba($urgent, 0.2) 30px);
		@include box-shadow-2(0 0 0 1px rgba($urgent, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35)); }
	&.premet {
		background: repeating-linear-gradient(180deg, rgba($preMET, 0.05), rgba($preMET, 0.05) 29px, rgba($preMET, 0.2) 29px, rgba($preMET, 0.2) 30px);
		@include box-shadow-2(0 0 0 1px rgba($preMET, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35)); }
	&.met {
		background: repeating-linear-gradient(180deg, rgba($MET, 0.05), rgba($MET, 0.05) 29px, rgba($MET, 0.2) 29px, rgba($MET, 0.2) 30px);
		@include box-shadow-2(0 0 0 1px rgba($MET, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35)); }
	&.codeblue {
		background: repeating-linear-gradient(180deg, rgba($codeBLUE, 0.05), rgba($codeBLUE, 0.05) 29px, rgba($codeBLUE, 0.2) 29px, rgba($codeBLUE, 0.2) 30px);
		@include box-shadow-2(0 0 0 1px rgba($codeBLUE, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35)); }

	h3.description {
		line-height: 1;
		margin-bottom: 4px; }
	.due-timeframe {
		font-size: 0.8em;
		color: $gray-copy; } }

.item-checkbox.checkbox-bg {
	background-color: #f7f7f7; }

.unspecified-patient {
	margin-top: 6px;
	color: $gray-copy; }

.strike {
	text-decoration: line-through; }

.new-task {
	font-weight: 700; }

.codeblue {
	&.task-checkbox .mt-checkbox:before, &.task-checkbox .checkbox-icon:before {
		border-color: $codeBLUE;
		background-color: rgba($codeBLUE, 0.4); } }

.met {
	&.task-checkbox .mt-checkbox:before, &.task-checkbox .checkbox-icon:before {
		border-color: $MET;
		background-color: rgba($MET, 0.4); } }

.premet {
	&.task-checkbox .mt-checkbox:before, &.task-checkbox .checkbox-icon:before {
		border-color: $preMET;
		background-color: rgba($preMET, 0.4); } }

.urgent {
	&.task-checkbox .mt-checkbox:before, &.task-checkbox .checkbox-icon:before {
		border-color: $urgent;
		background-color: rgba($urgent, 0.4); } }

.routine {
	&.task-checkbox .mt-checkbox.disabled:before, &.task-checkbox .checkbox-icon.disabled:before {
		opacity: 0.5;
		background-color: transparent; } }

.codeblue, .met, .premet, .urgent {
	&.task-checkbox .mt-checkbox.disabled:before, &.task-checkbox .checkbox-icon.disabled:before {
		opacity: 0.5; } }

.codeblue {
	&.task-checkbox .mt-checkbox.task-new.checked:before, &.task-checkbox .checkbox-icon.task-new.checked:before {
		background-color: rgba($codeBLUE, 0.4); }
	&.task-checkbox .mt-checkbox.checked:before, &.task-checkbox .checkbox-icon.checked:before {
		background-color: $codeBLUE; } }

.met {
	&.task-checkbox .mt-checkbox.task-new.checked:before, &.task-checkbox .checkbox-icon.task-new.checked:before {
		background-color: rgba($MET, 0.4); }
	&.task-checkbox .mt-checkbox.checked:before, &.task-checkbox .checkbox-icon.checked:before {
		background-color: $MET; } }

.premet {
	&.task-checkbox .mt-checkbox.task-new.checked:before, &.task-checkbox .checkbox-icon.task-new.checked:before {
		background-color: rgba($preMET, 0.4); }
	&.task-checkbox .mt-checkbox.checked:before, &.task-checkbox .checkbox-icon.checked:before {
		background-color: $preMET; } }

.urgent {
	&.task-checkbox .mt-checkbox.task-new.checked:before, &.task-checkbox .checkbox-icon.task-new.checked:before {
		background-color: rgba($urgent, 0.4); }
	&.task-checkbox .mt-checkbox.checked:before, &.task-checkbox .checkbox-icon.checked:before {
		background-color: $urgent; } }

.routine {
	&.task-checkbox .mt-checkbox.task-new.checked:before, &.task-checkbox .checkbox-icon.task-new.checked:before {
		background-color: transparent; }
	&.task-checkbox .mt-checkbox.checked:before, &.task-checkbox .checkbox-icon.checked:before {
		border-color: #ababab;
		background-color: #ababab; } }

.routine {
	.task-checkbox .mt-checkbox:before, .task-checkbox .checkbox-icon:before {
		border-color: #ababab; } }

.no-top-margin {
	margin-top: 0; }

ul.no-style li {
	list-style: none; }

.paging {
	margin-left: -20px;
	a {
		color: #888; }
	a:hover {
		text-decoration: none; } }


.total {
	width: 300px;
	float: left; }

.search-sort-selector {
	button.btn {
		border-color: #999;
		color: $gray-light; } }

.search-sort-selector {
	margin-right: auto;
	margin-left: auto;
	width: 150px; }

ul.dropdown-menu.sort-select {
	min-width: 0; }

.select-pill {
	display: inline-block;
	background-color: $gray-light;
	color: #fff;
	border-radius: 3px;
	padding: 4px 10px;
	margin-right: 5px;
	margin-bottom: 2px;
	&:hover {
		background-color: darken($gray-light, 20%) !important; }
	cursor: pointer;
	position: relative;
	top: 1px;
	&.small {
		font-size: small;
		padding: 4px 8px 4px 8px; } }

.white-container.showing {
	padding: 10px;
	padding-left: 20px;
	font-weight: bold; }

.desc-annot {
	font-size: 0.9em;
	display: block;
	color: $gray-light2; }

.task-reply {
	border: 1px solid #c7cad4;
	margin-bottom: 5px;
	@include box-shadow(0 1px 2px 0 rgba(0,0,0,0.35));
	padding: 5px 8px;
	background: repeating-linear-gradient(180deg, #edecf8, #edecf8 23px, #d8dbe6 23px, #d8dbe6 24px);
	background-color: #edecf8;
	color: #000;
	width: 100%; }

.task-replies {
	margin-bottom: 10px;
	.sent {
		float: right; }
	.sent, .sender {
		color: #82858c; }
	.oblique {
		font-style: italic; } }

.actions {
	font-size: 0.8em;
	position: absolute;
	float: left;
	right: 0;
	top: 21px;
	left: auto;
	display: block;
	z-index: 1000;
	width: auto;
	background-clip: padding-box;
	text-align: left;
	border-left: 1px solid #eee;
	border-right: 1px solid #eee;
	border-top: 1px solid #eee;
	background-color: #fff;
	margin: 0;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	.actions-pointer {
		position: absolute;
		display: block;
		z-index: 2000;
		right: 13px;
		top: -6px;
		width: 11px;
		height: 6px;
		background: url('../assets/images/pointer.png');
		background-repeat: no-repeat;
		background-size: 11px 6px;
		background-position: 0 0; }
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		// Needs to be auto to prevent scroll track when unneeded:
		overflow-y: auto;
		max-height: 45vh;
		li {
			padding: 10px;
			margin: 0;
			border-bottom: 1px solid #eee;
			display: flex;
			flex-flow: row nowrap;
			.action-col {
				margin-right: 10px;
				&:nth-child(2) {
					flex-grow: 1;
					margin-right: 20px; }
				&:last-child {
					flex-grow: 1;
					margin-right: 0; } }
			.icon {
				font-size: 1.5em;
				vertical-align: top;
				i.small {
					font-size: 80%; } } } } }

.reply-form {
	margin-top: 10px; }

.appalert {
	display: inline-block;
	border-radius: 50%;
	width: 21px;
	height: 21px;
	padding: 3px;
	background-color: rgba(black, 0.3);
	position: relative;
	top: -1px;
	font-size: 11px;
	font-weight: bold;
	color: #fff;
	text-align: center;
	margin-right: 3px;
	&.unread {
		background-color: $nimblic-red; } }

.photos {
	padding: .2vw;
	font-size: 0;
	-webkit-flex-flow: row wrap;
	flex-flow: row wrap;
	display: flex;
	.no-margin {
		margin-left: 0; }
	.thumb {
		position: relative;
		width: 100px;
		img {
			width: 94px;
			border-radius: 4px;
			border: 1px solid #ccc; } } }
.modal-photo {
	.modal-body {
		img {
			margin-left: auto;
			margin-right: auto;
			display: block; } } }

.row-buttons {
	display: flex;
	-webkit-flex-flow: row wrap;
	flex-flow: row wrap;
	.task-button {
		width: 50%;
		margin-bottom: 10px;
		&:nth-child(even) {
			padding-left: 15px; } } }

.forward {
	color: rgba(0,0,0,0.5);
	font-style: italic;
	.prac {
		color: #000; } }

.sort-select {
	li {
		padding: 5px;
		border-bottom: 1px solid $gray-light4; }
	:first-child {
		padding-top: 2px; }
	:last-child {
		padding-bottom: 2px;
		border-bottom: none; } }


.escalation-icon {
	display: inline-block;
	padding-right: 3px;
	position: relative;
	top: 3px;
	i {
		color: #ec2265;
		font-size: 20px;
		animation: escalation-pulse 2s infinite; } }

.white-container.pager {
	padding-top: 10px;
	padding-bottom: 10px;
	.pager-container {
		display: block;
		width: 220px;
		margin-left: auto;
		margin-right: auto;
		a.pager-ctrl {
			width: 25px;
			display: inline-block;
			margin-left: auto;
			margin-right: auto;
			color: $gray-copy;
			visibility: hidden;
			text-align: center;
			&.pager-show {
				visibility: visible; }
			&:hover {
				text-decoration: none; }
			i {
				font-size: 20px;
				position: relative;
				top: 2px; } } } }

@keyframes escalation-pulse {
  0% {
    color: #ec2265; }
  50% {
    color: #f26493; }
  100% {
    color: #ec2265; } }
