/* Import bootstrap-sass so that we have access to all of its selectors */
// @import "./bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss"
@import "common/colors.sass";

.btn {
	font-weight: 500;
	transition: all ease-in-out 0.15s;
	&.btn-left {
		text-align: left; }
	&.btn-no-border {
		&, &:visited, &:active, &:hover, &:focus {
			border: none; } } }

.btn-default:hover, .btn-default:focus, .btn-default.focus, .btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
	// background-color: transparent
	// box-shadow: 0 0 3px 0 rgba(0,0,0,0.15)
 }	// outline: 0

.btn-group.btn-group-justified.btn-dark-active {
	.btn-default.active {
		// background-color: $gray-light2
		color: white;
		// box-shadow: 0 none
 } }		// border: 0 none

.btn.btn-primary {
	border-color: lighten($highlight, 5%);
	background-color: lighten($highlight, 5%);
	color: white;
	&:hover, &:focus, &.focus, &:active, &.active {
		background-color: $highlight;
		border-color: $highlight; } }

.btn.btn-secondary {
	border-color: $gray;
	background-color: $gray;
	color: white;
	&:hover, &:focus, &.focus, &:active, &.active {
		border-color: darken($gray, 15%);
		background-color: darken($gray, 15%); } }

.btn.btn-outline {
	border-color: $gray-darker;
	background-color: transparent;
	color: $gray-darker;
	&:hover, &:focus, &.focus, &:active, &.active {
		border-color: darken($gray-darker, 15%);
		color: darken($gray-darker, 15%); } }

.btn.btn-default {
	border-color: $gray-light2;
	&:hover, &:focus, &.focus, &:active, &.active {
		border-color: darken($gray-light2, 15%); } }

// .btn.btn-info
// 	border-color: $highlight
// 	background-color: $highlight
// 	color: white
// 	&:hover, &:focus, &.focus, &:active, &.active
// 		background-color: darken($highlight, 10%)
// 		border-color: darken($highlight, 10%)

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
	outline: 0; }

/* Disabled */
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
	opacity: 0.5; }

.btn.btn-primary.disabled, .btn.btn-primary[disabled], fieldset[disabled] .btn.btn-primary {
	opacity: 0.25; }

/* Utilities for buttons */
.btn.btn-full-width {
	width: 100%; }

/* row buttons for Clinicians avail */

.btn.btn-row {
	width: 100%;
	border: 1px solid transparent;
	border-bottom: 1px solid $gray-lighter;
	margin-bottom: 0.5em;
	&:hover, &:focus, &:active {
		border: 1px solid $gray-light2;
		background-color:  $gray-light2;
		color: white; } }

/* big buttons in grid */
.btn.btn-big-grid {
	width: 100%;
	padding: 2em 30px;
	margin-top: 15px;
	margin-bottom: 15px;
	text-align: left;
	&.btn-med {
		padding: 1em 15px; }
	&.btn-tight {
		margin-bottom: 0;
		font-size: 0.866666666em; }
	&, & * {
		white-space: normal; }
	&.active, &:active {
		background-color: $gray-lighter; } }

.btn.btn-big-grid, .btn.btn-row {
	.btn-big-grid-shortcut, .btn-big-grid-txt {
		display: block;
		min-height: 0px;
		@media all and (min-width: 992px) {
			min-height: 45px;
			&.btn-no-minheight {
				min-height: 0; } } } }

.btn.btn-big-grid, .btn.btn-row {
	.btn-big-grid-shortcut {
		float: left;
		color: $gray-light4;
		font-weight: 700;
		padding-right: 15px;
		margin-bottom: 0; } }

.btn.btn-row {
	.btn-big-grid-txt {
		float: left; } }

.btn {
	&.no-margin {}
	margin: 0; }

