#task-list {
	label.task-search {
		color: #444;
		background-color: $gray-light5;
		font-weight: normal;
		&.date {
			padding: 3px 12px; }
		&.filter {
			padding: 6px 12px; }
		position: relative;
		top: -1px;
		cursor: auto;
		min-width: 80px;
		display: inline-block; }

	.date-select {
		display: inline-block;
		padding-right: 6px; }

	input.search-date {
		font-size: smaller;
		width: 150px;
		padding: 7px;
		margin-right: 5px;
		border-radius: 3px;
		border: 1px solid #999999; }

	input.search-text {
		border: 1 px solid #999999;
		border-radius: 3px;
		display: inline-block;
		padding-right: 20px; }

	.search-field {
		.form-control.btn {
			border: 1px solid #999999; } }

	.search-container {
		display: inline-block;
		border-radius: 3px;
		border: 1px solid #999999; }

	.borderless {
		display: inline-block;
		.form-control {
			border: none;
			min-width: 180px; } }

	.filter-select {
		display: inline-block;
		width: 200px;
		margin-right: 10px;
		ul.ui-select-choices {
			width: 200px; }
		input {
			width: 200px; }
		.btn.form-control {
			width: 200px; } }

	.filter-row {
		padding-top: 8px;
		background-color: #f3f3f3;
		padding-bottom: 6px;
		margin-bottom: 4px; }

	.filter-options {
		margin-bottom: -10px;
		height: auto; }

	a.clear-search {
		position: relative;
		display: inline-block;
		left: -5px;
		margin-left: -15px;
		color: #777; }

	a.disabled {
		color: #ccc !important;
		cursor: not-allowed; }

	.animate-show-hide.ng-hide {
		height: 0;
		opacity: 0; }

	.animate-show-hide {
		transition: all linear 0.2s;
		-webkit-transition: all linear 0.2s; }

	.task-search-btn {
		margin-left: 10px; } }

