input.report-date {
	font-size: smaller;
	width: 150px;
	padding: 4px;
	margin-right: 5px; }

.flex-row {
	padding: 10px;
	-webkit-flex-flow: row wrap;
	flex-flow: row wrap;
	display: flex;
	margin-bottom: 0;
	.flex-item-wide {
		width: 460px; }
	.date-select {
		width: 230px;
		float: left; }
	.flex-item {
		min-width: 250px;
		padding-right: 10px; }
	.button {
		width: 70px; }
	* {
		margin-bottom: 8px; } }
a.chart-csv {
	text-align: center;
	color: $highlight;
	font-size: small;
	margin-left: 25px; }

.blue {
	color: $highlight; }

.reports-header, .reports-summary {
	padding-bottom: 0;
	margin-bottom: 10px; }

.reports-summary {
	padding-top: 20px; }

.reports-graphs {
	padding-top: 10px; }

.no-tasks {
	width: 100%;
	padding: 8px;
	padding-top: 0;
	margin-top: -30px;
	h2 {
		font-style: italic;
		text-align: center;
		color: #333;
		font-size: 1.2em; } }

.summary {
	.main {
		margin-bottom: 12px; }
	.task-total {
		display: inline-block;
		width: 250px;
		font-size: 1.3em;
		color: #333333;
		font-weight: bold;
		margin-left: 25px;
		margin-top: 0;
		&.notasks {
			font-style: italic;
			text-align: center; } }
	.task-time {
		display: inline-block;
		width: 250px;
		margin-bottom: 10px;
		font-size: 1em;
		color: #333333;
		margin: 0 0 5px 25px; }
	.report-total {
		font-size: 1em; } }

.graph-container {
	padding: 20px;
	margin: 10px;
	margin-bottom: 20px;
	border: 1px solid $gray-light4; }
