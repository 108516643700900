.header-row {
	font-weight: bold;
	color: $gray-base; }

.alt-row {
	background-color: #f2f2f2; }

.row-roles .row {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	&:last-child {
		border-bottom: 2px solid #f2f2f2;
		margin-bottom: 10px; } }

.timepicker-row {
	background-color: #f2f2f2;
	margin-top: 10px;
	label.big-input-label {
		margin-top: 40px; } }

a.kinda-disabled, a.kinda-disabled:hover, a.kinda-disabled:active {
	color: $gray-light2;
	cursor: default;
	text-decoration: none; }

.role-form {
	input.responsibility {
		border-radius: 3px;
		border: 1px solid #000;
		&.code {
			display: inline-block;
			width: 30%; }
		&.display {
			display: inline-block;
			width: 68%; } }
	textarea {
		margin-top: 5px; }
	.ui-select-match.ui-select-placeholder {
		padding-right: 10px; }
	.ui-select-toggle.btn {
		width: 200px; }
	.ward-pill {
		display: inline-block;
		background-color: $gray-light;
		color: #fff;
		border-radius: 3px;
		padding: 5px;
		margin-right: 5px;
		margin-top: 5px; } }

.role-form:not(.ng-pristine) {
	input.responsibility {
		&.ng-invalid-required {
			border-color: $nimblic-red; } } }

