/* mixins */

@mixin quickanim {
	-o-transition: all 150ms ease-in-out;
	-moz-transition: all 150ms ease-in-out;
	-webkit-transition: all 150ms ease-in-out;
	transition: all 150ms ease-in-out; }

@mixin anim($animTime, $easing) {
	-o-transition: all $animTime $easing;
	-moz-transition: all $animTime $easing;
	-webkit-transition: all $animTime $easing;
	transition: all $animTime $easing; }

@mixin specanim($animKind, $animTime, $easing) {
	-o-transition: $animKind $animTime $easing;
	-moz-transition: $animKind $animTime $easing;
	-webkit-transition: $animKind $animTime $easing;
	transition: $animKind $animTime $easing; }

@mixin specanim2($animKind, $animTime, $easing, $animKind2, $animTime2, $easing2) {
	-o-transition: $animKind $animTime $easing, $animKind2 $animTime2 $easing2;
	-moz-transition: $animKind $animTime $easing, $animKind2 $animTime2 $easing2;
	-webkit-transition: $animKind $animTime $easing, $animKind2 $animTime2 $easing2;
	transition: $animKind $animTime $easing, $animKind2 $animTime2 $easing2; }

@mixin keyanim($name, $duration, $ease, $delay, $direction, $count, $fill-mode, $play-state) {
	-webkit-animation: $name $duration $ease $delay $direction $count $fill-mode $play-state;
	-moz-animation:    $name $duration $ease $delay $direction $count $fill-mode $play-state;
	-o-animation:      $name $duration $ease $delay $direction $count $fill-mode $play-state;
	animation:         $name $duration $ease $delay $direction $count $fill-mode $play-state; }

@mixin border-radius($border-radius) {
	-webkit-border-radius: $border-radius;
	border-radius: $border-radius; }

@mixin box-shadow($box-shadow) {
	-webkit-box-shadow: $box-shadow;
	-moz-box-shadow: $box-shadow;
	box-shadow: $box-shadow; }

@mixin box-shadow-2($box-shadow1,$box-shadow2) {
	-webkit-box-shadow: $box-shadow1,$box-shadow2;
	-moz-box-shadow: $box-shadow1,$box-shadow2;
	box-shadow: $box-shadow1,$box-shadow2; }


/* anims */

@-o-keyframes animateButton {
	0% {
		opacity: 0;
		height: 0; }
	100% {
		opacity: 1;
		height: 30px; } }

@-moz-keyframes animateButton {
	0% {
		opacity: 0;
		height: 0; }
	100% {
		opacity: 1;
		height: 30px; } }

@-webkit-keyframes animateButton {
	0% {
		opacity: 0;
		height: 0; }
	100% {
		opacity: 1;
		height: 30px; } }

@keyframes animateButton {
	0% {
		opacity: 0;
		height: 0; }
	100% {
		opacity: 1;
		height: 30px; } }

// Animate
@-o-keyframes rowHeight {
	0% {
		opacity: 0;
		max-height: 0; }
	100% {
		opacity: 1;
		max-height: 600px; } }

@-moz-keyframes rowHeight {
	0% {
		opacity: 0;
		max-height: 0; }
	100% {
		opacity: 1;
		max-height: 600px; } }

@-webkit-keyframes rowHeight {
	0% {
		opacity: 0;
		max-height: 0; }
	100% {
		opacity: 1;
		max-height: 600px; } }

@keyframes rowHeight {
	0% {
		opacity: 0;
		max-height: 0; }
	100% {
		opacity: 1;
		max-height: 600px; } }


