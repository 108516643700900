// @import "./bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss"
@import "common/colors.sass";
@import "common/mixins.sass";
@import "common/variables.sass";

nav.top-nav {
	background-color: white; // for old browsers
	background-color: white;
	position: fixed;
	height: 75px;
	border-bottom: 1px solid #e0e0e0;
	right: 0;
	left: 0;
	top: 0;
	z-index: 1030; }

.nav > li {
	> a {
		color: $gray;
		@include quickanim; }
	> a:hover, > a:focus, > a:active {
		background-color: transparent;
		color: {} }
	.dropdown-menu {
		.no-link {
			font-size: .8em;
			padding: 3px 20px;
			white-space: nowrap; } } }
.nav .open {
	> a, > a:hover, > a:focus, > a:active {
		background-color: transparent; } }

// header
.container-fluid > .navbar-header {
	float: left;
	width: 50px;
	margin-right: 0;
	margin-left: -15px;
	display: flex;
	height: 75px;
	@media all and (min-width: $screen-md) {
		width: 250px; }
	button#menu-toggle-2 {
		float: left;
		padding: 5px 9px;
		height: 40px;
		width: 40px;
		align-self: center;
		margin-left: 10px;
		border-color: #666;
		color: #333;
		&:hover, &:focus, &:active, &.focus, &.active {
			background-color: transparent;
			border-color: #000;
			color: #000; }
		&.btn-pressed {
			background-color: #555;
			border-color: #555;
			color: white;
			@include box-shadow(inset 0 2px 2px 1px rgba(0,0,0,0.75));
			&:hover, &:focus, &:active, &.focus, &.active {
				border-color: #000; } } }

	.navbar-brand {
		@include anim(500ms, ease);
		display: none;
		align-self: center;
		padding-left: 27px;
		margin-bottom: 2px;
		@media all and (min-width: $screen-md) {
			display: block; }
		&.pushLeft {
			padding-left: 31px; } }
	.environment {
		width: 200px;
		position: absolute;
		top: 20px;
		left: 250px; } }

// primary menu
.navbar-menus {
	.navbar-nav {
		margin: 0;
		> li > a {
			padding-top: 14.5px;
			padding-bottom: 14.5px; } }
	.navbar-right {
		//- for all use cases not just over 992px
		float: right !important;
		margin-right: -15px;
		height: 75px;

		@media all and (min-width: $screen-md) {
			margin-right: 0; }
		&.navbar-nav > li {
			float: left !important; }
		li a {
			font-weight: 400;
			height: 50px;
			margin-top: 25px;
			padding: 10px 35px;
			border-left: 1px solid #e0e0e0;
			border-radius: 5px;
			&:hover {
				background-color: #eff8ff; }
			&:active {
				background-color: #1570ef;
				colo: #fff; } }
		.navbar-text {
			float: left;
			margin: 14.5px 15px; }
		//- overwrite stupid bootstrap media query default
		.open .dropdown-menu {
			position: absolute;
			float: left;
			background-color: #fff;
			border: 1px solid #ccc;
			border: 1px solid rgba(0,0,0,0.15);
			@include box-shadow(0 6px 12px rgba(0, 0, 0, 0.175)); }
		.dropdown-menu > li > a {
			font-size: 1em;
			&:hover, &:focus {
				background-color: #555;
				color: white; } }
		.navbar-icon {
			display: inline-block;
			margin-right: 10px;
			width: 15px;
			svg {
				max-width: 14px;
				max-height: 12px;
				fill: currentColor; } }
		.max-width {
			max-width: 220px; } } }

.newalert {
	position: absolute;
	left: 24px;
	top: 15px;
	color: $nimblic-red;
	font-size: 11px; }

.outline {
	position: absolute;
	left: 23px;
	top: 14px;
	color: #fff;
	font-size: 13px; }

.menu-escalation {
	color: #ec2265;
	font-size: 14px;
	animation: escalation-pulse 2s infinite;
	display: inline-block;
	padding-right: 3px; }

.time-since {
	color: $gray-light;
	font-style: italic; }

ul.alerts {
	max-height: 65vh;
	min-width: 210px; //prevents overlap of text in header row
	overflow: auto;
	// position: relative
	li {
		border-bottom: 1px solid #d8dbe6;
		&:last-child {
			border: none; }
		a {
			padding: 10px 20px; } }

	&.dropdown-menu > li.new-alert, &.dropdown-menu > li.read-alert {
		font-size: 0.875em;
		background-color: #edecf8;
		&:hover, &:focus, &:active {
			background-color: #d5d3f0; }
		> a, > a:hover, > a:active, > a:focus {
			background-color: transparent;
			color: $gray-base; } }

	&.dropdown-menu > li.read-alert {
		background-color: transparent; }

	&.dropdown-menu > li.mark-read {
		padding-bottom: 10px;
		> a {
			padding: 0;
			float: right;
			color: $highlight;
			&:hover, &:active, &:focus {
				color: $highlight;
				background-color: transparent;
				text-decoration: underline; } }
		&:hover, &:active, &:focus {
			background-color: transparent; } } }
