.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.thumbnail {
  height: 200px;

  img.pull-right {
    width: 50px;
  }
}

.pointer :hover {
	cursor: pointer
}


// injector
@import 'common/variables.sass';
@import 'common/notifications.scss';
@import 'app/app.sass';
@import 'calltasker/calltasker-admin.sass';
@import 'common/animations.sass';
@import 'common/buttons.sass';
@import 'common/colors.sass';
@import 'common/forms.sass';
@import 'common/jslider.sass';
@import 'common/mixins.sass';
@import 'common/modal.sass';
@import 'common/print.sass';
@import 'common/typography.sass';
@import 'compose/compose.sass';
@import 'debug/debug.sass';
@import 'debug/entity.sass';
@import 'inbox/forwardtask.sass';
@import 'inbox/inbox.sass';
@import 'insights/insights.sass';
@import 'monitor/monitor.sass';
@import 'pharmacy-report/pharmacy.sass';
@import 'reports/reports.sass';
@import 'role/role.sass';
@import 'roster/roster-override.dialog.sass';
@import 'roster/roster-print.sass';
@import 'roster/roster.sass';
@import 'shift/aggregate.sass';
@import 'tasksearch/task-search.sass';
@import 'user/user.sass';
@import '../components/accordion/accordion.sass';
@import '../components/login/campus-selector-dialog.sass';
@import '../components/login/login-dialog.sass';
@import '../components/navbar/navbar.sass';
@import '../components/navbar/sidebar.sass';
// endinjector
