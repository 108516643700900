.user-form, .user-dialog {
	.rolepill {
		display: inline-block;
		background-color: $gray-light;
		color: #fff;
		border-radius: 3px;
		padding: 5px;
		margin-right: 5px; }
	.green {
		color: $green; }
	.user-filter {
		width: 300px;
		display: inline-block; }
	button.search {
		display: inline-block; }
	button.role-search {
		position: relative; } }
