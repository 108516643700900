@import "common/colors.sass";
@import "common/mixins.sass";

$row-height: 66px;

.modal-body {
	z-index: 200; }

.cal-container {
	border-left: 1px solid $gray-lighter;
	box-shadow: inset -1px 1px 0 0 $gray-lighter;
	display: inline-block;
	float: left; }

.form-control.big-input.calendar-input {
	padding: 20px;
	font-size: 1em; }

.list-group-item {
	color: #333; }

.push30 {
	margin-top: 28px; }

.team-input .form-control {
	color: $gray-dark;
	font-size: 1.2em;
	height: 40px; }

.row {
	&.no-shifts {
		padding-top: 10px; } }

.clone, .today {
	float: right;
	color: $gray-copy;
	padding: 0;
	&:disabled {
		color: #ddd;
		cursor: not-allowed; } }

.ta-select {
	padding-top: 10px; }

div.mismatch, .dropdown-menu > li > a.mismatch {
 }	// color: $orange!important

// Left column labels
.container-table {
	margin-right: -30px;
	margin-left: -30px;
	.label-table {
		width: 100%;
		height: $row-height;
		text-align: right;
		line-height: 1;
		vertical-align: middle;
		span {
			@include quickanim;
			color: #000; }
		&:hover, &:focus, &:active {
			span {
				color: $highlight; } }
		td {
			padding-right: 10px;
			span {
				padding: 1px 3px;
				line-height: 1.35; }
			&.selected {
				span {
					color: white;
					background-color: $highlight; }
				&:hover, &:focus, &:active {
					span {
						color: white; } } } } } }

.roster-date {
	border: none;
	background: transparent;
	box-shadow: none;
	outline: none; }

/* Maths for roster grid */
$grid: 8.333333%;
$grid1: 0;
$grid2: $grid1 + $grid;
$grid3: $grid1 + ($grid * 2);
$grid4: $grid1 + ($grid * 3);
$grid5: $grid1 + ($grid * 4);
$grid6: $grid1 + ($grid * 5);
$grid7: $grid1 + ($grid * 6);
$grid8: $grid1 + ($grid * 7);
$grid9: $grid1 + ($grid * 8);
$grid10: $grid1 + ($grid * 9);
$grid11: $grid1 + ($grid * 10);
$grid12: $grid1 + ($grid * 11);
$grid13: $grid1 + ($grid * 12);

div.roster-content {
	background-image: url('../assets/images/grey-divider.png'), url('../assets/images/grey-divider.png'), url('../assets/images/grey-divider.png'), url('../assets/images/grey-divider.png'), url('../assets/images/grey-divider.png'), url('../assets/images/grey-divider.png'), url('../assets/images/grey-divider.png'), url('../assets/images/grey-divider.png'), url('../assets/images/grey-divider.png'), url('../assets/images/grey-divider.png'), url('../assets/images/grey-divider.png'), url('../assets/images/grey-divider.png'), url('../assets/images/grey-divider.png');
	background-position: $grid1 0, $grid2 0, $grid3 0, $grid4 0, $grid5 0, $grid6 0, $grid7 0, $grid8 0, $grid9 0, $grid10 0, $grid11 0, $grid12 0, $grid13 0;
	background-repeat: repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y; }

//- For role level row select:
.selected .shift-row .shift-cell {
	&, &.empty-shift {
		box-shadow: inset -1px -1px 0 0px $highlight, -1px -1px 0 0px $highlight; } }

.shift-row {
	position: relative;
	height: $row-height;
	margin-bottom: 5px;

	.no-height {
		height: 0; }

	.role-name {
		max-height: 66px;
		overflow: hidden; }

	&.shift-row-stripes {
		background-image: url('../assets/images/grey-tile.png'); }

	&.shift-row.time {
		height: 16px; }

	// Shift Creation
	.shift-axis {
		position: relative;
		& div {
			position: absolute;
			display: block;
			height: 16px;
			padding: 0;
			margin-left: -20px; } }

	.role-row {
		height: 66px;
		&.selected {
 } }			//- ??

	// Roster Screen
	.shifts {
		.shift-cell {
			background-color: #fff;
			&.selected-aggregate {
				background-color: lighten($highlight,10%); }
			&.selected {
				background-color: $highlight; } } }


	.shift-cell {
		@include quickanim;
		height: $row-height;
		position: absolute;
		padding: 0;
		border: none;
		cursor: pointer;
		background-color: #eee;
		color: darken($gray-copy, 12%);
		box-shadow: inset -1px -1px 0 0px $gray-light1, -1px -1px 0 0px $gray-light3;
		&, & * {
			// TODO: Laura - figure out WTF is going on with font sizing
			font-size: 14px;
 }			// line-height: 1.2
		&:hover {
			box-shadow: inset -1px -1px 0 0px $gray-light, -1px -1px 0 0px $gray-light2, 0 1px 2px 1px rgba(0,0,0,0.35);
			z-index: 2;
			&.overridden {
				z-index: 1; }
			.overlap-marker {
				display: none; } }

		//- Multi-selected aggregates
		&.multi-selected {
			background-color: lighten($highlight,10%) !important;
			color: white;
			box-shadow: inset -1px -1px 0 0px lighten($highlight, 4%), -1px -1px 0 0px lighten($highlight, 14%);
			&:hover {
				background-color: lighten($highlight, 8%); }
			.aggregate svg {
				path {
					fill: currentColor; } } }

		//- Selected cells
		&.selected, &.selected.multi-selected {
			background-color: $highlight !important;
			color: white;
			z-index: 2;
			box-shadow: inset -1px -1px 0 0px darken($highlight, 4%), -1px -1px 0 0px lighten($highlight, 8%);
			&:hover {
				background-color: darken($highlight, 2%); }
			&.overridden {
				z-index: 1; }
			.overlap-marker {
				display: none; } }

		//- Empty cells
		&.empty-shift {
			background-color: rgba(white, 0.35);
			&.selected {
				background-color: rgba(lighten($highlight, 15%), 0.75); } }

		.override-marker {
			position: absolute;
			right: 0px;
			height: 32px;
			border-right: 1px dashed #ccc;
			color: #eee;
			display: none;
			top: 32px;
			width: 10px; }

		.overlap-marker {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			border-right: 1px dashed rgba(200,200,200, 0.7);
			width: 1px;
			z-index: 2; }

		&.overridden {
			&.assigned {
				background-color: #eee !important; }
			.practitioner {
				padding-top: 6px;
				background-color: white;
				width: 100%;
				padding-left: 8px;
				margin-left: -8px;
				margin-right: -6px;
				height: 25px;
				box-shadow: inset -1px -1px 0 0px darken($highlight, 4%), -1px -1px 0 0px lighten($highlight, 8%);
				position: absolute;
				top: 33px;
				height: 32px; }

			&.aggregate {
				.practitioner {
					top: 40px;
					height: 25px;
					padding-top: 3px; } }

			&.multi-selected {
				.practitioner {
					background-color: lighten($highlight,10%);
					color: white; } }

			&.selected {
				.practitioner {
					background-color: $highlight !important; } } }

		&.assigned {
			background-color: white; }

		//- Override
		&.override {
			//box-shadow: inset -1px -1px 0 0px darken($highlight, 10%), -1px -1px 0 0px lighten($highlight, 5%), 0 33px 0 0 rgba($highlight-muddy, 0.2)
			height: 33px;
			&.aggregate {
				height: 25px;
				//box-shadow: inset -1px -1px 0 0px darken($highlight, 10%), -1px -1px 0 0px lighten($highlight, 5%), 0 25px 0 0 rgba($highlight-muddy, 0.2)
				top: 15px; }

			z-index: 1;

			&:after {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				bottom: -5px;
				left: 30%;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 5px solid darken($highlight, 10%);
				font-size: 0;
				line-height: 0; }

			// &:hover
			// 	box-shadow: inset -1px -1px 0 0px darken($highlight, 10%), -1px -1px 0 0px lighten($highlight, 5%), 0 32px 0 0 rgba($highlight-muddy, 0.2), 0 1px 2px 1px rgba($highlight-muddy,0.5)

			&.multi-selected {
				background-color: lighten($highlight,10%);
				color: white;
				//box-shadow: inset -1px -1px 0 0px lighten($highlight, 4%), -1px -1px 0 0px lighten($highlight, 14%), 0 32px 0 0 rgba($highlight-muddy, 0.2)
				&:hover {
					background-color: lighten($highlight, 8%); } }

			&.selected, &.selected.multi-selected {
				background-color: $highlight;
				//box-shadow: inset -1px -1px 0 0px darken($highlight, 4%), -1px -1px 0 0px lighten($highlight, 8%), 0 32px 0 0 rgba($highlight-muddy, 0.2)
				&:hover {
					background-color: darken($highlight, 2%); } }

			.override-marker {
				display: block; }
			&.aggregate {
				.override-marker {
					height: 25px;
					top: 0; } }
			.mismatch:before {
				content: '\f234';
				display: inline-block;
				float: left;
				padding-right: 3px;
				text-rendering: auto;
				font-family: FontAwesome;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				transform: translate(0, 0); } }

		&.first-shift {
 }			// border-left: 2px solid #666
		&.assigned {}
		// no design at the mo

		input {
			background-color: transparent;
			color: white; }
		.aggregate {
			background-color: #dde;
			padding: 0;
			color: #fff;
			margin: -4px;
			margin-left: -8px;
			margin-right: -7px;
			margin-bottom: 0;
			&, & * {
				line-height: 1; }
			.link-icon {
				width: 12px;
				margin-left: auto;
				margin-right: auto; }
			svg {
				width: 12px;
				height: 12px;
				path {
					fill: currentColor; } } }

		.edit {
			@include quickanim;
			float: right;
			text-align: right;
			clear: right;
			margin-top: -8px;
			a span {
				margin-right: 2px; } }

		.practitioner {
			&, * {}
			height: 20px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden; }

		.min-display, .narrow-display, .wide-display {
			display: none; }

		.narrow-width, .below-min-width, .wide-width {
			.practitioner * {
				display: none; } }

		.below-min-width {
			.practitioner {
				.min-display {
					display: block;
					font-size: 0.9em; } } }
		.narrow-width {
			.practitioner {
				.narrow-display {
					display: block;
					font-size: 0.9em; } } }

		.wide-width {
			.practitioner {
				.wide-display {
					display: block; } } }

		// The shift is not selected but one of its siblings in the aggregate is
		&.selected-aggregate {
			.aggregate {
				svg {
					path {
						fill: darken($highlight, 15%); } } } }

		// The shift is selected and is aggregate
		&.selected.selected-aggregate {
			.aggregate {
				svg {
					path {
						fill: currentColor; } } } }

		&.selected.aggr-selected, &:active, &:focus {
			z-index: 1;
			.edit {
				opacity: 1; }
			.aggregate {
				svg {
					path {
						fill: currentColor; } } } }

		// contents inside shift cell
		.shift-content {
			padding: 2px 4px; }

		// Overlap of shift change:
		.overlap {
			text-indent: -9999px;
			background: repeating-linear-gradient(-45deg, $gray-lighter, $gray-lighter 6px, transparent 6px, transparent 12px);
			z-index: 3;
			cursor: auto;
			border: 0 none;
			height: 62px;
			width: 20px;
			margin-right: 10px;
			float: left;
			display: block; }

		.ui-select-toggle, .ui-select-search {
			font-size: 12px;
			font-style: normal;
			border: none;
			background: none;
			color: black;
			padding-top: 7px;
			padding-left: 18px;
			&.text {
				color: black; } }
		.ui-select-choices {
			a, a * {
				&:hover, &:focus, &:active {
					color: white; } } }
		.ui-select-dropdown {}
		.ui-select-choices-row {
			&.active {
				a, a * {
					color: white;
					&:hover, &:focus, &:active {
						color: white; } } }
			a {
				color: black; } } } }

.role-container .role-node {
	margin-top: 8px;
	&:first-child, &:only-child {
		margin-top: 0; }
	.role {
		margin-top: 2px;
		margin-bottom: 2px;
		span {
			background-color: $highlight;
			color: white;
			padding: 1px 3px; } } }

.hol {
	color: $gray-base;
	font-style: italic; }

.shift-details {
	background-color: rgba(#ededed, 0.75);
	border-top: 2px solid $highlight;
	border-bottom: 1px solid $highlight;
	z-index: 2;
	margin-top: 15px;
	&, h1, h2, h3, h4, h5, h6, p, span {
		color: $gray-base; }
	hr {
		border-color: darken($highlight-med, 5%); }
	label {
		font-weight: normal;
		font-size: 0.9em; }
	.invalid {
		color: #af0b17;
		font-size: 0.8em;
		display: block; }
	.contact-pref {
		background-color: rgba(255, 255, 255, 0.15);
		border: 1px solid $highlight-med;
		padding: 0.5em 0.65em;
		margin-top: 5px;
		section {
			margin-top: 0px;
			margin-bottom: 5px;
			&, & * {
				// TODO: Laura - figure out WTF is going on with font sizing
				font-size: 13px; } }
		#pager, #phone {
			border: 1px solid rgba($highlight, 0.5);
			background-color: rgba(255, 255, 255, 0.5);
			width: 50px;
			margin-right: 4px;
			&.disabled {
				background-color: transparent;
				border: 1px solid rgba($highlight, 0.2); } }
		#phone {
			width: 100px; } }

	& a, & a * {
		color: darken( $highlight, 10% );
		&.red {
			&, & * {
				color: $nimblic-red; } }
		.fa {
			// edit / delete / override links
			margin-right: 3px;
			margin-left: 6px; } }
	.roles {
		margin-top: 10px;
		color: $gray-light2;
		&, & * {
			line-height: 1; }
		.shift-role {
			margin-bottom: 0px; }

		.ui-select-toggle, .ui-select-search {
			font-size: 12px;
			font-style: normal;
			border: none;
			color: black;
			padding-top: 7px;
			padding-left: 18px;
			&.text {
				color: black; } }
		.ui-select-choices {
			a, a * {
				&:hover, &:focus, &:active {
					color: white; } } }
		.ui-select-dropdown {}
		.ui-select-choices-row {
			&.active {
				a, a * {
					color: white;
					&:hover, &:focus, &:active {
						color: white; } } }
			a {
				color: black; } } }
	input.edit-prac {
		width: 90%; } }

.shift-details, .roster-content {
	.dropdown-menu {
		font-size: 1em;
		z-index: 3; } }

.practitioner-select {
		width: 150px;
		font-size: 1em;
		padding: 4px; }

.invisible {
	visibility: hidden; }

.btn-group > .btn.active {
	z-index: 0; }

.calendar-hours {
	position: relative;
	border-bottom: 2px solid $gray-dark;
	height: 18px;
	.tick {
		width: 1px;
		margin-left: auto;
		margin-right: auto;
		border-right: 1px solid; } }

.date-holder {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	.clone-btn {
		float: right;
		display: inline;
		&, & * {
			color: white; }
		i {
			margin-right: 4px; }
		&:hover, &:active, &:focus {
			text-decoration: none;
			span {
				text-decoration: underline; } } }

	a.date-arrow {
		padding: 0 0.5em;
		display: inline-block;
		cursor: pointer;
		&:hover {
			text-decoration: none; } }
	.date-disp {
		display: inline-block; }
	.date-seg {
		display: inline-block;
		margin: 0 1em; } }

button.date-select.selected {
	background-color: #000;
	color: white; }

h4.teams {
	font-size: 1em; }
h5.team-header {
	font-size: .9em;
	margin-bottom: 3px;
	margin-top: 4px; }
.show-end-time {
	float: right;
	width: 6px;
	height: 60px;
	margin-right: -10px; }
.show-start-time {
	float: left;
	width: 6px;
	height: 60px;
	margin-left: -6px; }

.dropdown-menu > li > a {
	font-size: .8em; }

table.ng-invalid-min-time input, table.ng-invalid-max-time input {
	border-color: maroon; }

/* Roster: ui-bootstrap Typeahead*/
.typeahead-no-border {
	border: none;
	outline: none;
	background-color: transparent;
	// Important makes input layout on small cells IE9:
	width: 100%; }

.team-nav {
	color: $gray-light3; }
.reason {
	padding-top: 10px; }

/* Clone shifts */
.shift-summary {
	background: url('../assets/images/grey-tile.png');
	margin-bottom: 2px;
	padding: 2px 4px 1px 4px; }

/* Placeholders */

.shift-cell {
	input::-moz-placeholder {
		font-style: italic;
		opacity: 1;
		color: lighten($highlight, 25%); }

	input:-ms-input-placeholder {
		font-style: italic;
		opacity: 1;
		color: lighten($highlight, 25%); }

	input::-webkit-input-placeholder {
		font-style: italic;
		opacity: 1;
		color: lighten($highlight, 25%); }

	input:focus::-moz-placeholder, input.focus::-moz-placeholder, input:active::-moz-placeholder, input.active::-moz-placeholder {
		opacity: 1;
		color: white; }

	input:focus::-ms-input-placeholder, input.focus::-ms-input-placeholder, input:active::-ms-input-placeholder, input.active::-ms-input-placeholder {
		opacity: 1;
		color: white; }

	input:focus::-webkit-input-placeholder, input.focus::-webkit-input-placeholder, input:active::-webkit-input-placeholder, input.active::-webkit-input-placeholder {
		opacity: 1;
		color: white; } }

.shift-details {
	.shift-cell {
		input::-moz-placeholder {
			font-style: italic;
			opacity: 1;
			color: darken($highlight, 5%); }

		input:-ms-input-placeholder {
			font-style: italic;
			opacity: 1;
			color: darken($highlight, 5%); }

		input::-webkit-input-placeholder {
			font-style: italic;
			opacity: 1;
			color: darken($highlight, 5%); }

		input:focus::-moz-placeholder, input.focus::-moz-placeholder, input:active::-moz-placeholder, input.active::-moz-placeholder {
			opacity: 1;
			color: $gray-base; }

		input:focus::-ms-input-placeholder, input.focus::-ms-input-placeholder, input:active::-ms-input-placeholder, input.active::-ms-input-placeholder {
			opacity: 1;
			color: $gray-base; }

		input:focus::-webkit-input-placeholder, input.focus::-webkit-input-placeholder, input:active::-webkit-input-placeholder, input.active::-webkit-input-placeholder {
			opacity: 1;
			color: $gray-base; } } }

#edit-contact {
	.num-input {
		display: inline-block;
		padding-top: 5px; } }

.dark-bg {
	background-color: rgba(0,0,0,.5);
	padding: 4px;
	* {
		color: #fff; }
	.today {
		padding-right: 8px;
		a: hover {
			text-decoration: none;
			color: #fff; } } }
.pad-top-5 {
	padding-top: 5px; }

.change-warning {
	@include border-radius(3px);
	padding: 10px 20px;
	background-color: $gray-light2;
	color: #fff;
	i {
		font-size: 2.8em; } }

label.link {
	&:hover {
		text-decoration: underline; }
	cursor: pointer; }

.date-input {
	background-color: #fff;
	height: 410px;
	width: 375px;
	position: fixed;
	top: 50%;
	left: 50%;
	padding: 20px;
	border: 1px solid $gray-light;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	h3 {
		text-align: center;
		margin-top: 0;
		font-size: 20px; }
	ul.dropdown-menu {
		transform: translate(15px, 50px);
		box-shadow: none; }
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	z-index: 5;

	.date-input {
		button.right {
			float:right {} } } }
