.monitor {
	.white-container {
		margin-bottom: 10px; }
	.flex-row {
		&.right-align {
			-webkit-justify-content: flex-end;
			justify-content: flex-end; }
		padding: 0;
		.flex-item-narrow {
			width: 90px;
			margin-right: 10px;
			.sent, .urgency {
				font-size: small; }
			.urgency {
				color: #fff;
				padding: 5px;
				border-radius: 4px;
				width: 80px;
				text-align: center;
				&.premet {
					background-color: $preMET; }
				&.met {
					background-color: $MET; }
				&.codeblue {
					background-color: $codeBLUE; } } }
		&.patient {
			padding-left: 0;
			padding-top: 5px;
			.flex-item-third {
				width: 33%;
				min-width: 120px;
				padding-left: 0;
				&.icon {
					position: relative;
					padding-left: 12px;
					i {
						position: absolute;
						left: -5px;
						top: 3px; } } } } }
	.patient-container {
		cursor: pointer;
		&.active {
			.white-container {
				border: 1px $highlight solid; }
			h4 {
				font-weight: bold; } }
		.white-container {
			padding: 10px 20px 0 20px;
			//background-color: $gray-light6
			margin-bottom: 10px; }
		.task-list {
			margin-right: -15px;
			margin-left: -15px;
			margin-bottom: 20px; } }

	ul.flex-list.options {
		padding-bottom: 0;
		li:first-child {
			border: none;
			text-align: left;
			margin-left: 0;
			padding-left: 0;
			width: 100px !important;
			color: #333;
			padding-top: 20px; }
		li {
			text-align: center;
			font-size: 1em;
			width: 70px; }
		&.urgencies {
			li {
				width: 125px; } } }
	.no-tasks {
		margin-top: 10px;
		margin-bottom: 0;
		h2 {
			margin-bottom: 0;
			padding-bottom: 0; } } }

