.mt-modal {
	padding: 0 25px 15px 25px;
	color: $gray-light; }

.mt-modal h3 {
	line-height: 1.3;
	font-size: 1.2em; }

.server-unavailable {
	background-color: #000;
	color: #fff;
	font-size: 1.5em;
	text-align: center;
	padding: 10px;
	position: fixed;
	right: 0;
	left: 0;
	top: 0;
	z-index: 3000;
	display: none;
	&.show {
		display: block !important; } }

a.reconnect {
	color: #fff;
	text-decoration: underline; }

.overlay {
	background-color: rgba(0,0,0,0.2);
	position: fixed;
	left: 0;
	top: 50px;
	z-index: 1020;
	height: 100%;
	width: 100%; }

.loader {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(240,240,240,0.2);
	z-index: 2000;
	img {
		margin-left: -150px;
		margin-top: -150px;
		position: absolute;
		top: 50%;
		left: 50%;
		size: 300px;
		-ms-animation: spin 2s linear infinite; } }

@keyframes spin {
	100% {
		transform: rotate(360deg); } }

.ui-select-toggle {
	overflow: hidden; }

.white-container {
	padding: 0 20px 20px 20px;
	background-color: #fff; }

.hilight {
	font-weight: bold;
	color: black;
	background-color: rgba($highlight, 0.25); }

.hidden {
	display: none; }

html {
	background: url(../assets/images/cool-sky.jpg) no-repeat center top fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	&.home {
		background: url(../assets/images/splash.jpg) no-repeat center top fixed;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover; }
	body {
		background-color: transparent; } }
