
/* Used in multiple templates now: */
.dateblockright {
	float: right;
	margin-top: 21px;
	.dateblock {
		text-align: right; } }

.dateblock {
	float: left;
	display: block;
	&:last-child {
		margin-left: 5px; }
	h3 {
		margin: 0;
		font-size: 1.3333333333333em; } }

#roster-print-form {
	.print-buttons {
		margin-top: 10px;
		@media print {
			display: none; } }
	.print-header {
		h1 {
			margin-top: 5px;
			margin-bottom: 2px; } }
	.key {
		border: 1px solid $gray-light3;
		padding: 0 10px 8px 10px;
		border-radius: 5px;
		width: 160px;
		float: right;
		margin-top: 30px;
		@media only screen and (max-width: 767px) {
			margin-top: 10px;
			float: none;
			max-width: inherit; }
		h4 {
			text-transform: uppercase; }
		.icon {
			display: inline-block;
			min-width: 26px; } }

	.team {
		@media print {
			page-break-inside: avoid; }
		h3 {
			padding-bottom: 6px;
			margin-top: 40px;
			@media print {
				font-size: 15px; } }
		.row.header {
			font-weight: bold;
			@media print {
				&, & * {
					font-size: 12px; } } }
		.row.whole-shift {
			margin-top: 4px;
			margin-bottom: 4px;
			border-top: 1px solid $gray-light3;
			@media print {
				&, & * {
					font-size: 12px; } }
			&:last-child {
				border-bottom: 1px solid $gray-light3; }
			[class*="col-"] {
				padding-top: 2px;
				padding-bottom: 3px; }
			.row.print-shift {
				border-bottom: 1px dotted $gray-light4;
				&:last-child {
					border-bottom: none; } } }
		.aggregate svg path {
			fill: currentColor; }

		// .row.whole-shift:nth-child(odd)
		// 	border-bottom: 1px dotted $gray-light4
		// .row.whole-shift:nth-child(even)
 } }		// 	border-bottom: 1px dotted $gray-light2

@media print {
	#roster-print-form {
		padding: 0 0 20px 0;
		margin: 0;
		&, & * {
			color: black !important; }
		.print-logo {
			margin-bottom: 10px;
			width: 140px; }

		footer.footer {
			position: fixed;
			bottom: 0;
			left: 30px;
			padding-top: 5px;
			border-top: 1px solid $gray-light3;
			&, & * {
				font-size: 9px; } } } }
