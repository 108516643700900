@import "common/colors.sass";

.insight-tasks, .insights-graph {
	padding-top: 20px; }

.insights-header {
	margin-bottom: 20px;
	padding-bottom: 20px; }

ul.flex-list {
	padding: 10px;
	-webkit-flex-flow: row wrap;
	flex-flow: row wrap;
	display: flex;
	list-style: none;
	margin-bottom: 0;
	li.option {
		width: 160px;
		margin: 10px;
		border: 1px solid #ddd;
		padding: 10px;
		border-radius: 3px;
		position: relative;
		cursor: pointer;
		.custom {}
		&:hover {
			a {
				color: #fff;
				text-decoration: none; }
			background-color: #222; }
		&.active {
			background-color: $gray-light6;
			&:hover {
				a {
					color: #222; } } } } }

.row.pad-left {
	padding-left: 20px; }

.no-insights-tasks {
	width: 100%;
	padding: 8px;
	padding-top: 0;
	h2 {
		font-style: italic;
		text-align: center;
		color: #333;
		font-size: 1.2em; } }
