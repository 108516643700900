.pharmacyreport {
    .row {
        margin: 0;
        &.encounter {
            border-bottom: solid 1px $gray-light4;
            padding-bottom: 10px;
            .patient {
                font-weight: bold;
                padding-bottom: 8px;
                margin: 0;
                background-color: $gray-light;
                padding-top: 6px;
                &.row2 {
                    padding-top: 0px; }
                color: #fff; }
            .encounter-note {
                textarea, .note {
                    border: 1px solid $gray-light4; }
                .edit-icon {
                    display: inline-block;
                    margin-right: 6px;
                    width: 30px; } } }
        &.header {
            font-weight: bold; }


        &.pharmacy-task {
            margin-bottom: 8px;
            padding-top: 8px;
            font-weight: normal; }

        &.encounter-note {
            margin-top: 12px; } }

    .print-buttons, .report-date {
        margin-top: 20px; } }

@media print {
    #pharmacyreport {
        .print-logo {
            width: 20%;
            height: 20%; }
        h3 {
            font-size: 16px;
            margin-top: 10px;
            margin-bottom: 0; }
        .print-header {
            h1 {
                font-size: 22px;
                margin-bottom: 0; } }
        .row.patient, .row.encounter-note, .row.pharmacy-task, .note {
            font-size: 10px;
            margin-bottom: 2px;
            padding: 0; }
        .patient-header {
            padding: 5px;
            background-color: #000 !important;
            -webkit-print-color-adjust: exact;
            .row.patient, .row.patient.row2 {
                *,*, *:before, *:after {
                    color: #fff !important; } } }
        .pharmacy-task {
            &.first {
                margin-top: 8px; } }
        .row.encounter.header {
            padding-top: 10px;
            page-break-inside: avoid;
            margin-left: -15px;
            margin-right: -15px; }

        .encounter-note {
            .note {
                padding: 5px; } } } }
