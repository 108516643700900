@import "common/colors.sass";
@import "common/mixins.sass";
@import "common/variables.sass";

// New sidebar stuff
/*!
 * Start Bootstrap - Simple Sidebar HTML Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http: *///www.apache.org/licenses/LICENSE-2.0.

/* Toggle Styles */
/* This sidebar modified by seegatesite.com */

#menu.sidebar-nav.nav-pills.nav-stacked {
  li {
    padding: 1px 5px;
    a {
      font-weight: 400;
      .pull-right {
        padding-right: 20px; } }
    span.fa-stack {
 }      // outline: 1px solid yellow
    .sidebar-svg {
      // outline: 1px solid red
      text-align: center;
      font-size: 1.3em;
      position: relative;
      width: 2em;
      height: 2em;
      line-height: 2.5em;
      vertical-align: middle;
      display: inline-block;
      float: left;
      &, & * {
        box-sizing: border-box; }
      svg {
        width: 16px;
        height: 16px;
        fill: currentColor; } }
    ul.nav-pills.nav-stacked {
      //- submenu
      padding: 0 0 0 40px;
      list-style-type: none;
      //- admin
      &.nav-admin {
        padding: 0 0 100px 40px; } } } }

#wrapper {
  padding-left: 0;
  @include anim(500ms, ease);
  &.toggled {
    padding-left: 380px; }
  &.toggled-2 {
    padding-left: 0px;
    padding-right: 75px;
    #sidebar-wrapper {
      top: 50px;
      width: 380px; } }
  &.nopad {
    padding-left: 0; } }

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 380px;
  width: 0;
  // disini agar ketika di kecilkan tidak hilang semua default 0
  height: 100%;
  margin-left: -380px;
  margin-top: 24px;
  overflow-y: scroll;
  background: #FFF;
  @include anim(500ms, ease); }

#wrapper.toggled {
  #sidebar-wrapper {
    width: 380px; } }

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 34px 0;
  @media all and (min-width: $screen-md) {
    padding: 4px 5px; } }

#wrapper.toggled {
  #page-content-wrapper {
    position: absolute;
    margin-right: -380px; } }

.fixed-brand {
  width: auto; }

.chip {
  background: #D1E9FF;
  color: #194185;
  font-size: 0.8em;
  padding: 2px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  margin-right: 5px; }

/* Sidebar Styles */

.utilities-nav-container {
  position: absolute;
  bottom: 55px;
  width: 100%;
  padding: 10px;
  .utilities-nav {
    border-top: 1px solid #555;
    list-style: none;
    padding: 15px;
    width: 380px;
    text-align: left;
    font-size: 0.875em;
    li {
      padding: 0px 0px;
      padding-top: 3px;
      padding-bottom: 3px;
      color: #616161;
      i.fa {
        font-size: 1.5em;
        display: none; }
      a {
        @include quickanim;
        color: #616161;
        display: block;
        &:focus, &:active, &:hover, &.active {
          color: white;
          text-decoration: none; } } } }
  .app-version {
    font-size: 0.875em;
    text-align: right;
    position: absolute;
    color: #616161;
    right: 10px;
    bottom: 37px; } }

.nav-pills {
  li.active {
    a {
      color: #000;
      background: #eff8ff;
      &:hover {
        color: #000;
        background: #eff8ff; }
      &:active {
        background: #1570ef; } } } }

.nav-pills {
  li.active {
    a {
      color: #000;
      background: #eff8ff;
      &:hover {
        background: #eff8ff; } } } }

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 380px;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 2px;
  li {
    line-height: 48px;
    text-indent: 15px;
    padding: 5px 0px;
    li {
      padding: 0px; }
    a {
      @include quickanim;
      display: block;
      text-decoration: none;
      color: #000000;
      border-radius: 50px;

      &:hover {
        text-decoration: none;
        color: #000;
        background: #eff8ff;
        .chip {
          color: #194185; } }
      &:active, &:focus {
        text-decoration: none;
        background-color: #1570ef;
        colo: #fff; } } }
  > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
    a {
      color: #999999;
      &:hover {
        color: #fff;
        background: none; } } }
  .navbar-icon {
    display: inline-block;
    margin-right: 12px;
    margin-left: -10px;
    width: 32px;
    svg {
      width: 15px;
      height: 15px;
      fill: currentColor; } }
  .header {
    line-height: 22px;
    height: 22px; }
  .navHeader {
    height: 20px;
    line-height: 15px;
    font-size: 10px;
    color: #616161;
    text-transform: uppercase;
    padding: 0px 10px; } }

@media only screen and (min-width: $screen-md) {
  #wrapper {
    padding-left: 380px; }
  .fixed-brand {
    width: 380px; }
  #wrapper.toggled {
    padding-left: 0; }
  #sidebar-wrapper {
    width: 380px; }
  #wrapper {
    &.toggled #sidebar-wrapper {
      width: 380px;
      li {
        width: 50px; }
      .navHeader {
        display: none; }
      .nav-link {
        width: 50px;
        .nav-title {
          display: none; } } }
    &.toggled-2 #sidebar-wrapper {
      width: 65px;
      &:hover {
        widh: 65px; } }

    &.toggled-2 #sidebar-wrapper {
      .navHeader {
        display: none; } }
    &.toggled-2 #sidebar-wrapper a {
      width: 55px;
      height: 55px;
      overflow: hidden;
      .sidebar-svg {
        line-height: 50px; }
      .nav-title {
        display: none; }
      .pull-right {
        display: none; }
      .navHeader {
        display: none; } }

    &.toggled-2 {
      .utilities-nav-container {
        padding: 0; }
      .utilities-nav {
        padding: 0;
        padding-bottom: 20px;
        width: 65px;
        text-align: center;
        li {
          margin-left: 5px;
          margin-right: 5px;
          width: 55px;
          height: 55px; }
        i.fa {
          font-size: 17px;
          display: block;
          color: #616161;
          padding: 10px; }
        .utility-text {
          color: pink;
          display: none; } }
      .app-version {
        display: inline;
        position: relative; } } }

  #page-content-wrapper {
    padding: 30px 15px;
    position: relative;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; }
  #wrapper {
    &.toggled #page-content-wrapper {
      position: relative;
      margin-right: 0;
      padding-left: 380px; }
    &.toggled-2 #page-content-wrapper {
      position: relative;
      margin-right: 0;
      margin-left: 50px;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease; } } }

@media only print {
  #wrapper {
    //- This is to deal with stupid chrome layout for print bug:
    padding: 0 !important;
    position: relative;
    #page-content-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 !important;
      margin: 0 !important; } } }

.alert-icon {
  position: relative;
  i.fa.small {
    font-size: 15px;
    position: absolute;
    left: 8px;
    top: 5px;
    color: #333;
    i.fa.smaller {
      font-size: 13px;
      position: absolute;
      left: 8px;
      top: 5px; } } }

.sync-wrap {
  display: inline;
  position: relative;
  .sync {
    position: absolute;
    top: 3px;
    display: inline-block;
    margin-left: 4px;
    height: 16px;
    width: 16px;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 0.5;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
    svg {
      width: 16px;
      position: absolute;
      fill: #999999;
      left: 0;
      top: -5px; }

    @keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
      100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg); } }

    @-webkit-keyframes spin {
      0% {}
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      100% {}
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg); } } }
