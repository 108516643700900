.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

.thumbnail {
  height: 200px; }
  .thumbnail img.pull-right {
    width: 50px; }

.pointer :hover {
  cursor: pointer; }

.dr-notification-container {
  position: absolute;
  z-index: 10000; }

.dr-notification-container.bottom {
  bottom: 20px; }

.dr-notification-container.right {
  right: 20px; }

.dr-notification-container.left {
  left: 20px; }

.dr-notification-container.top {
  top: 20px; }

.dr-notification-container.center {
  left: 50%;
  margin-left: -190px; }

.dr-notification-wrapper {
  width: 380px;
  position: relative;
  margin: 10px 0; }

.dr-notification {
  width: 380px;
  background-color: rgba(2, 45, 59, 0.85);
  clear: both;
  min-height: 80px;
  max-height: 90px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  color: #bfe2de;
  border: 1px solid rgba(4, 94, 123, 0.85);
  overflow: hidden; }

.dr-notification-close-btn {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  display: inline-block;
  padding: 3px;
  background-color: rgba(1, 26, 34, 0.85);
  font-size: 14px;
  color: #adfaff;
  border: 1px solid rgba(4, 94, 123, 0.85);
  position: absolute;
  right: -11px;
  top: 5px;
  -webkit-transition: all 0.35s cubic-bezier(0.31, 0.39, 0.21, 1.65);
  -moz-transition: all 0.35s cubic-bezier(0.31, 0.39, 0.21, 1.65);
  transition: all 0.35s cubic-bezier(0.31, 0.39, 0.21, 1.65);
  cursor: pointer; }

.dr-notification-close-btn i {
  padding-left: 3px; }

.dr-notification-close-btn:hover {
  -webkit-transform: scale3d(1.25, 1.25, 1);
  -moz-transform: scale3d(1.25, 1.25, 1);
  -ms-transform: scale3d(1.25, 1.25, 1);
  transform: scale3d(1.25, 1.25, 1); }

.dr-notification-image {
  width: 80px;
  height: 80px;
  border-right: 1px solid rgba(4, 94, 123, 0.85);
  float: left;
  display: block;
  font-size: 40px;
  color: white;
  text-align: center; }

.dr-notification-image i {
  display: block;
  width: 100%;
  padding-top: 25px; }

.dr-notification-image img {
  margin: 15px;
  max-width: 70px;
  min-width: 48px; }

.dr-notification-image.dr-notification-type-info {
  color: #FFF; }

.dr-notification-image.dr-notification-type-warning {
  color: #FFA226; }

.dr-notification-image.dr-notification-type-error {
  color: #FF4B4F; }

.dr-notification-image.dr-notification-type-success {
  color: #B4D455; }

.dr-notification-image.success {
  color: #B4D455; }

.dr-notification-content {
  padding-left: 100px;
  padding-right: 15px;
  padding-top: 10px; }

.dr-notification-title {
  color: white;
  margin: 0px;
  padding: 0px;
  font-size: 20px; }

p.dr-notification-text {
  margin-top: -5px;
  font-size: 12px; }

.mt-modal {
  padding: 0 25px 15px 25px;
  color: #777777; }

.mt-modal h3 {
  line-height: 1.3;
  font-size: 1.2em; }

.server-unavailable {
  background-color: #000;
  color: #fff;
  font-size: 1.5em;
  text-align: center;
  padding: 10px;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 3000;
  display: none; }
  .server-unavailable.show {
    display: block !important; }

a.reconnect {
  color: #fff;
  text-decoration: underline; }

.overlay {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 50px;
  z-index: 1020;
  height: 100%;
  width: 100%; }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(240, 240, 240, 0.2);
  z-index: 2000; }
  .loader img {
    margin-left: -150px;
    margin-top: -150px;
    position: absolute;
    top: 50%;
    left: 50%;
    size: 300px;
    -ms-animation: spin 2s linear infinite; }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

.ui-select-toggle {
  overflow: hidden; }

.white-container {
  padding: 0 20px 20px 20px;
  background-color: #fff; }

.hilight {
  font-weight: bold;
  color: black;
  background-color: rgba(98, 123, 204, 0.25); }

.hidden {
  display: none; }

html {
  background: url(../assets/images/cool-sky.jpg) no-repeat center top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }
  html.home {
    background: url(../assets/images/splash.jpg) no-repeat center top fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }
  html body {
    background-color: transparent; }

.entity input.wide {
  width: 90%; }

.entity input.full-width {
  width: 100%; }

.animate-messages {
  opacity: 0;
  height: 0; }
  .animate-messages.ng-active, .animate-messages.ng-inactive {
    transition: all 200ms ease-in-out; }
  .animate-messages.ng-active {
    opacity: 1;
    height: 34px; }
  .animate-messages.ng-inactive {
    opacity: 0;
    height: 0; }

.animate-height {
  height: 0px;
  background-color: coral;
  color: white;
  animation: slideHeight 2s infinite; }

/* Chrome, Safari, Opera */
@-webkit-keyframes slideHeight {
  50% {
    height: 200px; } }

/* Standard syntax */
@keyframes slideHeight {
  50% {
    height: 200px; } }

/* Import bootstrap-sass so that we have access to all of its selectors */
/*NAV BAR*/
.btn {
  font-weight: 500;
  transition: all ease-in-out 0.15s; }
  .btn.btn-left {
    text-align: left; }
  .btn.btn-no-border, .btn.btn-no-border:visited, .btn.btn-no-border:active, .btn.btn-no-border:hover, .btn.btn-no-border:focus {
    border: none; }

.btn-group.btn-group-justified.btn-dark-active .btn-default.active {
  color: white; }

.btn.btn-primary {
  border-color: #758bd2;
  background-color: #758bd2;
  color: white; }
  .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary.focus, .btn.btn-primary:active, .btn.btn-primary.active {
    background-color: #627bcc;
    border-color: #627bcc; }

.btn.btn-secondary {
  border-color: #555555;
  background-color: #555555;
  color: white; }
  .btn.btn-secondary:hover, .btn.btn-secondary:focus, .btn.btn-secondary.focus, .btn.btn-secondary:active, .btn.btn-secondary.active {
    border-color: #2f2f2f;
    background-color: #2f2f2f; }

.btn.btn-outline {
  border-color: #222222;
  background-color: transparent;
  color: #222222; }
  .btn.btn-outline:hover, .btn.btn-outline:focus, .btn.btn-outline.focus, .btn.btn-outline:active, .btn.btn-outline.active {
    border-color: black;
    color: black; }

.btn.btn-default {
  border-color: #999999; }
  .btn.btn-default:hover, .btn.btn-default:focus, .btn.btn-default.focus, .btn.btn-default:active, .btn.btn-default.active {
    border-color: #737373; }

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: 0; }

/* Disabled */
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  opacity: 0.5; }

.btn.btn-primary.disabled, .btn.btn-primary[disabled], fieldset[disabled] .btn.btn-primary {
  opacity: 0.25; }

/* Utilities for buttons */
.btn.btn-full-width {
  width: 100%; }

/* row buttons for Clinicians avail */
.btn.btn-row {
  width: 100%;
  border: 1px solid transparent;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 0.5em; }
  .btn.btn-row:hover, .btn.btn-row:focus, .btn.btn-row:active {
    border: 1px solid #999999;
    background-color: #999999;
    color: white; }

/* big buttons in grid */
.btn.btn-big-grid {
  width: 100%;
  padding: 2em 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: left; }
  .btn.btn-big-grid.btn-med {
    padding: 1em 15px; }
  .btn.btn-big-grid.btn-tight {
    margin-bottom: 0;
    font-size: 0.866666666em; }
  .btn.btn-big-grid, .btn.btn-big-grid * {
    white-space: normal; }
  .btn.btn-big-grid.active, .btn.btn-big-grid:active {
    background-color: #eeeeee; }

.btn.btn-big-grid .btn-big-grid-shortcut, .btn.btn-big-grid .btn-big-grid-txt, .btn.btn-row .btn-big-grid-shortcut, .btn.btn-row .btn-big-grid-txt {
  display: block;
  min-height: 0px; }
  @media all and (min-width: 992px) {
    .btn.btn-big-grid .btn-big-grid-shortcut, .btn.btn-big-grid .btn-big-grid-txt, .btn.btn-row .btn-big-grid-shortcut, .btn.btn-row .btn-big-grid-txt {
      min-height: 45px; }
      .btn.btn-big-grid .btn-big-grid-shortcut.btn-no-minheight, .btn.btn-big-grid .btn-big-grid-txt.btn-no-minheight, .btn.btn-row .btn-big-grid-shortcut.btn-no-minheight, .btn.btn-row .btn-big-grid-txt.btn-no-minheight {
        min-height: 0; } }

.btn.btn-big-grid .btn-big-grid-shortcut, .btn.btn-row .btn-big-grid-shortcut {
  float: left;
  color: #cccccc;
  font-weight: 700;
  padding-right: 15px;
  margin-bottom: 0; }

.btn.btn-row .btn-big-grid-txt {
  float: left; }

.btn {
  margin: 0; }

/*NAV BAR*/
/*NAV BAR*/
/* mixins */
/* anims */
@-o-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-moz-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-webkit-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-o-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-moz-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-webkit-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0 none; }

select.form-control.select-nrm {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  font-size: 0.8em;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%; }

select.btn-filter {
  width: 100%;
  background-image: url("../assets/images/icon-filter-grey.svg"), url("../assets/images/icon-pointer-down-grey.png");
  background-repeat: no-repeat, no-repeat;
  background-position: 12px center, right center;
  background-size: 12px 12px, 21px 5px;
  padding-left: 30px;
  padding-right: 30px;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: hidden; }

.bg-dash {
  background: url("../assets/images/dash.png") no-repeat center center;
  background-size: 10px  1px; }
  @media (min-width: 990px) {
    .bg-dash {
      background-position: 25% center; } }

.form-control {
  border-radius: 0;
  box-shadow: none;
  color: #777777;
  border: 1px solid #999999; }
  .form-control[type="checkbox"] {
    width: auto;
    height: auto;
    display: inline-block; }
  .form-control:hover {
    border-color: #878787;
    color: #878787; }
  .form-control:focus, .form-control.focus, .form-control:active, .form-control.active {
    outline: none;
    box-shadow: none;
    color: black;
    border-color: black; }
  .form-control.input-wide {
    min-width: 220px; }

textarea.form-control {
  border: 1px solid transparent; }
  textarea.form-control:focus, textarea.form-control.focus, textarea.form-control:active, textarea.form-control.active {
    border: 1px solid #627bcc; }

.form-control.big-input {
  -o-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  border: 1px solid transparent;
  z-index: 1;
  font-size: 1.33333333em;
  padding: 0.25em 0 0 0;
  height: auto;
  color: #333333; }
  .form-control.big-input:hover {
    text-shadow: 1px 1px 1px rgba(51, 51, 51, 0.2); }
  .form-control.big-input:focus, .form-control.big-input.focus, .form-control.big-input:active, .form-control.big-input.active {
    color: #3e5cbd; }
    .form-control.big-input:focus:hover, .form-control.big-input.focus:hover, .form-control.big-input:active:hover, .form-control.big-input.active:hover {
      text-shadow: 1px 1px 1px rgba(98, 123, 204, 0.25); }

.form-control[disabled].big-input, .form-control[readonly].big-input, fieldset[disabled].form-control.big-input {
  background-color: white; }
  .form-control[disabled].big-input:hover, .form-control[readonly].big-input:hover, fieldset[disabled].form-control.big-input:hover {
    text-shadow: none; }
  .form-control[disabled].big-input:focus, .form-control[disabled].big-input.focus, .form-control[disabled].big-input:active, .form-control[disabled].big-input.active, .form-control[readonly].big-input:focus, .form-control[readonly].big-input.focus, .form-control[readonly].big-input:active, .form-control[readonly].big-input.active, fieldset[disabled].form-control.big-input:focus, fieldset[disabled].form-control.big-input.focus, fieldset[disabled].form-control.big-input:active, fieldset[disabled].form-control.big-input.active {
    color: #8e8e93; }

.form-control.big-input.med-input {
  font-size: 1em;
  padding: 0; }
  .form-control.big-input.med-input:focus, .form-control.big-input.med-input.focus, .form-control.big-input.med-input:active, .form-control.big-input.med-input.active {
    color: #627bcc; }
  .form-control.big-input.med-input.ward-input:before {
    content: "\f041"; }

.form-control.big-input.med-input.select {
  font-size: 1em; }

.form-control.big-input.sm-input {
  font-size: 1.333333333333em;
  margin-bottom: 15px; }

.invalid {
  color: #74747a;
  font-style: oblique;
  padding-top: 0.25em;
  display: inline-block;
  opacity: 1;
  height: 22px; }
  .invalid, .invalid * {
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in; }
  .invalid.ng-hide {
    opacity: 0;
    height: 0px; }
    .invalid.ng-hide, .invalid.ng-hide * {
      -o-transition: all 200ms ease-out;
      -moz-transition: all 200ms ease-out;
      -webkit-transition: all 200ms ease-out;
      transition: all 200ms ease-out; }

.none {
  display: none; }

.cursor-default {
  cursor: default; }

.error {
  color: #cd1419; }

.warning {
  color: #d96616; }

.error, .warning, .required {
  font-weight: 300;
  font-size: 0.86666em;
  font-style: italic;
  padding: 0.33333em 0 0 0.3333em; }
  .error, .error *, .warning, .warning *, .required, .required * {
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in; }
  .error.ng-hide, .warning.ng-hide, .required.ng-hide {
    opacity: 0;
    height: 0px; }
    .error.ng-hide, .error.ng-hide *, .warning.ng-hide, .warning.ng-hide *, .required.ng-hide, .required.ng-hide * {
      -o-transition: all 200ms ease-out;
      -moz-transition: all 200ms ease-out;
      -webkit-transition: all 200ms ease-out;
      transition: all 200ms ease-out; }
  .error.Pre-MET, .error.MET, .error.Code.Blue, .warning.Pre-MET, .warning.MET, .warning.Code.Blue, .required.Pre-MET, .required.MET, .required.Code.Blue {
    color: white;
    display: inline;
    padding: 0.15em 0.3333em 0.2em;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
  .error.Pre-MET, .warning.Pre-MET, .required.Pre-MET {
    background-color: #d96616; }
  .error.MET, .warning.MET, .required.MET {
    background-color: #cd1419; }
  .error.Code.Blue, .warning.Code.Blue, .required.Code.Blue {
    background-color: #3752aa; }

.big-input-label {
  color: #8e8e93;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 0px; }
  .big-input-label.med-input-label {
    margin-top: 5px; }
  .big-input-label.red {
    color: #cd1419; }

.right-input-label {
  margin-top: 12px;
  font-size: 0.8em; }

.sit-right-label {
  position: absolute;
  right: 15px;
  top: 12px;
  font-weight: 400; }

.big-input-advanced {
  position: absolute;
  z-index: 3;
  font-weight: 300;
  top: 0px;
  right: 5px; }
  .big-input-advanced .fa {
    padding-left: 0.5em; }

.hint-label {
  font-size: 0.8em;
  font-weight: 300;
  display: block;
  color: #b3b3b3; }
  .hint-label.label-lg {
    padding: 5px 16px; }
  .hint-label.inline {
    display: inline; }

.spacer-group {
  padding-left: 4px;
  padding-right: 4px; }
  .spacer-group:first-child {
    padding-left: 0; }
  .spacer-group:last-child {
    padding-right: 0; }

section .remove-margin {
  margin-top: 0px; }

/* UI SELECT Styling */
.ui-select-match {
  overflow: visible;
  padding-left: 1px;
  /*select*/ }
  .ui-select-match.btn-default-focus {
    outline: none;
    box-shadow: none;
    background-color: transparent; }
    .ui-select-match.btn-default-focus, .ui-select-match.btn-default-focus * {
      color: #627bcc; }
  .ui-select-match .btn.form-control {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border-color: inherit; }
  .ui-select-match .ui-select-placeholder.text-muted {
    font-style: italic;
    opacity: 1;
    color: #b3b3b3; }
  .ui-select-match.btn-default-focus .ui-select-placeholder.text-muted {
    color: #627bcc; }

/* dropdown background color */
.ui-select-bootstrap .ui-select-choices-row.active > a {
  background-color: #627bcc; }

.form-control {
  font-weight: 500;
  color: #555; }
  .form-control:focus, .form-control.focus, .form-control:active, .form-control.active {
    color: #627bcc; }

.form-control.ui-select-search {
  -webkit-border-radius: 3px;
  border-radius: 3px; }

.text-muted {
  color: #a8a8ac; }

.form-inline .form-control {
  display: inline-block;
  width: auto; }
  .form-inline .form-control.min-width {
    min-width: 60px; }
  .form-inline .form-control.med-input {
    vertical-align: baseline; }

.sb-search {
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 36px;
  height: 30px;
  float: right;
  overflow: hidden;
  -webkit-backface-visibility: hidden; }
  .sb-search .form-control.big-input.med-input.sb-search-input {
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    outline: none;
    width: 100%;
    height: 30px;
    margin: 0;
    z-index: 10;
    padding-right: 20px;
    padding-left: 10px; }
    .sb-search .form-control.big-input.med-input.sb-search-input:focus, .sb-search .form-control.big-input.med-input.sb-search-input:active {
      width: 100%; }
  .sb-search button.sb-icon-search {
    height: 30px;
    z-index: 90;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    cursor: pointer; }

@media all and (max-width: 1090px) {
  .pad-if-multirow {
    padding-bottom: 20px; }
  .big-input {
    font-size: auto !important; } }

label.label {
  font-family: 'Helvetica Neue', Helvetica, 'Segoe UI', Arial, sans-serif;
  display: inline-block;
  padding: 2px 4px;
  margin-right: 4px;
  font-size: 11.844px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 14px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 3px; }
  label.label.tiny-outline {
    padding: 1px 4px 3px;
    background-color: #b3b3b3;
    float: left;
    margin-right: 6px;
    text-shadow: 0 1px 0 #bdc2c7, 0 2px 0 #b8bdc2, 0 3px 1px rgba(0, 0, 0, 0.15), 0 0 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3); }
    label.label.tiny-outline.line-height30 {
      margin-top: 6px; }
    label.label.tiny-outline.tiny-fixed-width {
      width: 1.5em;
      text-align: center; }

.icon-tiny-sibling {
  margin-left: 4px;
  margin-right: 6px; }

/* Radio buttons */
[type="radio"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

label.radio {
  display: inline-block;
  cursor: pointer;
  line-height: 1.5;
  font-size: 1.066666em;
  margin: 0 0.666em 0 0;
  color: #8e8e93; }

/* the basic, unchecked style */
[type="radio"] + span:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
  border: 0.15em solid #fff;
  margin-right: 0.5em;
  -webkit-border-radius: 1em;
  border-radius: 1em;
  -webkit-box-shadow: 0 0 0 0.15em #e0e0e0;
  -moz-box-shadow: 0 0 0 0.15em #e0e0e0;
  box-shadow: 0 0 0 0.15em #e0e0e0;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease; }

/* the checked style using the :checked pseudo class */
[type="radio"]:checked + span:before {
  background: #627bcc;
  -webkit-box-shadow: 0 0 0 0.25em #b3b3b3;
  -moz-box-shadow: 0 0 0 0.25em #b3b3b3;
  box-shadow: 0 0 0 0.25em #b3b3b3; }

/* when radio is focused */
[type="radio"]:focus + span:before {
  -webkit-box-shadow: 0 0 0 0.15em #e0e0e0, 0 0 5px 0 rgba(98, 123, 204, 0.15);
  -moz-box-shadow: 0 0 0 0.15em #e0e0e0, 0 0 5px 0 rgba(98, 123, 204, 0.15);
  box-shadow: 0 0 0 0.15em #e0e0e0, 0 0 5px 0 rgba(98, 123, 204, 0.15); }

[type="radio"]:checked:focus + span:before {
  -webkit-box-shadow: 0 0 0 0.25em #cccccc, 0 0 5px 0 rgba(98, 123, 204, 0.15);
  -moz-box-shadow: 0 0 0 0.25em #cccccc, 0 0 5px 0 rgba(98, 123, 204, 0.15);
  box-shadow: 0 0 0 0.25em #cccccc, 0 0 5px 0 rgba(98, 123, 204, 0.15); }

/* CHECKBOXES */
/* Base for label styling */
.normCheck[type="checkbox"] {
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
  /* disabled checkbox */
  /* accessibility */
  /* hover style just for information */ }
  .normCheck[type="checkbox"]:not(:checked), .normCheck[type="checkbox"]:checked {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .normCheck[type="checkbox"]:not(:checked) + label, .normCheck[type="checkbox"]:checked + label {
    position: relative;
    cursor: pointer;
    margin: 0 0.666em 0 0;
    padding-left: 1.5em;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease; }
  .normCheck[type="checkbox"]:not(:checked) + label:before, .normCheck[type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 1em;
    height: 1em;
    background: white;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease; }
  .normCheck[type="checkbox"]:not(:checked) + label:before {
    -webkit-box-shadow: 0 0 0 0.15em #e0e0e0;
    -moz-box-shadow: 0 0 0 0.15em #e0e0e0;
    box-shadow: 0 0 0 0.15em #e0e0e0; }
  .normCheck[type="checkbox"]:checked + label:before {
    -webkit-box-shadow: 0 0 0 0.25em #b3b3b3;
    -moz-box-shadow: 0 0 0 0.25em #b3b3b3;
    box-shadow: 0 0 0 0.25em #b3b3b3; }
  .normCheck[type="checkbox"]:not(:checked) + label:after, .normCheck[type="checkbox"]:checked + label:after {
    content: '\f00c';
    font: normal normal normal 0.75em/1 FontAwesome;
    position: absolute;
    top: 4px;
    left: 2px;
    width: 1em;
    height: 1em;
    color: #627bcc;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease; }
  .normCheck[type="checkbox"]:not(:checked) + label:after, .normCheck[type="checkbox"]:disabled + label:after {
    opacity: 0;
    transform: scale(0); }
  .normCheck[type="checkbox"]:not(:disabled):checked + label:after {
    opacity: 1;
    transform: scale(1); }
  .normCheck[type="checkbox"]:disabled + label, .normCheck[type="checkbox"]:disabled + label:hover {
    color: #b3b3b3; }
  .normCheck[type="checkbox"]:disabled:not(:checked) + label:before {
    -webkit-box-shadow: 0 0 0 0.15em #eeeeee;
    -moz-box-shadow: 0 0 0 0.15em #eeeeee;
    box-shadow: 0 0 0 0.15em #eeeeee;
    background-color: #eeeeee; }
  .normCheck[type="checkbox"]:disabled:checked + label:before {
    -webkit-box-shadow: 0 0 0 0.25em #eeeeee;
    -moz-box-shadow: 0 0 0 0.25em #eeeeee;
    box-shadow: 0 0 0 0.25em #eeeeee;
    background-color: #eeeeee; }
  .normCheck[type="checkbox"]:disabled:checked + label:after {
    color: #b3b3b3; }
  .normCheck[type="checkbox"]:checked:focus + label:before {
    -webkit-box-shadow: 0 0 0 0.25em #627bcc;
    -moz-box-shadow: 0 0 0 0.25em #627bcc;
    box-shadow: 0 0 0 0.25em #627bcc; }
  .normCheck[type="checkbox"]:not(:checked):focus + label:before {
    -webkit-box-shadow: 0 0 0 0.15em #889bd9;
    -moz-box-shadow: 0 0 0 0.15em #889bd9;
    box-shadow: 0 0 0 0.15em #889bd9; }
  .normCheck[type="checkbox"] + label:hover {
    color: #627bcc; }

/* Placeholders */
.form-control::-moz-placeholder {
  -o-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  font-style: italic;
  opacity: 1;
  color: #b3b3b3; }

.form-control:-ms-input-placeholder {
  -o-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  font-style: italic;
  opacity: 1;
  color: #b3b3b3; }

.form-control::-webkit-input-placeholder {
  -o-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  font-style: italic;
  opacity: 1;
  color: #b3b3b3; }

.form-control:hover::-moz-placeholder, .form-control.hover::-moz-placeholder {
  opacity: 1;
  color: #777777; }

.form-control:hover::-ms-input-placeholder, .form-control.hover::-ms-input-placeholder {
  opacity: 1;
  color: #777777; }

.form-control:hover::-webkit-input-placeholder, .form-control.hover::-webkit-input-placeholder {
  opacity: 1;
  color: #777777; }

.form-control:focus::-moz-placeholder, .form-control.focus::-moz-placeholder, .form-control:active::-moz-placeholder, .form-control.active::-moz-placeholder {
  opacity: 1;
  color: #627bcc; }

.form-control:focus::-ms-input-placeholder, .form-control.focus::-ms-input-placeholder, .form-control:active::-ms-input-placeholder, .form-control.active::-ms-input-placeholder {
  opacity: 1;
  color: #627bcc; }

.form-control:focus::-webkit-input-placeholder, .form-control.focus::-webkit-input-placeholder, .form-control:active::-webkit-input-placeholder, .form-control.active::-webkit-input-placeholder {
  opacity: 1;
  color: #627bcc; }

/*Placeholders with error on field*/
.jslider .jslider-value {
  font-size: 1.1em !important;
  top: -30px !important; }

.jslider .jslider-scale ins {
  font-size: 12px !important;
  left: -12px !important; }

/* mixins */
/* anims */
@-o-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-moz-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-webkit-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-o-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-moz-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-webkit-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

/*NAV BAR*/
/* mixins */
/* anims */
@-o-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-moz-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-webkit-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-o-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-moz-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-webkit-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

.modal-body .more-info {
  font-size: 1em; }

.modal-body a.show-more {
  color: #627bcc;
  font-size: 1em;
  display: block; }

.modal-title {
  color: #333333; }

.modal-icon-header {
  padding: 2em 0;
  text-align: center;
  color: white; }

.modal-error .modal-icon-header {
  background-color: #fef2f2;
  color: #cd1419;
  border-radius: 5px 5px 0 0; }

.modal-warning .modal-icon-header {
  background-color: #fff8e8;
  color: #d99800; }

.modal-info .modal-icon-header, .modal-sent .modal-icon-header {
  background-color: #627bcc; }

.modal-cancel .modal-icon-header, .modal-fwd .modal-icon-header, .modal-annotation .modal-icon-header {
  background-color: #555555; }

.modal-footer {
  border: none; }

@media print {
  * {
    background: transparent !important;
    color: black;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important; }
  a, a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
    /* Don't show links for images, or javascript/internal links */ }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.3cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  .no-print {
    display: none; }
  body {
    padding-top: 0 !important; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; } }

@media screen {
  .print-only {
    display: none; } }

@media print {
  .screen-only {
    display: none; } }

/*NAV BAR*/
/* mixins */
/* anims */
@-o-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-moz-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-webkit-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-o-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-moz-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-webkit-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

body {
  padding-top: 51px;
  background-color: #e0e0e0;
  -webkit-font-smoothing: antialiased; }

h4, h5, h6, .h4, .h5, .h6 {
  font-weight: 700; }

h1.h1-admin {
  color: black;
  padding-top: 20px;
  font-size: 30px;
  margin-top: 0; }

footer {
  background-color: white;
  border-top: 1px solid #eeeeee;
  padding-top: 2em;
  margin-top: 4em; }

blockquote {
  border-left: 3px solid #cccccc;
  font-style: oblique;
  padding-top: 6px;
  margin-top: 1em;
  margin-bottom: 1em; }

a {
  -o-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  cursor: pointer; }
  a:hover, a:focus {
    color: auto;
    text-decoration: underline; }
    a:hover i.fa.fa-margR, a:focus i.fa.fa-margR {
      text-decoration: none; }
  a i.fa.fa-margR {
    margin-right: 0.2em; }
  a.white {
    color: rgba(255, 255, 255, 0.9); }
    a.white:hover, a.white:focus, a.white:active, a.white.active {
      color: white; }
  a.red {
    color: #cd1419; }
    a.red:hover, a.red:focus, a.red:active, a.red.active {
      color: #9f0f13; }
  a.blue {
    color: #889bd9; }
    a.blue:hover, a.blue:focus, a.blue:active, a.blue.active {
      color: #627bcc;
      text-decoration: none; }

/* SVGs */
i.svg-10 svg {
  width: 10px;
  height: 10px;
  margin: 0; }

i.svg-12 svg {
  width: 12px;
  height: 12px;
  margin-left: 3px;
  margin-right: 3px; }

i.svg-16 svg {
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-right: 3px; }

i.svg-24 svg {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-right: 4px; }

i.svg-32 svg {
  width: 32px;
  height: 32px;
  margin-left: 4px;
  margin-right: 4px; }

i.svg-48 svg {
  width: 48px;
  height: 48px;
  margin-left: 4px;
  margin-right: 4px; }

i.svg-64 svg {
  width: 64px;
  height: 64px;
  margin-left: 4px;
  margin-right: 4px; }

i svg {
  fill: currentColor;
  display: inline-block; }

i.no-margin svg {
  margin: 0; }

/* helper classes */
.italic {
  font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 400; }

.normal {
  font-weight: 400; }

.uppercase {
  text-transform: uppercase; }

.red {
  color: #cd1419; }

.urgent {
  color: #d99800; }

.base {
  color: #000; }

.nowrap {
  white-space: nowrap; }

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.pad-top {
  padding-top: 1em; }

.pad-bot {
  padding-bottom: 1em; }

.pad-top40 {
  padding-top: 40px; }

.pad-top-bot {
  padding-top: 10px;
  padding-bottom: 10px; }

.pad-sides {
  padding-left: 1em;
  padding-right: 1em; }

.text-medium {
  font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500; }

.white-bg {
  background-color: white; }

.inline-block {
  display: inline-block; }

.copy {
  color: #8e8e93; }

.dark {
  color: #000; }

.highlight-clr {
  color: #627bcc; }

.dark-copy {
  color: #5b5b60; }

.strikethru {
  text-decoration: line-through; }

.no-border {
  border: none; }

.nav-tabs.no-border > li {
  margin-bottom: 0; }

.pointer {
  cursor: pointer; }

.heading-sentence {
  text-transform: capitalize; }

.gray-bg {
  background-color: #e0e0e0; }

.highlight-bg {
  background-color: #b4cbe0; }

.inline-block {
  display: inline-block; }

.inline {
  display: inline; }

.margin-0, .no-margin {
  margin: 0; }

.right {
  float: right; }

.text-big {
  font-size: 1.5em; }

h1.heading-nmt, h2.heading-nmt, h3.heading-nmt, h4.heading-nmt, h5.heading-nmt, h6.heading-nmt {
  margin-top: 0; }

.row-nomargin {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.pad-col {
  padding-top: 10px; }

.pad-col2 {
  padding-top: 6px; }

/* layout classes move this elsewhere */
.gray-container {
  background-color: #F5F5F5;
  padding: 1.5em; }

.trans-container {
  padding: 0 1.5em; }

.white-container-skinny {
  background-color: white;
  padding: 0.5em 1em; }

.gray-container.margin-bottom, .task-container.margin-bottom, .trans-container.margin-bottom, .white-container.margin-bottom, .white-container-skinny.margin-bottom {
  margin-bottom: 0.5em; }

/* responsive utility classes */
.width80 {
  width: 80%; }

.width100 {
  width: 100%; }

.push-down {
  padding-top: 10px; }
  @media all and (min-width: 768px) {
    .push-down {
      padding-top: 30px; } }
  @media all and (min-width: 992px) {
    .push-down {
      padding-top: 50px; } }
  @media all and (min-width: 1200px) {
    .push-down {
      padding-top: 60px; } }

/*NAV BAR*/
/* mixins */
/* anims */
@-o-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-moz-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-webkit-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-o-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-moz-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-webkit-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

.compose section#section-2, .compose section#section-2B {
  padding-top: 0.3333em; }

.compose .row.remove-margin {
  margin-left: 0;
  margin-right: 0; }

.compose .border-bottom {
  margin-bottom: 1.3333333em;
  padding-bottom: 0.6666666em;
  border-bottom: 1px solid #eeeeee; }

.compose p.border-bottom {
  padding-bottom: 1.3333333em; }

.compose .notPatientRelated {
  margin: 5px 0;
  -o-animation: animateButton 150ms ease-in;
  -moz-animation: animateButton 150ms ease-in;
  -webkit-animation: animateButton 150ms ease-in;
  animation: animateButton 150ms ease-in; }
  .compose .notPatientRelated.ng-hide {
    -o-animation: animateButton 150ms ease-in alternate;
    -moz-animation: animateButton 150ms ease-in alternate;
    -webkit-animation: animateButton 150ms ease-in alternate;
    animation: animateButton 150ms ease-in alternate; }

.compose .ccs {
  margin-top: 10px; }
  .compose .ccs .name {
    color: #333333; }

.compose .remove {
  font-size: small;
  font-weight: bold;
  float: right;
  color: #cd1419;
  padding-right: 50px; }

.compose .toggle-cc {
  position: absolute;
  right: 20px;
  margin-top: -10px;
  font-size: 18px; }

.compose .observation-header {
  padding: 10px 5px;
  margin: 20px 0 10px;
  background-color: #f5f5f5;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #bdbdbd; }

.compose .list-group-item.active, .compose .list-group-item.active:hover, .compose .list-group-item.active:focus {
  background-color: #627bcc;
  border-color: #627bcc; }

.compose .list-group-item.highlighted, .compose .list-group-item.highlighted:hover, .compose .list-group-item.highlighted:focus {
  background-color: #ddd;
  border-color: #ddd; }

.compose .pager-preview .message {
  -webkit-border-radius: 15px;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 0 1px #e5e5e5, inset 1px 1px 2px 0 #ccc;
  -moz-box-shadow: 0 0 0 1px #e5e5e5, inset 1px 1px 2px 0 #ccc;
  box-shadow: 0 0 0 1px #e5e5e5, inset 1px 1px 2px 0 #ccc;
  border: 15px solid white;
  border-top: 30px solid white;
  border-bottom: 20px solid white;
  background-color: rgba(98, 123, 204, 0.15);
  padding: 10px 12px;
  font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
  font-weight: bold;
  font-size: 12px;
  color: #777777; }

.compose .pager-preview .pager-header {
  font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: oblique;
  font-weight: 400;
  color: #b3b3b3;
  position: absolute;
  top: 5px;
  left: 30px;
  font-size: 14px; }

.compose .pager-preview .chars-over {
  color: #ffb70d; }

.compose .pager-preview .extra-chars {
  color: #ca8d00;
  background-color: rgba(255, 255, 255, 0.5); }

.compose .form-inline .form-group input[type="text"].form-control {
  width: auto;
  display: inline-block; }
  .compose .form-inline .form-group input[type="text"].form-control.small-input {
    max-width: 60px; }
  .compose .form-inline .form-group input[type="text"].form-control.phone-input {
    width: 100px; }
  .compose .form-inline .form-group input[type="text"].form-control.pager-input {
    width: 70px; }

.compose .compose-help {
  font-style: italic;
  color: #627bcc; }

.compose .form-group.campus .ui-select-container {
  display: inline-block;
  margin-right: 30px;
  width: 220px;
  padding-right: 10px; }

.compose .form-group.ward .form-control {
  width: 200px !important;
  margin-right: 10px; }

.compose .btn.btn-info#task-btn {
  border-color: #ddd; }
  .compose .btn.btn-info#task-btn:focus, .compose .btn.btn-info#task-btn.focus, .compose .btn.btn-info#task-btn:active:focus, .compose .btn.btn-info#task-btn:active.focus, .compose .btn.btn-info#task-btn.active:focus, .compose .btn.btn-info#task-btn.active.focus {
    border-color: #627bcc; }

.compose .task-panel {
  -webkit-transition: max-height ease-in 0.2s;
  transition: max-height ease-in 0.2s; }

.compose .task-panel.ng-hide {
  height: auto;
  max-height: 0; }

.compose .btn-group-vertical.block {
  display: block; }

.compose .covering {
  font-style: italic; }

.compose a.search-select {
  width: 120px;
  display: inline-block;
  font-size: 18px;
  color: #fff;
  opacity: 0.5; }
  .compose a.search-select.selected {
    opacity: 1; }

.compose select.form-control.select-nrm {
  color: #8e8e93; }
  .compose select.form-control.select-nrm.met, .compose select.form-control.select-nrm.premet, .compose select.form-control.select-nrm.urgent {
    color: white;
    border: none; }
  .compose select.form-control.select-nrm.met {
    background-color: #cd1419; }
  .compose select.form-control.select-nrm.premet {
    background-color: #d96616; }
  .compose select.form-control.select-nrm.urgent {
    background-color: #d99800; }

.compose .ui-select-container.ui-select-bootstrap.dropdown {
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  .compose .ui-select-container.ui-select-bootstrap.dropdown .btn-default-focus:focus {
    background-color: red;
    outline: none; }
  .compose .ui-select-container.ui-select-bootstrap.dropdown .dropdown-menu {
    background-color: white;
    padding: 0;
    width: auto; }
    .compose .ui-select-container.ui-select-bootstrap.dropdown .dropdown-menu .ui-select-choices-row.active > a {
      background-color: #627bcc; }
    .compose .ui-select-container.ui-select-bootstrap.dropdown .dropdown-menu .ui-select-choices-row > a {
      font-size: 0.8em;
      padding: 3px 10px; }
      .compose .ui-select-container.ui-select-bootstrap.dropdown .dropdown-menu .ui-select-choices-row > a small {
        margin-right: 10px;
        min-width: 50px;
        display: inline-block; }
  .compose .ui-select-container.ui-select-bootstrap.dropdown .form-control.ui-select-search {
    font-size: 1em;
    border: 0;
    padding: 0;
    background-color: transparent;
    height: auto;
    text-transform: uppercase; }
  .compose .ui-select-container.ui-select-bootstrap.dropdown .ui-select-toggle.form-control.btn {
    font-size: 1em;
    height: auto;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-transform: uppercase;
    padding: 0;
    border: 0;
    background-color: transparent; }
    .compose .ui-select-container.ui-select-bootstrap.dropdown .ui-select-toggle.form-control.btn a.btn.btn-xs.btn-link.pull-right {
      padding: 0 5px 0 0;
      border: none;
      line-height: 1; }
      .compose .ui-select-container.ui-select-bootstrap.dropdown .ui-select-toggle.form-control.btn a.btn.btn-xs.btn-link.pull-right .glyphicon {
        top: 4px; }
    .compose .ui-select-container.ui-select-bootstrap.dropdown .ui-select-toggle.form-control.btn > .caret {
      top: 7px;
      right: 0px; }

.compose .custom {
  background-color: #eee;
  margin: 10px -15px 10px -13px;
  padding: 0 10px 20px 20px; }
  .compose .custom .custom-field.half-width {
    width: 50%;
    display: inline-block;
    padding-right: 15px; }
  .compose .custom .custom-field.full-width {
    width: 100%;
    clear: left; }
  .compose .custom .check {
    display: inline-block;
    margin-right: 25px; }
    .compose .custom .check label {
      font-size: small;
      display: block;
      margin-bottom: 5px !important; }
    .compose .custom .check .mt-checkbox {
      width: 22px;
      height: 22px; }
  .compose .custom .form-control.custom-select {
    width: 100%;
    background-color: #fff;
    padding: 5px !important;
    border-radius: 5px;
    border-color: #999; }
    .compose .custom .form-control.custom-select.ng-invalid {
      border-color: #cd1419; }
    .compose .custom .form-control.custom-select .ui-select-toggle {
      display: inline-block;
      width: 100%; }

.compose .shift-up {
  margin-top: -15px; }

.compose .form-control.ta-select.ui-select-container.big-input {
  z-index: inherit; }

.compose .form-control.ta-select.ui-select-container.custom-select {
  padding: 5px;
  border: 1px solid #999999;
  border-radius: 5px;
  width: 215px; }

.compose .form-control.ta-select.ui-select-container .ui-select-placeholder {
  text-transform: none;
  padding-right: 10px !important;
  font-size: 1em; }

.compose .form-control.ta-select.ui-select-container .ui-select-match .ng-binding {
  padding-right: 5px; }

.compose .form-control.ta-select.ui-select-container .ui-select-match .ui-select-match-text {
  text-transform: none;
  max-width: 280px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.compose .form-control.ta-select.ui-select-container input.ui-select-search {
  text-transform: none !important; }

.compose .form-control.campus-select.ui-select-container {
  /* fix text overflow issue */ }
  .compose .form-control.campus-select.ui-select-container .ui-select-match .ui-select-match-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%; }
  .compose .form-control.campus-select.ui-select-container .ui-select-toggle {
    width: 250px; }

.compose .form-group.campus .ui-select-container.ui-select-bootstrap.dropdown .ui-select-toggle.form-control.btn {
  text-transform: none; }

.compose #observations .btn-default:active, .compose #observations .btn-default.active {
  color: white;
  background-color: #627bcc;
  border-color: #627bcc; }

.compose #observations input.obs {
  min-width: 149px; }

.compose #input_role {
  min-width: 200px; }

.compose .white-container {
  padding: 15px 20px 20px;
  margin-bottom: 1.5em; }
  .compose .white-container .form-inline .form-group label {
    margin: 0; }
  .compose .white-container .practitioners, .compose .white-container .practitioners-heading, .compose .white-container .campus-heading {
    margin-left: 0;
    margin-right: 0; }
  .compose .white-container .practitioners-heading, .compose .white-container .campus-heading {
    font-weight: 400;
    font-size: 0.8em;
    padding-bottom: 0.75em;
    padding-top: 0.75em;
    background-color: #eeeeee;
    border: 1px solid #eeeeee; }
    .compose .white-container .practitioners-heading img, .compose .white-container .campus-heading img {
      vertical-align: top; }
  .compose .white-container .campus-heading {
    background-color: #555555;
    border: none; }
    .compose .white-container .campus-heading label {
      color: #b3b3b3;
      float: left;
      margin: 0;
      padding: 2px 4px 0 0; }
    .compose .white-container .campus-heading .form-control {
      color: white;
      display: inline-block;
      width: auto; }
    .compose .white-container .campus-heading .ui-select-container {
      display: inline-block; }
      .compose .white-container .campus-heading .ui-select-container .ui-select-match.btn-default-focus, .compose .white-container .campus-heading .ui-select-container .ui-select-match.btn-default-focus * {
        color: white; }
      .compose .white-container .campus-heading .ui-select-container .ui-select-match-text span {
        padding-right: 15px; }
  .compose .white-container .practitioners {
    border: 1px solid #eeeeee;
    border-top-color: transparent;
    padding-bottom: 0.75em;
    padding-top: 0.75em;
    font-weight: 400;
    font-size: 0.875em;
    cursor: pointer; }
    .compose .white-container .practitioners.border-top {
      margin-top: 2px;
      border-top-color: #eeeeee; }
    @media screen and (min-width: 1200px) {
      .compose .white-container .practitioners {
        font-size: 1em; } }
    .compose .white-container .practitioners:hover, .compose .white-container .practitioners:focus, .compose .white-container .practitioners.focus, .compose .white-container .practitioners:active, .compose .white-container .practitioners.active, .compose .white-container .practitioners.selected {
      background-color: white;
      border-color: #afbce5;
      color: black; }
    .compose .white-container .practitioners .roles {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .compose .white-container .overflow {
    background-color: #eeeeee; }
    .compose .white-container .overflow a {
      color: #333333; }
  .compose .white-container .spid {
    display: inline-block;
    min-width: 80px; }
  .compose .white-container .dropdown-holder {
    position: relative; }
    .compose .white-container .dropdown-holder .dropdown-panel {
      position: absolute;
      z-index: 1000;
      top: 0;
      width: 100%;
      background-color: white;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }

.compose input[type="text"].form-control::-ms-clear {
  display: none; }

.task-description {
  background: repeating-linear-gradient(180deg, #fff, #fff 29px, #f0f1f2 29px, #f0f1f2 30px);
  padding: 8px 12px 20px;
  font-size: 18px;
  line-height: 30px;
  width: 100%;
  color: #333333;
  margin: 0;
  border: 0;
  -webkit-box-shadow: 0 0 0 1px #d4d4d4, 0 1px 2px 0 rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 0 0 1px #d4d4d4, 0 1px 2px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 0 0 0 1px #d4d4d4, 0 1px 2px 0 rgba(0, 0, 0, 0.35); }
  .task-description, .task-description * {
    line-height: 30px; }
  .task-description p, .task-description h1, .task-description h2, .task-description h3, .task-description h4, .task-description h5 {
    margin: 0; }
  .task-description:focus, .task-description:active, .task-description.active {
    color: #627bcc;
    -webkit-box-shadow: 0 0 0 1px #b3b3b3, 0 1px 2px 1px rgba(98, 123, 204, 0.5);
    -moz-box-shadow: 0 0 0 1px #b3b3b3, 0 1px 2px 1px rgba(98, 123, 204, 0.5);
    box-shadow: 0 0 0 1px #b3b3b3, 0 1px 2px 1px rgba(98, 123, 204, 0.5);
    color: black;
    outline: 0 none; }

.task-description::-moz-placeholder {
  font-style: italic; }

.task-description:-ms-input-placeholder {
  font-style: italic; }

.task-description::-webkit-input-placeholder {
  font-style: italic; }

.task-description:focus::-moz-placeholder, .task-description.focus::-moz-placeholder, .task-description:active::-moz-placeholder, .task-description.active::-moz-placeholder {
  color: #627bcc; }

.task-description:focus::-ms-input-placeholder, .task-description.focus::-ms-input-placeholder, .task-description:active::-ms-input-placeholder, .task-description.active::-ms-input-placeholder {
  color: #627bcc; }

.task-description:focus::-webkit-input-placeholder, .task-description.focus::-webkit-input-placeholder, .task-description:active::-webkit-input-placeholder, .task-description.active::-webkit-input-placeholder {
  color: #627bcc; }

.compose .inactive {
  opacity: 0.35; }

.compose .active {
  opacity: 1; }

#dueby {
  position: relative; }
  #dueby .dueby-inner {
    position: absolute;
    top: auto;
    bottom: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 12px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
    #dueby .dueby-inner select {
      margin-top: 35px; }

.secret {
  display: block;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 0; }

.empty-roles .toggle-empty {
  cursor: pointer;
  color: #999999; }

.cc-select .role-icon {
  float: right;
  font-size: 24px; }

.cc-select .cc-row-select {
  padding: 8px;
  border: 1px solid #777777;
  border-top: none;
  margin: 0; }
  .cc-select .cc-row-select.last {
    border-radius: 0 0 4px 4px; }
  .cc-select .cc-row-select.first {
    border-top: 1px solid #777777;
    border-radius: 4px 4px 0 0; }
  .cc-select .cc-row-select.first.last {
    border-radius: 4px 4px 4px 4px; }
  .cc-select .cc-row-select.selected {
    color: #fff;
    background-color: #627bcc; }
  .cc-select .cc-row-select::hover {
    cursor: pointer; }

.bold {
  font-weight: bold; }

.debug p {
  margin-bottom: 2px; }

.debug input.active {
  border: 1px solid #000; }

.entity input.wide {
  width: 90%; }

.entity input.full-width {
  width: 100%; }

/*NAV BAR*/
/* mixins */
/* anims */
@-o-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-moz-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-webkit-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-o-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-moz-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-webkit-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

.forward-task .big-input-container {
  position: relative; }
  .forward-task .big-input-container .sit-right-label {
    right: 0; }

/*NAV BAR*/
/* mixins */
/* anims */
@-o-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-moz-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-webkit-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-o-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-moz-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-webkit-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

#task-wrapper {
  /* buttons */ }
  #task-wrapper .row-buttons [class*='col-']:nth-child(3), #task-wrapper .row-buttons [class*='col-']:nth-child(4) {
    padding-top: 10px; }
  @media all and (min-width: 768px) {
    #task-wrapper .row-buttons [class*='col-']:nth-child(3), #task-wrapper .row-buttons [class*='col-']:nth-child(4) {
      padding-top: 0; } }
  @media all and (min-width: 1200px) {
    #task-wrapper .row-buttons [class*='col-']:nth-child(3), #task-wrapper .row-buttons [class*='col-']:nth-child(4) {
      padding-top: 10px; } }

.task-container {
  padding: 10px 20px;
  background-color: white;
  border: 1px solid #eeeeee; }
  .task-container:hover, .task-container:active, .task-container.active, .task-container:focus {
    border: 1px solid rgba(98, 123, 204, 0.65);
    cursor: pointer; }
  .task-container.selected {
    border-color: rgba(98, 123, 204, 0.65); }

.form-replacer {
  font-size: 1.0666666666666em;
  padding: 0.25em 0 0 0;
  color: #000; }
  .form-replacer.big-form-replacer {
    font-size: 1.25em; }
  .form-replacer .pale {
    color: #8e8e93; }

.toggleCc {
  float: right; }

.cc-reply {
  float: right; }

.gray-task-row {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -10px;
  padding-bottom: 15px;
  padding-top: 15px;
  background-color: #f2f2f2; }
  @media screen and (min-width: 1200px) {
    .gray-task-row {
      margin-top: -10px;
      margin-left: -10px;
      margin-bottom: -10px;
      padding-top: 5px;
      min-height: 320px; } }

.form-control.big-input.no-underline {
  border-bottom-style: none; }

.scroller {
  overflow-y: hidden;
  position: relative; }
  .scroller div {
    white-space: normal; }

.black {
  color: #000; }

a.sort-dir, a.sort-btn {
  padding-left: 5px;
  padding-right: 1px;
  text-decoration: none;
  font-weight: bold;
  color: #999999; }

a.sort-dir {
  top: 10px;
  display: inline-block; }

a.sort-dir:hover {
  text-decoration: none;
  cursor: pointer; }

a.sort-dir:hover {
  color: #999999; }

.sort-dir.noshow {
  visibility: hidden; }

.radio.vertical {
  display: block;
  margin-bottom: 10px; }

/* Inbox list  - centers task description vertically */
.h2-container {
  position: relative;
  width: auto;
  height: 38px;
  margin-left: 40px; }
  .h2-container.no-left-margin {
    margin-left: 0; }
  .h2-container h2.h2-task {
    display: block;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin: 0;
    font-size: 18px;
    line-height: 1.25;
    color: #000; }

.hidden-all {
  display: none; }

.task-checkbox {
  cursor: pointer; }

label.task-checkbox.large {
  margin-top: 0; }

.task-checkbox {
  float: left;
  margin: 0; }

.task-checkbox .mt-checkbox, .checkbox-icon {
  position: relative;
  width: 28px;
  height: 28px;
  display: inline-block;
  border: 0;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none; }

.task.row .task-checkbox .mt-checkbox {
  top: 5px; }

.task-checkbox.large .mt-checkbox {
  width: 40px;
  height: 40px;
  margin-left: -5px;
  margin-top: 0;
  padding-top: 0; }

.task-checkbox .mt-checkbox:before, .checkbox-icon:before {
  display: table;
  width: 100%;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-radius: 28px;
  background: #fff;
  content: ' ';
  -webkit-transition: background-color 20ms ease-in-out;
  transition: background-color 20ms ease-in-out; }

.task-checkbox .mt-checkbox.checked:after {
  -webkit-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  top: 33%;
  left: 25%;
  display: table;
  width: 14px;
  height: 6px;
  border: 2px solid transparent;
  border-top: 0;
  border-right: 0;
  content: ' '; }

.task-checkbox .mt-checkbox.task-completed.checked:after {
  border-color: #fff; }

.task-checkbox .mt-checkbox.task-cancelled:after {
  content: '\F00D';
  font: normal normal normal 1.5em/1 FontAwesome;
  position: absolute;
  top: 3px;
  left: 5px;
  width: 1.5em;
  height: 1.5em;
  color: rgba(171, 171, 171, 0.4); }

.codeblue.task-checkbox .mt-checkbox.task-cancelled:after {
  color: rgba(0, 153, 204, 0.4); }

.met.task-checkbox .mt-checkbox.task-cancelled:after {
  color: rgba(205, 20, 25, 0.4); }

.premet.task-checkbox .mt-checkbox.task-cancelled:after {
  color: rgba(217, 102, 22, 0.4); }

.urgent.task-checkbox .mt-checkbox.task-cancelled:after {
  color: rgba(217, 152, 0, 0.4); }

.task-checkbox .mt-checkbox.task-new.checked:after {
  border: none; }

.task-checkbox.large .mt-checkbox:after {
  width: 20px;
  height: 8px;
  border-width: 2px; }

.task-checkbox .mt-checkbox:before, .checkbox .checkbox-icon:before {
  -o-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border-color: #ababab;
  border-width: 2px;
  border-style: solid; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.task-checkbox .mt-checkbox:before, .task-checkbox .checkbox-icon:before {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border-color: #ababab;
  border-width: 2px;
  border-style: solid; }

.task-checkbox .mt-checkbox.task-new:before {
  border-style: dashed; }

.task-checkbox {
  width: 42px; }

.label {
  font-family: 'Helvetica Neue', Helvetica, 'Segoe UI', Arial, sans-serif;
  display: inline-block;
  padding: 3px 5px;
  margin-right: 4px;
  margin-left: 4px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 13px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  .label.label-new {
    background-color: rgba(0, 0, 0, 0.5); }
  .label:last-child {
    margin-right: 0; }
  .label.outline {
    background-color: transparent;
    text-shadow: none;
    text-transform: uppercase;
    padding: 0 2px;
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.1); }

.label.premet, .label.met, .label.urgent, .label.codeblue {
  color: white; }

.label.codeblue {
  background-color: #0099cc; }

.label.met {
  background-color: #cd1419; }

.label.premet {
  background-color: #d96616; }

.label.urgent {
  background-color: #d99800; }

.white-container.input-search {
  padding-top: 10px; }

article.observations {
  margin-top: 22px;
  font-size: 15px;
  color: #8e8e93; }
  article.observations .responsive-inliner {
    display: inline; }
    @media screen and (min-width: 1200px) and (max-width: 1300px) {
      article.observations .responsive-inliner {
        display: block;
        margin-left: 27px; } }

article.custom {
  padding: 10px;
  color: #8e8e93;
  border: 1px solid #d4d4d4;
  background-color: #f2f2f2;
  margin-bottom: 8px; }
  article.custom .custom-label {
    font-size: 13px;
    font-weight: bold; }
  article.custom .custom-value, article.custom .custom-coding {
    font-size: 13px;
    color: #333; }
  article.custom .custom-multicoding {
    display: inline-block;
    background-color: #e0e0e0;
    color: #333;
    border-radius: 3px;
    margin-right: 5px;
    margin-bottom: 4px;
    position: relative;
    top: 1px;
    font-size: 12px;
    padding: 2px 6px 2px 6px; }

article.task-description {
  color: black;
  margin: 0 0 10px 0;
  width: auto; }
  article.task-description.urgent {
    background: repeating-linear-gradient(180deg, rgba(217, 152, 0, 0.05), rgba(217, 152, 0, 0.05) 29px, rgba(217, 152, 0, 0.2) 29px, rgba(217, 152, 0, 0.2) 30px);
    -webkit-box-shadow: 0 0 0 1px rgba(217, 152, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 0 0 1px rgba(217, 152, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 0 1px rgba(217, 152, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35); }
  article.task-description.premet {
    background: repeating-linear-gradient(180deg, rgba(217, 102, 22, 0.05), rgba(217, 102, 22, 0.05) 29px, rgba(217, 102, 22, 0.2) 29px, rgba(217, 102, 22, 0.2) 30px);
    -webkit-box-shadow: 0 0 0 1px rgba(217, 102, 22, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 0 0 1px rgba(217, 102, 22, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 0 1px rgba(217, 102, 22, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35); }
  article.task-description.met {
    background: repeating-linear-gradient(180deg, rgba(205, 20, 25, 0.05), rgba(205, 20, 25, 0.05) 29px, rgba(205, 20, 25, 0.2) 29px, rgba(205, 20, 25, 0.2) 30px);
    -webkit-box-shadow: 0 0 0 1px rgba(205, 20, 25, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 0 0 1px rgba(205, 20, 25, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 0 1px rgba(205, 20, 25, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35); }
  article.task-description.codeblue {
    background: repeating-linear-gradient(180deg, rgba(0, 153, 204, 0.05), rgba(0, 153, 204, 0.05) 29px, rgba(0, 153, 204, 0.2) 29px, rgba(0, 153, 204, 0.2) 30px);
    -webkit-box-shadow: 0 0 0 1px rgba(0, 153, 204, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 0 0 1px rgba(0, 153, 204, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 0 1px rgba(0, 153, 204, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.35); }
  article.task-description h3.description {
    line-height: 1;
    margin-bottom: 4px; }
  article.task-description .due-timeframe {
    font-size: 0.8em;
    color: #8e8e93; }

.item-checkbox.checkbox-bg {
  background-color: #f7f7f7; }

.unspecified-patient {
  margin-top: 6px;
  color: #8e8e93; }

.strike {
  text-decoration: line-through; }

.new-task {
  font-weight: 700; }

.codeblue.task-checkbox .mt-checkbox:before, .codeblue.task-checkbox .checkbox-icon:before {
  border-color: #0099cc;
  background-color: rgba(0, 153, 204, 0.4); }

.met.task-checkbox .mt-checkbox:before, .met.task-checkbox .checkbox-icon:before {
  border-color: #cd1419;
  background-color: rgba(205, 20, 25, 0.4); }

.premet.task-checkbox .mt-checkbox:before, .premet.task-checkbox .checkbox-icon:before {
  border-color: #d96616;
  background-color: rgba(217, 102, 22, 0.4); }

.urgent.task-checkbox .mt-checkbox:before, .urgent.task-checkbox .checkbox-icon:before {
  border-color: #d99800;
  background-color: rgba(217, 152, 0, 0.4); }

.routine.task-checkbox .mt-checkbox.disabled:before, .routine.task-checkbox .checkbox-icon.disabled:before {
  opacity: 0.5;
  background-color: transparent; }

.codeblue.task-checkbox .mt-checkbox.disabled:before, .codeblue.task-checkbox .checkbox-icon.disabled:before, .met.task-checkbox .mt-checkbox.disabled:before, .met.task-checkbox .checkbox-icon.disabled:before, .premet.task-checkbox .mt-checkbox.disabled:before, .premet.task-checkbox .checkbox-icon.disabled:before, .urgent.task-checkbox .mt-checkbox.disabled:before, .urgent.task-checkbox .checkbox-icon.disabled:before {
  opacity: 0.5; }

.codeblue.task-checkbox .mt-checkbox.task-new.checked:before, .codeblue.task-checkbox .checkbox-icon.task-new.checked:before {
  background-color: rgba(0, 153, 204, 0.4); }

.codeblue.task-checkbox .mt-checkbox.checked:before, .codeblue.task-checkbox .checkbox-icon.checked:before {
  background-color: #0099cc; }

.met.task-checkbox .mt-checkbox.task-new.checked:before, .met.task-checkbox .checkbox-icon.task-new.checked:before {
  background-color: rgba(205, 20, 25, 0.4); }

.met.task-checkbox .mt-checkbox.checked:before, .met.task-checkbox .checkbox-icon.checked:before {
  background-color: #cd1419; }

.premet.task-checkbox .mt-checkbox.task-new.checked:before, .premet.task-checkbox .checkbox-icon.task-new.checked:before {
  background-color: rgba(217, 102, 22, 0.4); }

.premet.task-checkbox .mt-checkbox.checked:before, .premet.task-checkbox .checkbox-icon.checked:before {
  background-color: #d96616; }

.urgent.task-checkbox .mt-checkbox.task-new.checked:before, .urgent.task-checkbox .checkbox-icon.task-new.checked:before {
  background-color: rgba(217, 152, 0, 0.4); }

.urgent.task-checkbox .mt-checkbox.checked:before, .urgent.task-checkbox .checkbox-icon.checked:before {
  background-color: #d99800; }

.routine.task-checkbox .mt-checkbox.task-new.checked:before, .routine.task-checkbox .checkbox-icon.task-new.checked:before {
  background-color: transparent; }

.routine.task-checkbox .mt-checkbox.checked:before, .routine.task-checkbox .checkbox-icon.checked:before {
  border-color: #ababab;
  background-color: #ababab; }

.routine .task-checkbox .mt-checkbox:before, .routine .task-checkbox .checkbox-icon:before {
  border-color: #ababab; }

.no-top-margin {
  margin-top: 0; }

ul.no-style li {
  list-style: none; }

.paging {
  margin-left: -20px; }
  .paging a {
    color: #888; }
  .paging a:hover {
    text-decoration: none; }

.total {
  width: 300px;
  float: left; }

.search-sort-selector button.btn {
  border-color: #999;
  color: #777777; }

.search-sort-selector {
  margin-right: auto;
  margin-left: auto;
  width: 150px; }

ul.dropdown-menu.sort-select {
  min-width: 0; }

.select-pill {
  display: inline-block;
  background-color: #777777;
  color: #fff;
  border-radius: 3px;
  padding: 4px 10px;
  margin-right: 5px;
  margin-bottom: 2px;
  cursor: pointer;
  position: relative;
  top: 1px; }
  .select-pill:hover {
    background-color: #444444 !important; }
  .select-pill.small {
    font-size: small;
    padding: 4px 8px 4px 8px; }

.white-container.showing {
  padding: 10px;
  padding-left: 20px;
  font-weight: bold; }

.desc-annot {
  font-size: 0.9em;
  display: block;
  color: #999999; }

.task-reply {
  border: 1px solid #c7cad4;
  margin-bottom: 5px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
  padding: 5px 8px;
  background: repeating-linear-gradient(180deg, #edecf8, #edecf8 23px, #d8dbe6 23px, #d8dbe6 24px);
  background-color: #edecf8;
  color: #000;
  width: 100%; }

.task-replies {
  margin-bottom: 10px; }
  .task-replies .sent {
    float: right; }
  .task-replies .sent, .task-replies .sender {
    color: #82858c; }
  .task-replies .oblique {
    font-style: italic; }

.actions {
  font-size: 0.8em;
  position: absolute;
  float: left;
  right: 0;
  top: 21px;
  left: auto;
  display: block;
  z-index: 1000;
  width: auto;
  background-clip: padding-box;
  text-align: left;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  background-color: #fff;
  margin: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
  .actions .actions-pointer {
    position: absolute;
    display: block;
    z-index: 2000;
    right: 13px;
    top: -6px;
    width: 11px;
    height: 6px;
    background: url("../assets/images/pointer.png");
    background-repeat: no-repeat;
    background-size: 11px 6px;
    background-position: 0 0; }
  .actions ul {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-y: auto;
    max-height: 45vh; }
    .actions ul li {
      padding: 10px;
      margin: 0;
      border-bottom: 1px solid #eee;
      display: flex;
      flex-flow: row nowrap; }
      .actions ul li .action-col {
        margin-right: 10px; }
        .actions ul li .action-col:nth-child(2) {
          flex-grow: 1;
          margin-right: 20px; }
        .actions ul li .action-col:last-child {
          flex-grow: 1;
          margin-right: 0; }
      .actions ul li .icon {
        font-size: 1.5em;
        vertical-align: top; }
        .actions ul li .icon i.small {
          font-size: 80%; }

.reply-form {
  margin-top: 10px; }

.appalert {
  display: inline-block;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  padding: 3px;
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;
  top: -1px;
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-right: 3px; }
  .appalert.unread {
    background-color: #cd1419; }

.photos {
  padding: .2vw;
  font-size: 0;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  display: flex; }
  .photos .no-margin {
    margin-left: 0; }
  .photos .thumb {
    position: relative;
    width: 100px; }
    .photos .thumb img {
      width: 94px;
      border-radius: 4px;
      border: 1px solid #ccc; }

.modal-photo .modal-body img {
  margin-left: auto;
  margin-right: auto;
  display: block; }

.row-buttons {
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap; }
  .row-buttons .task-button {
    width: 50%;
    margin-bottom: 10px; }
    .row-buttons .task-button:nth-child(even) {
      padding-left: 15px; }

.forward {
  color: rgba(0, 0, 0, 0.5);
  font-style: italic; }
  .forward .prac {
    color: #000; }

.sort-select li {
  padding: 5px;
  border-bottom: 1px solid #cccccc; }

.sort-select :first-child {
  padding-top: 2px; }

.sort-select :last-child {
  padding-bottom: 2px;
  border-bottom: none; }

.escalation-icon {
  display: inline-block;
  padding-right: 3px;
  position: relative;
  top: 3px; }
  .escalation-icon i {
    color: #ec2265;
    font-size: 20px;
    animation: escalation-pulse 2s infinite; }

.white-container.pager {
  padding-top: 10px;
  padding-bottom: 10px; }
  .white-container.pager .pager-container {
    display: block;
    width: 220px;
    margin-left: auto;
    margin-right: auto; }
    .white-container.pager .pager-container a.pager-ctrl {
      width: 25px;
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
      color: #8e8e93;
      visibility: hidden;
      text-align: center; }
      .white-container.pager .pager-container a.pager-ctrl.pager-show {
        visibility: visible; }
      .white-container.pager .pager-container a.pager-ctrl:hover {
        text-decoration: none; }
      .white-container.pager .pager-container a.pager-ctrl i {
        font-size: 20px;
        position: relative;
        top: 2px; }

@keyframes escalation-pulse {
  0% {
    color: #ec2265; }
  50% {
    color: #f26493; }
  100% {
    color: #ec2265; } }

/*NAV BAR*/
.insight-tasks, .insights-graph {
  padding-top: 20px; }

.insights-header {
  margin-bottom: 20px;
  padding-bottom: 20px; }

ul.flex-list {
  padding: 10px;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  display: flex;
  list-style: none;
  margin-bottom: 0; }
  ul.flex-list li.option {
    width: 160px;
    margin: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 3px;
    position: relative;
    cursor: pointer; }
    ul.flex-list li.option:hover {
      background-color: #222; }
      ul.flex-list li.option:hover a {
        color: #fff;
        text-decoration: none; }
    ul.flex-list li.option.active {
      background-color: #e6e6e6; }
      ul.flex-list li.option.active:hover a {
        color: #222; }

.row.pad-left {
  padding-left: 20px; }

.no-insights-tasks {
  width: 100%;
  padding: 8px;
  padding-top: 0; }
  .no-insights-tasks h2 {
    font-style: italic;
    text-align: center;
    color: #333;
    font-size: 1.2em; }

.monitor .white-container {
  margin-bottom: 10px; }

.monitor .flex-row {
  padding: 0; }
  .monitor .flex-row.right-align {
    -webkit-justify-content: flex-end;
    justify-content: flex-end; }
  .monitor .flex-row .flex-item-narrow {
    width: 90px;
    margin-right: 10px; }
    .monitor .flex-row .flex-item-narrow .sent, .monitor .flex-row .flex-item-narrow .urgency {
      font-size: small; }
    .monitor .flex-row .flex-item-narrow .urgency {
      color: #fff;
      padding: 5px;
      border-radius: 4px;
      width: 80px;
      text-align: center; }
      .monitor .flex-row .flex-item-narrow .urgency.premet {
        background-color: #d96616; }
      .monitor .flex-row .flex-item-narrow .urgency.met {
        background-color: #cd1419; }
      .monitor .flex-row .flex-item-narrow .urgency.codeblue {
        background-color: #0099cc; }
  .monitor .flex-row.patient {
    padding-left: 0;
    padding-top: 5px; }
    .monitor .flex-row.patient .flex-item-third {
      width: 33%;
      min-width: 120px;
      padding-left: 0; }
      .monitor .flex-row.patient .flex-item-third.icon {
        position: relative;
        padding-left: 12px; }
        .monitor .flex-row.patient .flex-item-third.icon i {
          position: absolute;
          left: -5px;
          top: 3px; }

.monitor .patient-container {
  cursor: pointer; }
  .monitor .patient-container.active .white-container {
    border: 1px #627bcc solid; }
  .monitor .patient-container.active h4 {
    font-weight: bold; }
  .monitor .patient-container .white-container {
    padding: 10px 20px 0 20px;
    margin-bottom: 10px; }
  .monitor .patient-container .task-list {
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 20px; }

.monitor ul.flex-list.options {
  padding-bottom: 0; }
  .monitor ul.flex-list.options li:first-child {
    border: none;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
    width: 100px !important;
    color: #333;
    padding-top: 20px; }
  .monitor ul.flex-list.options li {
    text-align: center;
    font-size: 1em;
    width: 70px; }
  .monitor ul.flex-list.options.urgencies li {
    width: 125px; }

.monitor .no-tasks {
  margin-top: 10px;
  margin-bottom: 0; }
  .monitor .no-tasks h2 {
    margin-bottom: 0;
    padding-bottom: 0; }

.pharmacyreport .row {
  margin: 0; }
  .pharmacyreport .row.encounter {
    border-bottom: solid 1px #cccccc;
    padding-bottom: 10px; }
    .pharmacyreport .row.encounter .patient {
      font-weight: bold;
      padding-bottom: 8px;
      margin: 0;
      background-color: #777777;
      padding-top: 6px;
      color: #fff; }
      .pharmacyreport .row.encounter .patient.row2 {
        padding-top: 0px; }
    .pharmacyreport .row.encounter .encounter-note textarea, .pharmacyreport .row.encounter .encounter-note .note {
      border: 1px solid #cccccc; }
    .pharmacyreport .row.encounter .encounter-note .edit-icon {
      display: inline-block;
      margin-right: 6px;
      width: 30px; }
  .pharmacyreport .row.header {
    font-weight: bold; }
  .pharmacyreport .row.pharmacy-task {
    margin-bottom: 8px;
    padding-top: 8px;
    font-weight: normal; }
  .pharmacyreport .row.encounter-note {
    margin-top: 12px; }

.pharmacyreport .print-buttons, .pharmacyreport .report-date {
  margin-top: 20px; }

@media print {
  #pharmacyreport .print-logo {
    width: 20%;
    height: 20%; }
  #pharmacyreport h3 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 0; }
  #pharmacyreport .print-header h1 {
    font-size: 22px;
    margin-bottom: 0; }
  #pharmacyreport .row.patient, #pharmacyreport .row.encounter-note, #pharmacyreport .row.pharmacy-task, #pharmacyreport .note {
    font-size: 10px;
    margin-bottom: 2px;
    padding: 0; }
  #pharmacyreport .patient-header {
    padding: 5px;
    background-color: #000 !important;
    -webkit-print-color-adjust: exact; }
    #pharmacyreport .patient-header .row.patient *, #pharmacyreport .patient-header .row.patient *, #pharmacyreport .patient-header .row.patient *:before, #pharmacyreport .patient-header .row.patient *:after, #pharmacyreport .patient-header .row.patient.row2 *, #pharmacyreport .patient-header .row.patient.row2 *, #pharmacyreport .patient-header .row.patient.row2 *:before, #pharmacyreport .patient-header .row.patient.row2 *:after {
      color: #fff !important; }
  #pharmacyreport .pharmacy-task.first {
    margin-top: 8px; }
  #pharmacyreport .row.encounter.header {
    padding-top: 10px;
    page-break-inside: avoid;
    margin-left: -15px;
    margin-right: -15px; }
  #pharmacyreport .encounter-note .note {
    padding: 5px; } }

input.report-date {
  font-size: smaller;
  width: 150px;
  padding: 4px;
  margin-right: 5px; }

.flex-row {
  padding: 10px;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  display: flex;
  margin-bottom: 0; }
  .flex-row .flex-item-wide {
    width: 460px; }
  .flex-row .date-select {
    width: 230px;
    float: left; }
  .flex-row .flex-item {
    min-width: 250px;
    padding-right: 10px; }
  .flex-row .button {
    width: 70px; }
  .flex-row * {
    margin-bottom: 8px; }

a.chart-csv {
  text-align: center;
  color: #627bcc;
  font-size: small;
  margin-left: 25px; }

.blue {
  color: #627bcc; }

.reports-header, .reports-summary {
  padding-bottom: 0;
  margin-bottom: 10px; }

.reports-summary {
  padding-top: 20px; }

.reports-graphs {
  padding-top: 10px; }

.no-tasks {
  width: 100%;
  padding: 8px;
  padding-top: 0;
  margin-top: -30px; }
  .no-tasks h2 {
    font-style: italic;
    text-align: center;
    color: #333;
    font-size: 1.2em; }

.summary .main {
  margin-bottom: 12px; }

.summary .task-total {
  display: inline-block;
  width: 250px;
  font-size: 1.3em;
  color: #333333;
  font-weight: bold;
  margin-left: 25px;
  margin-top: 0; }
  .summary .task-total.notasks {
    font-style: italic;
    text-align: center; }

.summary .task-time {
  display: inline-block;
  width: 250px;
  margin-bottom: 10px;
  font-size: 1em;
  color: #333333;
  margin: 0 0 5px 25px; }

.summary .report-total {
  font-size: 1em; }

.graph-container {
  padding: 20px;
  margin: 10px;
  margin-bottom: 20px;
  border: 1px solid #cccccc; }

.header-row {
  font-weight: bold;
  color: #000; }

.alt-row {
  background-color: #f2f2f2; }

.row-roles .row {
  padding-top: 0.5em;
  padding-bottom: 0.5em; }
  .row-roles .row:last-child {
    border-bottom: 2px solid #f2f2f2;
    margin-bottom: 10px; }

.timepicker-row {
  background-color: #f2f2f2;
  margin-top: 10px; }
  .timepicker-row label.big-input-label {
    margin-top: 40px; }

a.kinda-disabled, a.kinda-disabled:hover, a.kinda-disabled:active {
  color: #999999;
  cursor: default;
  text-decoration: none; }

.role-form input.responsibility {
  border-radius: 3px;
  border: 1px solid #000; }
  .role-form input.responsibility.code {
    display: inline-block;
    width: 30%; }
  .role-form input.responsibility.display {
    display: inline-block;
    width: 68%; }

.role-form textarea {
  margin-top: 5px; }

.role-form .ui-select-match.ui-select-placeholder {
  padding-right: 10px; }

.role-form .ui-select-toggle.btn {
  width: 200px; }

.role-form .ward-pill {
  display: inline-block;
  background-color: #777777;
  color: #fff;
  border-radius: 3px;
  padding: 5px;
  margin-right: 5px;
  margin-top: 5px; }

.role-form:not(.ng-pristine) input.responsibility.ng-invalid-required {
  border-color: #cd1419; }

/*NAV BAR*/
/* mixins */
/* anims */
@-o-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-moz-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-webkit-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-o-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-moz-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-webkit-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

.sliderBox {
  padding: 10px;
  min-height: 60px;
  margin-top: 30px; }
  .sliderBox.no-margin {
    margin: 0; }
  .sliderBox.no-padding {
    padding: 0; }

.jslider div.jslider-pointer {
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: -12px;
  margin-left: -15px; }

.jslider div.jslider-pointer {
  background: url(../assets/images/jslider-pointers.png) center top;
  background-size: 32px 64px;
  background-position: 0 0; }
  .jslider div.jslider-pointer.jslider-pointer-hover {
    background-position: 0 32px; }

.jslider div.jslider-bg {
  top: -7px; }
  .jslider div.jslider-bg i {
    height: 14px;
    background-color: #ddd;
    background-image: none;
    -webkit-box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.5); }
    .jslider div.jslider-bg i.range {
      background-color: #627bcc;
      height: 14px; }

.jslider div.jslider-scale span {
  border-color: #ccc;
  height: 15px; }

.jslider div.jslider-scale ins {
  top: 10px;
  background-color: white; }

#override .btn-default:active, #override .btn-default.active {
  color: white;
  background-color: #627bcc;
  border-color: #627bcc; }

/* Used in multiple templates now: */
.dateblockright {
  float: right;
  margin-top: 21px; }
  .dateblockright .dateblock {
    text-align: right; }

.dateblock {
  float: left;
  display: block; }
  .dateblock:last-child {
    margin-left: 5px; }
  .dateblock h3 {
    margin: 0;
    font-size: 1.3333333333333em; }

#roster-print-form .print-buttons {
  margin-top: 10px; }
  @media print {
    #roster-print-form .print-buttons {
      display: none; } }

#roster-print-form .print-header h1 {
  margin-top: 5px;
  margin-bottom: 2px; }

#roster-print-form .key {
  border: 1px solid #b3b3b3;
  padding: 0 10px 8px 10px;
  border-radius: 5px;
  width: 160px;
  float: right;
  margin-top: 30px; }
  @media only screen and (max-width: 767px) {
    #roster-print-form .key {
      margin-top: 10px;
      float: none;
      max-width: inherit; } }
  #roster-print-form .key h4 {
    text-transform: uppercase; }
  #roster-print-form .key .icon {
    display: inline-block;
    min-width: 26px; }

@media print {
  #roster-print-form .team {
    page-break-inside: avoid; } }

#roster-print-form .team h3 {
  padding-bottom: 6px;
  margin-top: 40px; }
  @media print {
    #roster-print-form .team h3 {
      font-size: 15px; } }

#roster-print-form .team .row.header {
  font-weight: bold; }
  @media print {
    #roster-print-form .team .row.header, #roster-print-form .team .row.header * {
      font-size: 12px; } }

#roster-print-form .team .row.whole-shift {
  margin-top: 4px;
  margin-bottom: 4px;
  border-top: 1px solid #b3b3b3; }
  @media print {
    #roster-print-form .team .row.whole-shift, #roster-print-form .team .row.whole-shift * {
      font-size: 12px; } }
  #roster-print-form .team .row.whole-shift:last-child {
    border-bottom: 1px solid #b3b3b3; }
  #roster-print-form .team .row.whole-shift [class*="col-"] {
    padding-top: 2px;
    padding-bottom: 3px; }
  #roster-print-form .team .row.whole-shift .row.print-shift {
    border-bottom: 1px dotted #cccccc; }
    #roster-print-form .team .row.whole-shift .row.print-shift:last-child {
      border-bottom: none; }

#roster-print-form .team .aggregate svg path {
  fill: currentColor; }

@media print {
  #roster-print-form {
    padding: 0 0 20px 0;
    margin: 0; }
    #roster-print-form, #roster-print-form * {
      color: black !important; }
    #roster-print-form .print-logo {
      margin-bottom: 10px;
      width: 140px; }
    #roster-print-form footer.footer {
      position: fixed;
      bottom: 0;
      left: 30px;
      padding-top: 5px;
      border-top: 1px solid #b3b3b3; }
      #roster-print-form footer.footer, #roster-print-form footer.footer * {
        font-size: 9px; } }

/*NAV BAR*/
/* mixins */
/* anims */
@-o-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-moz-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-webkit-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-o-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-moz-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-webkit-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

.modal-body {
  z-index: 200; }

.cal-container {
  border-left: 1px solid #eeeeee;
  box-shadow: inset -1px 1px 0 0 #eeeeee;
  display: inline-block;
  float: left; }

.form-control.big-input.calendar-input {
  padding: 20px;
  font-size: 1em; }

.list-group-item {
  color: #333; }

.push30 {
  margin-top: 28px; }

.team-input .form-control {
  color: #333333;
  font-size: 1.2em;
  height: 40px; }

.row.no-shifts {
  padding-top: 10px; }

.clone, .today {
  float: right;
  color: #8e8e93;
  padding: 0; }
  .clone:disabled, .today:disabled {
    color: #ddd;
    cursor: not-allowed; }

.ta-select {
  padding-top: 10px; }

.container-table {
  margin-right: -30px;
  margin-left: -30px; }
  .container-table .label-table {
    width: 100%;
    height: 66px;
    text-align: right;
    line-height: 1;
    vertical-align: middle; }
    .container-table .label-table span {
      -o-transition: all 150ms ease-in-out;
      -moz-transition: all 150ms ease-in-out;
      -webkit-transition: all 150ms ease-in-out;
      transition: all 150ms ease-in-out;
      color: #000; }
    .container-table .label-table:hover span, .container-table .label-table:focus span, .container-table .label-table:active span {
      color: #627bcc; }
    .container-table .label-table td {
      padding-right: 10px; }
      .container-table .label-table td span {
        padding: 1px 3px;
        line-height: 1.35; }
      .container-table .label-table td.selected span {
        color: white;
        background-color: #627bcc; }
      .container-table .label-table td.selected:hover span, .container-table .label-table td.selected:focus span, .container-table .label-table td.selected:active span {
        color: white; }

.roster-date {
  border: none;
  background: transparent;
  box-shadow: none;
  outline: none; }

/* Maths for roster grid */
div.roster-content {
  background-image: url("../assets/images/grey-divider.png"), url("../assets/images/grey-divider.png"), url("../assets/images/grey-divider.png"), url("../assets/images/grey-divider.png"), url("../assets/images/grey-divider.png"), url("../assets/images/grey-divider.png"), url("../assets/images/grey-divider.png"), url("../assets/images/grey-divider.png"), url("../assets/images/grey-divider.png"), url("../assets/images/grey-divider.png"), url("../assets/images/grey-divider.png"), url("../assets/images/grey-divider.png"), url("../assets/images/grey-divider.png");
  background-position: 0 0, 8.33333% 0, 16.66667% 0, 25.0% 0, 33.33333% 0, 41.66666% 0, 50.0% 0, 58.33333% 0, 66.66666% 0, 75.0% 0, 83.33333% 0, 91.66666% 0, 100.0% 0;
  background-repeat: repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y, repeat-y; }

.selected .shift-row .shift-cell, .selected .shift-row .shift-cell.empty-shift {
  box-shadow: inset -1px -1px 0 0px #627bcc, -1px -1px 0 0px #627bcc; }

.shift-row {
  position: relative;
  height: 66px;
  margin-bottom: 5px; }
  .shift-row .no-height {
    height: 0; }
  .shift-row .role-name {
    max-height: 66px;
    overflow: hidden; }
  .shift-row.shift-row-stripes {
    background-image: url("../assets/images/grey-tile.png"); }
  .shift-row.shift-row.time {
    height: 16px; }
  .shift-row .shift-axis {
    position: relative; }
    .shift-row .shift-axis div {
      position: absolute;
      display: block;
      height: 16px;
      padding: 0;
      margin-left: -20px; }
  .shift-row .role-row {
    height: 66px; }
  .shift-row .shifts .shift-cell {
    background-color: #fff; }
    .shift-row .shifts .shift-cell.selected-aggregate {
      background-color: #889bd9; }
    .shift-row .shifts .shift-cell.selected {
      background-color: #627bcc; }
  .shift-row .shift-cell {
    -o-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    height: 66px;
    position: absolute;
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: #eee;
    color: #6f6f74;
    box-shadow: inset -1px -1px 0 0px #878787, -1px -1px 0 0px #b3b3b3; }
    .shift-row .shift-cell, .shift-row .shift-cell * {
      font-size: 14px; }
    .shift-row .shift-cell:hover {
      box-shadow: inset -1px -1px 0 0px #777777, -1px -1px 0 0px #999999, 0 1px 2px 1px rgba(0, 0, 0, 0.35);
      z-index: 2; }
      .shift-row .shift-cell:hover.overridden {
        z-index: 1; }
      .shift-row .shift-cell:hover .overlap-marker {
        display: none; }
    .shift-row .shift-cell.multi-selected {
      background-color: #889bd9 !important;
      color: white;
      box-shadow: inset -1px -1px 0 0px #7188d1, -1px -1px 0 0px #98a8de; }
      .shift-row .shift-cell.multi-selected:hover {
        background-color: #8195d6; }
      .shift-row .shift-cell.multi-selected .aggregate svg path {
        fill: currentColor; }
    .shift-row .shift-cell.selected, .shift-row .shift-cell.selected.multi-selected {
      background-color: #627bcc !important;
      color: white;
      z-index: 2;
      box-shadow: inset -1px -1px 0 0px #536ec7, -1px -1px 0 0px #8195d6; }
      .shift-row .shift-cell.selected:hover, .shift-row .shift-cell.selected.multi-selected:hover {
        background-color: #5a75c9; }
      .shift-row .shift-cell.selected.overridden, .shift-row .shift-cell.selected.multi-selected.overridden {
        z-index: 1; }
      .shift-row .shift-cell.selected .overlap-marker, .shift-row .shift-cell.selected.multi-selected .overlap-marker {
        display: none; }
    .shift-row .shift-cell.empty-shift {
      background-color: rgba(255, 255, 255, 0.35); }
      .shift-row .shift-cell.empty-shift.selected {
        background-color: rgba(156, 172, 223, 0.75); }
    .shift-row .shift-cell .override-marker {
      position: absolute;
      right: 0px;
      height: 32px;
      border-right: 1px dashed #ccc;
      color: #eee;
      display: none;
      top: 32px;
      width: 10px; }
    .shift-row .shift-cell .overlap-marker {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      border-right: 1px dashed rgba(200, 200, 200, 0.7);
      width: 1px;
      z-index: 2; }
    .shift-row .shift-cell.overridden.assigned {
      background-color: #eee !important; }
    .shift-row .shift-cell.overridden .practitioner {
      padding-top: 6px;
      background-color: white;
      width: 100%;
      padding-left: 8px;
      margin-left: -8px;
      margin-right: -6px;
      height: 25px;
      box-shadow: inset -1px -1px 0 0px #536ec7, -1px -1px 0 0px #8195d6;
      position: absolute;
      top: 33px;
      height: 32px; }
    .shift-row .shift-cell.overridden.aggregate .practitioner {
      top: 40px;
      height: 25px;
      padding-top: 3px; }
    .shift-row .shift-cell.overridden.multi-selected .practitioner {
      background-color: #889bd9;
      color: white; }
    .shift-row .shift-cell.overridden.selected .practitioner {
      background-color: #627bcc !important; }
    .shift-row .shift-cell.assigned {
      background-color: white; }
    .shift-row .shift-cell.override {
      height: 33px;
      z-index: 1; }
      .shift-row .shift-cell.override.aggregate {
        height: 25px;
        top: 15px; }
      .shift-row .shift-cell.override:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: -5px;
        left: 30%;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #3e5cbd;
        font-size: 0;
        line-height: 0; }
      .shift-row .shift-cell.override.multi-selected {
        background-color: #889bd9;
        color: white; }
        .shift-row .shift-cell.override.multi-selected:hover {
          background-color: #8195d6; }
      .shift-row .shift-cell.override.selected, .shift-row .shift-cell.override.selected.multi-selected {
        background-color: #627bcc; }
        .shift-row .shift-cell.override.selected:hover, .shift-row .shift-cell.override.selected.multi-selected:hover {
          background-color: #5a75c9; }
      .shift-row .shift-cell.override .override-marker {
        display: block; }
      .shift-row .shift-cell.override.aggregate .override-marker {
        height: 25px;
        top: 0; }
      .shift-row .shift-cell.override .mismatch:before {
        content: '\f234';
        display: inline-block;
        float: left;
        padding-right: 3px;
        text-rendering: auto;
        font-family: FontAwesome;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate(0, 0); }
    .shift-row .shift-cell input {
      background-color: transparent;
      color: white; }
    .shift-row .shift-cell .aggregate {
      background-color: #dde;
      padding: 0;
      color: #fff;
      margin: -4px;
      margin-left: -8px;
      margin-right: -7px;
      margin-bottom: 0; }
      .shift-row .shift-cell .aggregate, .shift-row .shift-cell .aggregate * {
        line-height: 1; }
      .shift-row .shift-cell .aggregate .link-icon {
        width: 12px;
        margin-left: auto;
        margin-right: auto; }
      .shift-row .shift-cell .aggregate svg {
        width: 12px;
        height: 12px; }
        .shift-row .shift-cell .aggregate svg path {
          fill: currentColor; }
    .shift-row .shift-cell .edit {
      -o-transition: all 150ms ease-in-out;
      -moz-transition: all 150ms ease-in-out;
      -webkit-transition: all 150ms ease-in-out;
      transition: all 150ms ease-in-out;
      float: right;
      text-align: right;
      clear: right;
      margin-top: -8px; }
      .shift-row .shift-cell .edit a span {
        margin-right: 2px; }
    .shift-row .shift-cell .practitioner {
      height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
    .shift-row .shift-cell .min-display, .shift-row .shift-cell .narrow-display, .shift-row .shift-cell .wide-display {
      display: none; }
    .shift-row .shift-cell .narrow-width .practitioner *, .shift-row .shift-cell .below-min-width .practitioner *, .shift-row .shift-cell .wide-width .practitioner * {
      display: none; }
    .shift-row .shift-cell .below-min-width .practitioner .min-display {
      display: block;
      font-size: 0.9em; }
    .shift-row .shift-cell .narrow-width .practitioner .narrow-display {
      display: block;
      font-size: 0.9em; }
    .shift-row .shift-cell .wide-width .practitioner .wide-display {
      display: block; }
    .shift-row .shift-cell.selected-aggregate .aggregate svg path {
      fill: #3752aa; }
    .shift-row .shift-cell.selected.selected-aggregate .aggregate svg path {
      fill: currentColor; }
    .shift-row .shift-cell.selected.aggr-selected, .shift-row .shift-cell:active, .shift-row .shift-cell:focus {
      z-index: 1; }
      .shift-row .shift-cell.selected.aggr-selected .edit, .shift-row .shift-cell:active .edit, .shift-row .shift-cell:focus .edit {
        opacity: 1; }
      .shift-row .shift-cell.selected.aggr-selected .aggregate svg path, .shift-row .shift-cell:active .aggregate svg path, .shift-row .shift-cell:focus .aggregate svg path {
        fill: currentColor; }
    .shift-row .shift-cell .shift-content {
      padding: 2px 4px; }
    .shift-row .shift-cell .overlap {
      text-indent: -9999px;
      background: repeating-linear-gradient(-45deg, #eeeeee, #eeeeee 6px, transparent 6px, transparent 12px);
      z-index: 3;
      cursor: auto;
      border: 0 none;
      height: 62px;
      width: 20px;
      margin-right: 10px;
      float: left;
      display: block; }
    .shift-row .shift-cell .ui-select-toggle, .shift-row .shift-cell .ui-select-search {
      font-size: 12px;
      font-style: normal;
      border: none;
      background: none;
      color: black;
      padding-top: 7px;
      padding-left: 18px; }
      .shift-row .shift-cell .ui-select-toggle.text, .shift-row .shift-cell .ui-select-search.text {
        color: black; }
    .shift-row .shift-cell .ui-select-choices a:hover, .shift-row .shift-cell .ui-select-choices a:focus, .shift-row .shift-cell .ui-select-choices a:active, .shift-row .shift-cell .ui-select-choices a *:hover, .shift-row .shift-cell .ui-select-choices a *:focus, .shift-row .shift-cell .ui-select-choices a *:active {
      color: white; }
    .shift-row .shift-cell .ui-select-choices-row.active a, .shift-row .shift-cell .ui-select-choices-row.active a * {
      color: white; }
      .shift-row .shift-cell .ui-select-choices-row.active a:hover, .shift-row .shift-cell .ui-select-choices-row.active a:focus, .shift-row .shift-cell .ui-select-choices-row.active a:active, .shift-row .shift-cell .ui-select-choices-row.active a *:hover, .shift-row .shift-cell .ui-select-choices-row.active a *:focus, .shift-row .shift-cell .ui-select-choices-row.active a *:active {
        color: white; }
    .shift-row .shift-cell .ui-select-choices-row a {
      color: black; }

.role-container .role-node {
  margin-top: 8px; }
  .role-container .role-node:first-child, .role-container .role-node:only-child {
    margin-top: 0; }
  .role-container .role-node .role {
    margin-top: 2px;
    margin-bottom: 2px; }
    .role-container .role-node .role span {
      background-color: #627bcc;
      color: white;
      padding: 1px 3px; }

.hol {
  color: #000;
  font-style: italic; }

.shift-details {
  background-color: rgba(237, 237, 237, 0.75);
  border-top: 2px solid #627bcc;
  border-bottom: 1px solid #627bcc;
  z-index: 2;
  margin-top: 15px; }
  .shift-details, .shift-details h1, .shift-details h2, .shift-details h3, .shift-details h4, .shift-details h5, .shift-details h6, .shift-details p, .shift-details span {
    color: #000; }
  .shift-details hr {
    border-color: #a2bed9; }
  .shift-details label {
    font-weight: normal;
    font-size: 0.9em; }
  .shift-details .invalid {
    color: #af0b17;
    font-size: 0.8em;
    display: block; }
  .shift-details .contact-pref {
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid #b4cbe0;
    padding: 0.5em 0.65em;
    margin-top: 5px; }
    .shift-details .contact-pref section {
      margin-top: 0px;
      margin-bottom: 5px; }
      .shift-details .contact-pref section, .shift-details .contact-pref section * {
        font-size: 13px; }
    .shift-details .contact-pref #pager, .shift-details .contact-pref #phone {
      border: 1px solid rgba(98, 123, 204, 0.5);
      background-color: rgba(255, 255, 255, 0.5);
      width: 50px;
      margin-right: 4px; }
      .shift-details .contact-pref #pager.disabled, .shift-details .contact-pref #phone.disabled {
        background-color: transparent;
        border: 1px solid rgba(98, 123, 204, 0.2); }
    .shift-details .contact-pref #phone {
      width: 100px; }
  .shift-details a, .shift-details a * {
    color: #3e5cbd; }
    .shift-details a.red, .shift-details a.red *, .shift-details a *.red, .shift-details a *.red * {
      color: #cd1419; }
    .shift-details a .fa, .shift-details a * .fa {
      margin-right: 3px;
      margin-left: 6px; }
  .shift-details .roles {
    margin-top: 10px;
    color: #999999; }
    .shift-details .roles, .shift-details .roles * {
      line-height: 1; }
    .shift-details .roles .shift-role {
      margin-bottom: 0px; }
    .shift-details .roles .ui-select-toggle, .shift-details .roles .ui-select-search {
      font-size: 12px;
      font-style: normal;
      border: none;
      color: black;
      padding-top: 7px;
      padding-left: 18px; }
      .shift-details .roles .ui-select-toggle.text, .shift-details .roles .ui-select-search.text {
        color: black; }
    .shift-details .roles .ui-select-choices a:hover, .shift-details .roles .ui-select-choices a:focus, .shift-details .roles .ui-select-choices a:active, .shift-details .roles .ui-select-choices a *:hover, .shift-details .roles .ui-select-choices a *:focus, .shift-details .roles .ui-select-choices a *:active {
      color: white; }
    .shift-details .roles .ui-select-choices-row.active a, .shift-details .roles .ui-select-choices-row.active a * {
      color: white; }
      .shift-details .roles .ui-select-choices-row.active a:hover, .shift-details .roles .ui-select-choices-row.active a:focus, .shift-details .roles .ui-select-choices-row.active a:active, .shift-details .roles .ui-select-choices-row.active a *:hover, .shift-details .roles .ui-select-choices-row.active a *:focus, .shift-details .roles .ui-select-choices-row.active a *:active {
        color: white; }
    .shift-details .roles .ui-select-choices-row a {
      color: black; }
  .shift-details input.edit-prac {
    width: 90%; }

.shift-details .dropdown-menu, .roster-content .dropdown-menu {
  font-size: 1em;
  z-index: 3; }

.practitioner-select {
  width: 150px;
  font-size: 1em;
  padding: 4px; }

.invisible {
  visibility: hidden; }

.btn-group > .btn.active {
  z-index: 0; }

.calendar-hours {
  position: relative;
  border-bottom: 2px solid #333333;
  height: 18px; }
  .calendar-hours .tick {
    width: 1px;
    margin-left: auto;
    margin-right: auto;
    border-right: 1px solid; }

.date-holder {
  padding-top: 0.5em;
  padding-bottom: 0.5em; }
  .date-holder .clone-btn {
    float: right;
    display: inline; }
    .date-holder .clone-btn, .date-holder .clone-btn * {
      color: white; }
    .date-holder .clone-btn i {
      margin-right: 4px; }
    .date-holder .clone-btn:hover, .date-holder .clone-btn:active, .date-holder .clone-btn:focus {
      text-decoration: none; }
      .date-holder .clone-btn:hover span, .date-holder .clone-btn:active span, .date-holder .clone-btn:focus span {
        text-decoration: underline; }
  .date-holder a.date-arrow {
    padding: 0 0.5em;
    display: inline-block;
    cursor: pointer; }
    .date-holder a.date-arrow:hover {
      text-decoration: none; }
  .date-holder .date-disp {
    display: inline-block; }
  .date-holder .date-seg {
    display: inline-block;
    margin: 0 1em; }

button.date-select.selected {
  background-color: #000;
  color: white; }

h4.teams {
  font-size: 1em; }

h5.team-header {
  font-size: .9em;
  margin-bottom: 3px;
  margin-top: 4px; }

.show-end-time {
  float: right;
  width: 6px;
  height: 60px;
  margin-right: -10px; }

.show-start-time {
  float: left;
  width: 6px;
  height: 60px;
  margin-left: -6px; }

.dropdown-menu > li > a {
  font-size: .8em; }

table.ng-invalid-min-time input, table.ng-invalid-max-time input {
  border-color: maroon; }

/* Roster: ui-bootstrap Typeahead*/
.typeahead-no-border {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%; }

.team-nav {
  color: #b3b3b3; }

.reason {
  padding-top: 10px; }

/* Clone shifts */
.shift-summary {
  background: url("../assets/images/grey-tile.png");
  margin-bottom: 2px;
  padding: 2px 4px 1px 4px; }

/* Placeholders */
.shift-cell input::-moz-placeholder {
  font-style: italic;
  opacity: 1;
  color: #c2cceb; }

.shift-cell input:-ms-input-placeholder {
  font-style: italic;
  opacity: 1;
  color: #c2cceb; }

.shift-cell input::-webkit-input-placeholder {
  font-style: italic;
  opacity: 1;
  color: #c2cceb; }

.shift-cell input:focus::-moz-placeholder, .shift-cell input.focus::-moz-placeholder, .shift-cell input:active::-moz-placeholder, .shift-cell input.active::-moz-placeholder {
  opacity: 1;
  color: white; }

.shift-cell input:focus::-ms-input-placeholder, .shift-cell input.focus::-ms-input-placeholder, .shift-cell input:active::-ms-input-placeholder, .shift-cell input.active::-ms-input-placeholder {
  opacity: 1;
  color: white; }

.shift-cell input:focus::-webkit-input-placeholder, .shift-cell input.focus::-webkit-input-placeholder, .shift-cell input:active::-webkit-input-placeholder, .shift-cell input.active::-webkit-input-placeholder {
  opacity: 1;
  color: white; }

.shift-details .shift-cell input::-moz-placeholder {
  font-style: italic;
  opacity: 1;
  color: #4f6bc6; }

.shift-details .shift-cell input:-ms-input-placeholder {
  font-style: italic;
  opacity: 1;
  color: #4f6bc6; }

.shift-details .shift-cell input::-webkit-input-placeholder {
  font-style: italic;
  opacity: 1;
  color: #4f6bc6; }

.shift-details .shift-cell input:focus::-moz-placeholder, .shift-details .shift-cell input.focus::-moz-placeholder, .shift-details .shift-cell input:active::-moz-placeholder, .shift-details .shift-cell input.active::-moz-placeholder {
  opacity: 1;
  color: #000; }

.shift-details .shift-cell input:focus::-ms-input-placeholder, .shift-details .shift-cell input.focus::-ms-input-placeholder, .shift-details .shift-cell input:active::-ms-input-placeholder, .shift-details .shift-cell input.active::-ms-input-placeholder {
  opacity: 1;
  color: #000; }

.shift-details .shift-cell input:focus::-webkit-input-placeholder, .shift-details .shift-cell input.focus::-webkit-input-placeholder, .shift-details .shift-cell input:active::-webkit-input-placeholder, .shift-details .shift-cell input.active::-webkit-input-placeholder {
  opacity: 1;
  color: #000; }

#edit-contact .num-input {
  display: inline-block;
  padding-top: 5px; }

.dark-bg {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 4px; }
  .dark-bg * {
    color: #fff; }
  .dark-bg .today {
    padding-right: 8px;
    a: hover;
      a-text-decoration: none;
      a-color: #fff; }

.pad-top-5 {
  padding-top: 5px; }

.change-warning {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 10px 20px;
  background-color: #999999;
  color: #fff; }
  .change-warning i {
    font-size: 2.8em; }

label.link {
  cursor: pointer; }
  label.link:hover {
    text-decoration: underline; }

.date-input {
  background-color: #fff;
  height: 410px;
  width: 375px;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 20px;
  border: 1px solid #777777;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 5; }
  .date-input h3 {
    text-align: center;
    margin-top: 0;
    font-size: 20px; }
  .date-input ul.dropdown-menu {
    transform: translate(15px, 50px);
    box-shadow: none; }

#aggregate-form button {
  margin: 0; }

#aggregate-form .row.shift {
  padding-bottom: 5px;
  padding-top: 5px; }

#aggregate-form .dragging {
  -ms-transform: rotate(3deg);
  /* IE 9 */
  -webkit-transform: rotate(2deg);
  /* Safari */
  transform: rotate(3deg); }

#aggregate-form .shift-summary-container {
  border: 1px solid #cccccc;
  padding: 4px; }
  #aggregate-form .shift-summary-container .drop-target {
    text-align: center;
    width: 100%;
    padding-bottom: 3px; }

.name-edit-icon {
  display: inline-block;
  padding-right: 10px;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px; }
  .name-edit-icon.green {
    color: #339901; }
  .name-edit-icon.blue {
    color: #627bcc; }

.edit-name {
  padding-bottom: 10px; }
  .edit-name input {
    border-radius: 3px; }

#task-list label.task-search {
  color: #444;
  background-color: #e0e0e0;
  font-weight: normal;
  position: relative;
  top: -1px;
  cursor: auto;
  min-width: 80px;
  display: inline-block; }
  #task-list label.task-search.date {
    padding: 3px 12px; }
  #task-list label.task-search.filter {
    padding: 6px 12px; }

#task-list .date-select {
  display: inline-block;
  padding-right: 6px; }

#task-list input.search-date {
  font-size: smaller;
  width: 150px;
  padding: 7px;
  margin-right: 5px;
  border-radius: 3px;
  border: 1px solid #999999; }

#task-list input.search-text {
  border: 1 px solid #999999;
  border-radius: 3px;
  display: inline-block;
  padding-right: 20px; }

#task-list .search-field .form-control.btn {
  border: 1px solid #999999; }

#task-list .search-container {
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #999999; }

#task-list .borderless {
  display: inline-block; }
  #task-list .borderless .form-control {
    border: none;
    min-width: 180px; }

#task-list .filter-select {
  display: inline-block;
  width: 200px;
  margin-right: 10px; }
  #task-list .filter-select ul.ui-select-choices {
    width: 200px; }
  #task-list .filter-select input {
    width: 200px; }
  #task-list .filter-select .btn.form-control {
    width: 200px; }

#task-list .filter-row {
  padding-top: 8px;
  background-color: #f3f3f3;
  padding-bottom: 6px;
  margin-bottom: 4px; }

#task-list .filter-options {
  margin-bottom: -10px;
  height: auto; }

#task-list a.clear-search {
  position: relative;
  display: inline-block;
  left: -5px;
  margin-left: -15px;
  color: #777; }

#task-list a.disabled {
  color: #ccc !important;
  cursor: not-allowed; }

#task-list .animate-show-hide.ng-hide {
  height: 0;
  opacity: 0; }

#task-list .animate-show-hide {
  transition: all linear 0.2s;
  -webkit-transition: all linear 0.2s; }

#task-list .task-search-btn {
  margin-left: 10px; }

.user-form .rolepill, .user-dialog .rolepill {
  display: inline-block;
  background-color: #777777;
  color: #fff;
  border-radius: 3px;
  padding: 5px;
  margin-right: 5px; }

.user-form .green, .user-dialog .green {
  color: #339901; }

.user-form .user-filter, .user-dialog .user-filter {
  width: 300px;
  display: inline-block; }

.user-form button.search, .user-dialog button.search {
  display: inline-block; }

.user-form button.role-search, .user-dialog button.role-search {
  position: relative; }

/* Accordion */
accordion .panel-default, accordion .panel-default > .panel-heading, accordion .panel-group .panel-heading + .panel-collapse > .panel-body, accordion .panel-group .panel-heading + .panel-collapse > .list-group {
  border-color: transparent;
  border: 0;
  box-shadow: none; }

accordion .panel-heading {
  display: none; }

accordion .panel-body {
  padding: 0; }

accordion .panel {
  background-color: transparent; }

accordion .panel-group {
  margin-bottom: 0 !important; }

accordion .col-xs-6:nth-child(3), accordion .col-xs-6:nth-child(4) {
  padding-top: 1em; }
  @media screen and (min-width: 768px) {
    accordion .col-xs-6:nth-child(3), accordion .col-xs-6:nth-child(4) {
      padding-top: 0; } }

.login-modal button.close {
  margin-top: 20px;
  margin-right: 20px; }

.login-modal button.close {
  margin-top: 20px;
  margin-right: 20px; }

/*NAV BAR*/
/* mixins */
/* anims */
@-o-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-moz-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-webkit-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-o-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-moz-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-webkit-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

nav.top-nav {
  background-color: white;
  background-color: white;
  position: fixed;
  height: 75px;
  border-bottom: 1px solid #e0e0e0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030; }

.nav > li > a {
  color: #555555;
  -o-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out; }

.nav > li > a:hover, .nav > li > a:focus, .nav > li > a:active {
  background-color: transparent; }

.nav > li .dropdown-menu .no-link {
  font-size: .8em;
  padding: 3px 20px;
  white-space: nowrap; }

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus, .nav .open > a:active {
  background-color: transparent; }

.container-fluid > .navbar-header {
  float: left;
  width: 50px;
  margin-right: 0;
  margin-left: -15px;
  display: flex;
  height: 75px; }
  @media all and (min-width: 992px) {
    .container-fluid > .navbar-header {
      width: 250px; } }
  .container-fluid > .navbar-header button#menu-toggle-2 {
    float: left;
    padding: 5px 9px;
    height: 40px;
    width: 40px;
    align-self: center;
    margin-left: 10px;
    border-color: #666;
    color: #333; }
    .container-fluid > .navbar-header button#menu-toggle-2:hover, .container-fluid > .navbar-header button#menu-toggle-2:focus, .container-fluid > .navbar-header button#menu-toggle-2:active, .container-fluid > .navbar-header button#menu-toggle-2.focus, .container-fluid > .navbar-header button#menu-toggle-2.active {
      background-color: transparent;
      border-color: #000;
      color: #000; }
    .container-fluid > .navbar-header button#menu-toggle-2.btn-pressed {
      background-color: #555;
      border-color: #555;
      color: white;
      -webkit-box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.75);
      box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.75); }
      .container-fluid > .navbar-header button#menu-toggle-2.btn-pressed:hover, .container-fluid > .navbar-header button#menu-toggle-2.btn-pressed:focus, .container-fluid > .navbar-header button#menu-toggle-2.btn-pressed:active, .container-fluid > .navbar-header button#menu-toggle-2.btn-pressed.focus, .container-fluid > .navbar-header button#menu-toggle-2.btn-pressed.active {
        border-color: #000; }
  .container-fluid > .navbar-header .navbar-brand {
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    display: none;
    align-self: center;
    padding-left: 27px;
    margin-bottom: 2px; }
    @media all and (min-width: 992px) {
      .container-fluid > .navbar-header .navbar-brand {
        display: block; } }
    .container-fluid > .navbar-header .navbar-brand.pushLeft {
      padding-left: 31px; }
  .container-fluid > .navbar-header .environment {
    width: 200px;
    position: absolute;
    top: 20px;
    left: 250px; }

.navbar-menus .navbar-nav {
  margin: 0; }
  .navbar-menus .navbar-nav > li > a {
    padding-top: 14.5px;
    padding-bottom: 14.5px; }

.navbar-menus .navbar-right {
  float: right !important;
  margin-right: -15px;
  height: 75px; }
  @media all and (min-width: 992px) {
    .navbar-menus .navbar-right {
      margin-right: 0; } }
  .navbar-menus .navbar-right.navbar-nav > li {
    float: left !important; }
  .navbar-menus .navbar-right li a {
    font-weight: 400;
    height: 50px;
    margin-top: 25px;
    padding: 10px 35px;
    border-left: 1px solid #e0e0e0;
    border-radius: 5px; }
    .navbar-menus .navbar-right li a:hover {
      background-color: #eff8ff; }
    .navbar-menus .navbar-right li a:active {
      background-color: #1570ef;
      colo: #fff; }
  .navbar-menus .navbar-right .navbar-text {
    float: left;
    margin: 14.5px 15px; }
  .navbar-menus .navbar-right .open .dropdown-menu {
    position: absolute;
    float: left;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
  .navbar-menus .navbar-right .dropdown-menu > li > a {
    font-size: 1em; }
    .navbar-menus .navbar-right .dropdown-menu > li > a:hover, .navbar-menus .navbar-right .dropdown-menu > li > a:focus {
      background-color: #555;
      color: white; }
  .navbar-menus .navbar-right .navbar-icon {
    display: inline-block;
    margin-right: 10px;
    width: 15px; }
    .navbar-menus .navbar-right .navbar-icon svg {
      max-width: 14px;
      max-height: 12px;
      fill: currentColor; }
  .navbar-menus .navbar-right .max-width {
    max-width: 220px; }

.newalert {
  position: absolute;
  left: 24px;
  top: 15px;
  color: #cd1419;
  font-size: 11px; }

.outline {
  position: absolute;
  left: 23px;
  top: 14px;
  color: #fff;
  font-size: 13px; }

.menu-escalation {
  color: #ec2265;
  font-size: 14px;
  animation: escalation-pulse 2s infinite;
  display: inline-block;
  padding-right: 3px; }

.time-since {
  color: #777777;
  font-style: italic; }

ul.alerts {
  max-height: 65vh;
  min-width: 210px;
  overflow: auto; }
  ul.alerts li {
    border-bottom: 1px solid #d8dbe6; }
    ul.alerts li:last-child {
      border: none; }
    ul.alerts li a {
      padding: 10px 20px; }
  ul.alerts.dropdown-menu > li.new-alert, ul.alerts.dropdown-menu > li.read-alert {
    font-size: 0.875em;
    background-color: #edecf8; }
    ul.alerts.dropdown-menu > li.new-alert:hover, ul.alerts.dropdown-menu > li.new-alert:focus, ul.alerts.dropdown-menu > li.new-alert:active, ul.alerts.dropdown-menu > li.read-alert:hover, ul.alerts.dropdown-menu > li.read-alert:focus, ul.alerts.dropdown-menu > li.read-alert:active {
      background-color: #d5d3f0; }
    ul.alerts.dropdown-menu > li.new-alert > a, ul.alerts.dropdown-menu > li.new-alert > a:hover, ul.alerts.dropdown-menu > li.new-alert > a:active, ul.alerts.dropdown-menu > li.new-alert > a:focus, ul.alerts.dropdown-menu > li.read-alert > a, ul.alerts.dropdown-menu > li.read-alert > a:hover, ul.alerts.dropdown-menu > li.read-alert > a:active, ul.alerts.dropdown-menu > li.read-alert > a:focus {
      background-color: transparent;
      color: #000; }
  ul.alerts.dropdown-menu > li.read-alert {
    background-color: transparent; }
  ul.alerts.dropdown-menu > li.mark-read {
    padding-bottom: 10px; }
    ul.alerts.dropdown-menu > li.mark-read > a {
      padding: 0;
      float: right;
      color: #627bcc; }
      ul.alerts.dropdown-menu > li.mark-read > a:hover, ul.alerts.dropdown-menu > li.mark-read > a:active, ul.alerts.dropdown-menu > li.mark-read > a:focus {
        color: #627bcc;
        background-color: transparent;
        text-decoration: underline; }
    ul.alerts.dropdown-menu > li.mark-read:hover, ul.alerts.dropdown-menu > li.mark-read:active, ul.alerts.dropdown-menu > li.mark-read:focus {
      background-color: transparent; }

/*NAV BAR*/
/* mixins */
/* anims */
@-o-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-moz-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-webkit-keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@keyframes animateButton {
  0% {
    opacity: 0;
    height: 0; }
  100% {
    opacity: 1;
    height: 30px; } }

@-o-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-moz-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@-webkit-keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

@keyframes rowHeight {
  0% {
    opacity: 0;
    max-height: 0; }
  100% {
    opacity: 1;
    max-height: 600px; } }

/*!
 * Start Bootstrap - Simple Sidebar HTML Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http: */
/* Toggle Styles */
/* This sidebar modified by seegatesite.com */
#menu.sidebar-nav.nav-pills.nav-stacked li {
  padding: 1px 5px; }
  #menu.sidebar-nav.nav-pills.nav-stacked li a {
    font-weight: 400; }
    #menu.sidebar-nav.nav-pills.nav-stacked li a .pull-right {
      padding-right: 20px; }
  #menu.sidebar-nav.nav-pills.nav-stacked li .sidebar-svg {
    text-align: center;
    font-size: 1.3em;
    position: relative;
    width: 2em;
    height: 2em;
    line-height: 2.5em;
    vertical-align: middle;
    display: inline-block;
    float: left; }
    #menu.sidebar-nav.nav-pills.nav-stacked li .sidebar-svg, #menu.sidebar-nav.nav-pills.nav-stacked li .sidebar-svg * {
      box-sizing: border-box; }
    #menu.sidebar-nav.nav-pills.nav-stacked li .sidebar-svg svg {
      width: 16px;
      height: 16px;
      fill: currentColor; }
  #menu.sidebar-nav.nav-pills.nav-stacked li ul.nav-pills.nav-stacked {
    padding: 0 0 0 40px;
    list-style-type: none; }
    #menu.sidebar-nav.nav-pills.nav-stacked li ul.nav-pills.nav-stacked.nav-admin {
      padding: 0 0 100px 40px; }

#wrapper {
  padding-left: 0;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease; }
  #wrapper.toggled {
    padding-left: 380px; }
  #wrapper.toggled-2 {
    padding-left: 0px;
    padding-right: 75px; }
    #wrapper.toggled-2 #sidebar-wrapper {
      top: 50px;
      width: 380px; }
  #wrapper.nopad {
    padding-left: 0; }

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 380px;
  width: 0;
  height: 100%;
  margin-left: -380px;
  margin-top: 24px;
  overflow-y: scroll;
  background: #FFF;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease; }

#wrapper.toggled #sidebar-wrapper {
  width: 380px; }

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 34px 0; }
  @media all and (min-width: 992px) {
    #page-content-wrapper {
      padding: 4px 5px; } }

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -380px; }

.fixed-brand {
  width: auto; }

.chip {
  background: #D1E9FF;
  color: #194185;
  font-size: 0.8em;
  padding: 2px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  margin-right: 5px; }

/* Sidebar Styles */
.utilities-nav-container {
  position: absolute;
  bottom: 55px;
  width: 100%;
  padding: 10px; }
  .utilities-nav-container .utilities-nav {
    border-top: 1px solid #555;
    list-style: none;
    padding: 15px;
    width: 380px;
    text-align: left;
    font-size: 0.875em; }
    .utilities-nav-container .utilities-nav li {
      padding: 0px 0px;
      padding-top: 3px;
      padding-bottom: 3px;
      color: #616161; }
      .utilities-nav-container .utilities-nav li i.fa {
        font-size: 1.5em;
        display: none; }
      .utilities-nav-container .utilities-nav li a {
        -o-transition: all 150ms ease-in-out;
        -moz-transition: all 150ms ease-in-out;
        -webkit-transition: all 150ms ease-in-out;
        transition: all 150ms ease-in-out;
        color: #616161;
        display: block; }
        .utilities-nav-container .utilities-nav li a:focus, .utilities-nav-container .utilities-nav li a:active, .utilities-nav-container .utilities-nav li a:hover, .utilities-nav-container .utilities-nav li a.active {
          color: white;
          text-decoration: none; }
  .utilities-nav-container .app-version {
    font-size: 0.875em;
    text-align: right;
    position: absolute;
    color: #616161;
    right: 10px;
    bottom: 37px; }

.nav-pills li.active a {
  color: #000;
  background: #eff8ff; }
  .nav-pills li.active a:hover {
    color: #000;
    background: #eff8ff; }
  .nav-pills li.active a:active {
    background: #1570ef; }

.nav-pills li.active a {
  color: #000;
  background: #eff8ff; }
  .nav-pills li.active a:hover {
    background: #eff8ff; }

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 380px;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 2px; }
  .sidebar-nav li {
    line-height: 48px;
    text-indent: 15px;
    padding: 5px 0px; }
    .sidebar-nav li li {
      padding: 0px; }
    .sidebar-nav li a {
      -o-transition: all 150ms ease-in-out;
      -moz-transition: all 150ms ease-in-out;
      -webkit-transition: all 150ms ease-in-out;
      transition: all 150ms ease-in-out;
      display: block;
      text-decoration: none;
      color: #000000;
      border-radius: 50px; }
      .sidebar-nav li a:hover {
        text-decoration: none;
        color: #000;
        background: #eff8ff; }
        .sidebar-nav li a:hover .chip {
          color: #194185; }
      .sidebar-nav li a:active, .sidebar-nav li a:focus {
        text-decoration: none;
        background-color: #1570ef;
        colo: #fff; }
  .sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px; }
    .sidebar-nav > .sidebar-brand a {
      color: #999999; }
      .sidebar-nav > .sidebar-brand a:hover {
        color: #fff;
        background: none; }
  .sidebar-nav .navbar-icon {
    display: inline-block;
    margin-right: 12px;
    margin-left: -10px;
    width: 32px; }
    .sidebar-nav .navbar-icon svg {
      width: 15px;
      height: 15px;
      fill: currentColor; }
  .sidebar-nav .header {
    line-height: 22px;
    height: 22px; }
  .sidebar-nav .navHeader {
    height: 20px;
    line-height: 15px;
    font-size: 10px;
    color: #616161;
    text-transform: uppercase;
    padding: 0px 10px; }

@media only screen and (min-width: 992px) {
  #wrapper {
    padding-left: 380px; }
  .fixed-brand {
    width: 380px; }
  #wrapper.toggled {
    padding-left: 0; }
  #sidebar-wrapper {
    width: 380px; }
  #wrapper.toggled #sidebar-wrapper {
    width: 380px; }
    #wrapper.toggled #sidebar-wrapper li {
      width: 50px; }
    #wrapper.toggled #sidebar-wrapper .navHeader {
      display: none; }
    #wrapper.toggled #sidebar-wrapper .nav-link {
      width: 50px; }
      #wrapper.toggled #sidebar-wrapper .nav-link .nav-title {
        display: none; }
  #wrapper.toggled-2 #sidebar-wrapper {
    width: 65px; }
    #wrapper.toggled-2 #sidebar-wrapper:hover {
      widh: 65px; }
  #wrapper.toggled-2 #sidebar-wrapper .navHeader {
    display: none; }
  #wrapper.toggled-2 #sidebar-wrapper a {
    width: 55px;
    height: 55px;
    overflow: hidden; }
    #wrapper.toggled-2 #sidebar-wrapper a .sidebar-svg {
      line-height: 50px; }
    #wrapper.toggled-2 #sidebar-wrapper a .nav-title {
      display: none; }
    #wrapper.toggled-2 #sidebar-wrapper a .pull-right {
      display: none; }
    #wrapper.toggled-2 #sidebar-wrapper a .navHeader {
      display: none; }
  #wrapper.toggled-2 .utilities-nav-container {
    padding: 0; }
  #wrapper.toggled-2 .utilities-nav {
    padding: 0;
    padding-bottom: 20px;
    width: 65px;
    text-align: center; }
    #wrapper.toggled-2 .utilities-nav li {
      margin-left: 5px;
      margin-right: 5px;
      width: 55px;
      height: 55px; }
    #wrapper.toggled-2 .utilities-nav i.fa {
      font-size: 17px;
      display: block;
      color: #616161;
      padding: 10px; }
    #wrapper.toggled-2 .utilities-nav .utility-text {
      color: pink;
      display: none; }
  #wrapper.toggled-2 .app-version {
    display: inline;
    position: relative; }
  #page-content-wrapper {
    padding: 30px 15px;
    position: relative;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
    padding-left: 380px; }
  #wrapper.toggled-2 #page-content-wrapper {
    position: relative;
    margin-right: 0;
    margin-left: 50px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; } }

@media only print {
  #wrapper {
    padding: 0 !important;
    position: relative; }
    #wrapper #page-content-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 !important;
      margin: 0 !important; } }

.alert-icon {
  position: relative; }
  .alert-icon i.fa.small {
    font-size: 15px;
    position: absolute;
    left: 8px;
    top: 5px;
    color: #333; }
    .alert-icon i.fa.small i.fa.smaller {
      font-size: 13px;
      position: absolute;
      left: 8px;
      top: 5px; }

.sync-wrap {
  display: inline;
  position: relative; }
  .sync-wrap .sync {
    position: absolute;
    top: 3px;
    display: inline-block;
    margin-left: 4px;
    height: 16px;
    width: 16px;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 0.5;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear; }
    .sync-wrap .sync svg {
      width: 16px;
      position: absolute;
      fill: #999999;
      left: 0;
      top: -5px; }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-webkit-keyframes spin {
  0% { }
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  100% { }
  -webkit-transform: rotate(359deg);
  transform: rotate(359deg); }
