@import "common/colors.sass";
@import "common/mixins.sass";


.sliderBox {
	padding: 10px;
	min-height: 60px;
	margin-top: 30px;
	&.no-margin {
		margin: 0; }
	&.no-padding {
		padding: 0; } }

//- Override JSLIDE stylings:
.jslider div.jslider-pointer {
	width: 32px;
	height: 32px;
	overflow: hidden;
	top: -12px;
	margin-left: -15px; }

.jslider div.jslider-pointer {
	background: url(../assets/images/jslider-pointers.png) center top;
	background-size: 32px 64px;
	background-position: 0 0;
	&.jslider-pointer-hover {
		background-position: 0 32px; } }


.jslider div.jslider-bg {
	top: -7px;
	i {
		height: 14px;
		background-color: #ddd;
		background-image: none;
		@include box-shadow(inset 0 1px 2px 0 rgba(0,0,0,0.5));
		&.range {
			background-color: $highlight;
			height: 14px; } } }

.jslider div.jslider-scale {
	span {
		border-color: #ccc;
		height: 15px; }
	ins {
		top: 10px;
		background-color: white; } }

#override {
	.btn-default:active, .btn-default.active {
		color: white;
		background-color: $highlight;
		border-color: $highlight; } }
