#aggregate-form {
	button {
		margin: 0; }
	.row.shift {
		padding-bottom: 5px;
		padding-top: 5px; }
	.dragging {
		-ms-transform: rotate(3deg); /* IE 9 */;
		-webkit-transform: rotate(2deg); /* Safari */;
		transform: rotate(3deg); }
	.shift-summary-container {
		border: 1px solid $gray-light4;
		padding: 4px;
		.drop-target {
			text-align: center;
			width: 100%;
			padding-bottom: 3px; } } }
.name-edit-icon {
	display: inline-block;
	padding-right: 10px;
	font-size: 18px;
	margin-top: 10px;
	margin-bottom: 10px;
	&.green {
		color: $green; }
	&.blue {
		color: $highlight; } }
.edit-name {
	padding-bottom: 10px;
	input {
		border-radius: 3px; } }
