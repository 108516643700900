/* Accordion */

accordion {
	.panel-default, .panel-default > .panel-heading, .panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
		border-color: transparent;
		border: 0;
		box-shadow: none; }
	.panel-heading {
		display: none; }
	.panel-body {
		padding: 0; }
	.panel {
		background-color: transparent; }
	.panel-group {
		margin-bottom: 0 !important; }
	.col-xs-6:nth-child(3), .col-xs-6:nth-child(4) {
		padding-top: 1em;
		@media screen and (min-width: 768px) {
			padding-top: 0; } } }

