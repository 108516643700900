@import "common/colors.sass";
@import "common/mixins.sass";

.compose {

	section#section-2, section#section-2B {
		padding-top: 0.3333em; }
	.row.remove-margin {
		margin-left: 0;
		margin-right: 0; }
	.border-bottom {
		margin-bottom: 1.3333333em;
		padding-bottom: 0.6666666em;
		border-bottom: 1px solid $gray-lighter; }
	p.border-bottom {
		padding-bottom: 1.3333333em; }

	.notPatientRelated {
		margin: 5px 0;
		-o-animation: animateButton 150ms ease-in;
		-moz-animation: animateButton 150ms ease-in;
		-webkit-animation: animateButton 150ms ease-in;
		animation: animateButton 150ms ease-in;
		&.ng-hide {
			-o-animation: animateButton 150ms ease-in alternate;
			-moz-animation: animateButton 150ms ease-in alternate;
			-webkit-animation: animateButton 150ms ease-in alternate;
			animation: animateButton 150ms ease-in alternate; } }
	.ccs {
		margin-top: 10px;
		.name {
			color: $gray-dark; } }
	.remove {
		font-size: small;
		font-weight: bold;
		float: right;
		color: $nimblic-red;
		padding-right: 50px; }

	.toggle-cc {
		position: absolute;
		right: 20px;
		margin-top: -10px;
		font-size: 18px; }

	.observation-header {
		padding: 10px 5px;
		margin: 20px 0 10px;
		background-color: #f5f5f5;
		border-top: 1px solid #e8e8e8;
		border-bottom: 1px solid #bdbdbd; }

	.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
		background-color: $highlight;
		border-color: $highlight; }

	.list-group-item.highlighted, .list-group-item.highlighted:hover, .list-group-item.highlighted:focus {
		background-color: #ddd;
		border-color: #ddd; }

	.pager-preview {
		.message {
			@include border-radius(15px);
			@include box-shadow-2(0 0 0 1px #e5e5e5, inset 1px 1px 2px 0 #ccc);
			border: 15px solid white;
			border-top: 30px solid white;
			border-bottom: 20px solid white;
			background-color: rgba($highlight, 0.15);
			padding: 10px 12px;
			font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
			font-weight: bold;
			font-size: 12px;
			color: $gray-light; }
		.pager-header {
			font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
			font-style: oblique;
			font-weight: 400;
			color: $gray-light3;
			position: absolute;
			top: 5px;
			left: 30px;
			font-size: 14px; }
		.chars-over {
			color: lighten($urgent, 10%); }
		.extra-chars {
			color: darken($urgent, 3%);
			background-color: rgba(white, 0.5); } }

	.form-inline .form-group input[type="text"].form-control {
		//- Make inline for all sizes:
		width: auto;
		display: inline-block;
		&.small-input {
			max-width: 60px; }
		&.phone-input {
			width: 100px; }
		&.pager-input {
			width: 70px; } }

	.compose-help {
		font-style: italic;
		color: $highlight; }

	.form-group {
		&.campus {
			.ui-select-container {
				display: inline-block;
				margin-right: 30px;
				width: 220px;
				padding-right: 10px; } }
		&.ward {
			.form-control {
				width: 200px !important;
				margin-right: 10px; } } }

	.btn.btn-info#task-btn {
		border-color: #ddd;
		&:focus, &.focus, &:active:focus, &:active.focus, &.active:focus, &.active.focus {
			border-color: $highlight; } }

	.task-panel {
		-webkit-transition: max-height ease-in 0.2s;
		transition: max-height ease-in 0.2s;
		// Todo: Why is this here?
		// max-height: 530px
 }		// overflow: hidden
	.task-panel {
		&.ng-hide {
			height: auto;
			max-height: 0; } }

	.btn-group-vertical.block {
		display: block; }

	.covering {
		font-style: italic; }

	a.search-select {
		width: 120px;
		display: inline-block;
		font-size: 18px;
		color: #fff;
		opacity: 0.5;
		&.selected {
			opacity: 1; } }

	select.form-control.select-nrm {
		color: $gray-copy;
		&.met, &.premet, &.urgent {
			color: white;
			border: none; }
		&.met {
			background-color: $MET; }
		&.premet {
			background-color: $preMET; }
		&.urgent {
			background-color: $urgent; } }

	.ui-select-container.ui-select-bootstrap.dropdown {
		@include border-radius(3px);
		.btn-default-focus {
			&:focus {
				background-color: red;
				outline: none; } }

		// dropdown menu
		.dropdown-menu {
			background-color: white;
			padding: 0;
			// text-transform: uppercase
			width: auto;

			// dropdown inner
			.ui-select-choices-row.active > a {
				background-color: $highlight; }
			.ui-select-choices-row > a {
				font-size: 0.8em;
				padding: 3px 10px;
				small {
					margin-right: 10px;
					min-width: 50px;
					display: inline-block; } } }

		// input
		.form-control.ui-select-search {
			font-size: 1em;
			border: 0;
			padding: 0;
			background-color: transparent;
			height: auto;
			text-transform: uppercase; }
		// default button
		.ui-select-toggle.form-control.btn {
			font-size: 1em;
			height: auto;
			@include border-radius(3px);
			text-transform: uppercase;
			padding: 0;
			border: 0;
			background-color: transparent;
			// clear filter
			a.btn.btn-xs.btn-link.pull-right {
				padding: 0 5px 0 0;
				border: none;
				line-height: 1;
				.glyphicon {
					top: 4px; } }
			// dropdown caret
			> .caret {
				top: 7px;
				right: 0px; } } }

	.custom {
		background-color: #eee;
		margin: 10px -15px 10px -13px;
		padding: 0 10px 20px 20px;
		.custom-field {
			&.half-width {
				width: 50%;
				display: inline-block;
				padding-right: 15px; }
			&.full-width {
				width: 100%;
				clear: left; } }
		.check {
			display: inline-block;
			margin-right: 25px;
			label {
				font-size: small;
				display: block;
				margin-bottom: 5px !important; }
			.mt-checkbox {
				width: 22px;
				height: 22px; } }
		.form-control.custom-select {
			&.ng-invalid {
				border-color: $nimblic-red; }
			width: 100%;
			background-color: #fff;
			padding: 5px !important;
			border-radius: 5px;
			border-color: #999;
			.ui-select-toggle {
				display: inline-block;
				width: 100%; } } }

	.shift-up {
		margin-top: -15px; }

	.form-control.ta-select.ui-select-container {
		&.big-input {
			z-index: inherit; }
		&.custom-select {
			padding: 5px;
			border: 1px solid #999999;
			border-radius: 5px;
			width: 215px; }
		.ui-select-placeholder {
			text-transform: none;
			padding-right: 10px !important;
			font-size: 1em; }
		.ui-select-match {
			.ng-binding {
				padding-right: 5px; }
			.ui-select-match-text {
				text-transform: none;
				max-width: 280px;
				display: inline-block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis; } }
		input.ui-select-search {
			text-transform: none !important; } }

	.form-control.campus-select.ui-select-container {
		/* fix text overflow issue */
		.ui-select-match {
			.ui-select-match-text {
				display: inline-block;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 85%; } }
		.ui-select-toggle {
			width: 250px; } }

	.form-group.campus {
		.ui-select-container.ui-select-bootstrap.dropdown {
			.ui-select-toggle.form-control.btn {
				text-transform: none; } } }


	#observations {
		.btn-default:active, .btn-default.active {
			color: white;
			background-color: $highlight;
			border-color: $highlight; }
		input.obs {
			min-width: 149px; } }

	#input_role {
		min-width: 200px; }

	.white-container {
		padding: 15px 20px 20px;
		margin-bottom: 1.5em;

		.form-inline .form-group label {
			margin: 0; }

		.practitioners, .practitioners-heading, .campus-heading {
			margin-left: 0;
			margin-right: 0; }
		.practitioners-heading, .campus-heading {
			font-weight: 400;
			font-size: 0.8em;
			padding-bottom: 0.75em;
			padding-top: 0.75em;
			background-color: $gray-lighter;
			border: 1px solid $gray-lighter;
			img {
				vertical-align: top; } }
		.campus-heading {
			background-color: $gray;
			border: none;
			label {
				color: $gray-light3;
				float: left;
				margin: 0;
				padding: 2px 4px 0 0; }
			.form-control {
				color: white;
				display: inline-block;
				width: auto; }
			.ui-select-container {
				display: inline-block;
				.ui-select-match.btn-default-focus, .ui-select-match.btn-default-focus * {
					color: white; }
				.ui-select-match-text {
					span {
						padding-right: 15px; } } } }

		.practitioners {
			border: 1px solid $gray-lighter;
			border-top-color: transparent;
			padding-bottom: 0.75em;
			padding-top: 0.75em;
			font-weight: 400;
			font-size: 0.875em;
			cursor: pointer;
			&.border-top {
				margin-top: 2px;
				border-top-color: $gray-lighter; }
			@media screen and (min-width: 1200px) {
				font-size: 1em; }
			&:hover, &:focus, &.focus, &:active, &.active, &.selected {
				background-color: white;
				border-color: lighten($highlight, 20%);
				color: black; }
			.roles {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis; } }

		.overflow {
			background-color: $gray-lighter;
			a {
				color: $gray-dark; } }

		.spid {
			display: inline-block;
			min-width: 80px; }

		.dropdown-holder {
			position: relative;
			.dropdown-panel {
				position: absolute;
				z-index: 1000;
				top: 0;
				width: 100%;
				background-color: white;
				@include box-shadow(0 6px 12px rgba(0,0,0,.175));
 } } }				// @include box-shadow(4px 4px 2px 0 rgba(180,180,180,0.5))

	input[type="text"].form-control::-ms-clear {
		display: none; } }

.task-description {
	background: repeating-linear-gradient(180deg, #fff, #fff 29px, #f0f1f2 29px, #f0f1f2 30px);
	padding: 8px 12px 20px;
	font-size: 18px;
	line-height: 30px;
	width: 100%;
	color: $gray-dark;
	margin: 0;
	border: 0;
	@include box-shadow-2(0 0 0 1px #d4d4d4, 0 1px 2px 0 rgba(0, 0, 0, 0.35));
	&, & * {
		line-height: 30px; }
	p, h1, h2, h3, h4, h5 {
		margin: 0; }
	&:focus, &:active, &.active {
		color: $highlight;
		@include box-shadow-2(0 0 0 1px #b3b3b3, 0 1px 2px 1px rgba($highlight, 0.5));
		color: black;
		outline: 0 none; } }


.task-description::-moz-placeholder {
	font-style: italic; }

.task-description:-ms-input-placeholder {
	font-style: italic; }

.task-description::-webkit-input-placeholder {
	font-style: italic; }

.task-description:focus::-moz-placeholder, .task-description.focus::-moz-placeholder, .task-description:active::-moz-placeholder, .task-description.active::-moz-placeholder {
	color: $highlight; }

.task-description:focus::-ms-input-placeholder, .task-description.focus::-ms-input-placeholder, .task-description:active::-ms-input-placeholder, .task-description.active::-ms-input-placeholder {
	color: $highlight; }

.task-description:focus::-webkit-input-placeholder, .task-description.focus::-webkit-input-placeholder, .task-description:active::-webkit-input-placeholder, .task-description.active::-webkit-input-placeholder {
	color: $highlight; }


.compose .inactive {
	opacity: 0.35; }

.compose .active {
	opacity: 1; }

#dueby {
	position: relative;
	.dueby-inner {
		position: absolute;
		top: auto;
		bottom: 100%;
		left: 0;
		right: 0;
		z-index: 1000;
		padding: 12px;
		background-color: white;
		border: 1px solid rgba(0, 0, 0, 0.15);
		@include border-radius(3px);
		@include box-shadow(0 6px 12px rgba(0,0,0,.175));
		select {
			margin-top: 35px; } } }

.secret {
	display: block;
	height: 0;
	width: 0;
	padding: 0;
	margin: 0;
	border: 0;
	line-height: 0; }

.empty-roles {
	.toggle-empty {
		cursor: pointer;
		color: $gray-light2; } }
.cc-select {
	.role-icon {
		float: right;
		font-size: 24px; }
	.cc-row-select {
		padding: 8px;
		border: 1px solid $gray-light;
		border-top: none;
		margin: 0;
		&.last {
			border-radius: 0 0 4px 4px; }
		&.first {
			border-top: 1px solid $gray-light;
			border-radius: 4px 4px 0 0; }
		&.first {
			&.last {
				border-radius: 4px 4px 4px 4px; } }
		&.selected {
			color: #fff;
			background-color: $highlight; }
		&::hover {
			cursor: pointer; } } }
