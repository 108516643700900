@import "common/colors.sass";
@import "common/variables.sass";
@import "common/mixins.sass";

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 0 none; }

select.form-control.select-nrm {
	-webkit-appearance: menulist;
	-moz-appearance: menulist;
	appearance: menulist;
	font-size: 0.8em;
	@include border-radius(3px);
	text-transform: uppercase;
	font-weight: bold;
	width: 100%; }

select.btn-filter {
	width: 100%;
	background-image: url('../assets/images/icon-filter-grey.svg'), url('../assets/images/icon-pointer-down-grey.png');
	background-repeat: no-repeat, no-repeat;
	background-position: 12px center, right center;
	background-size: 12px 12px, 21px 5px;
	padding-left: 30px;
	padding-right: 30px;
	white-space: nowrap;
	overflow: hidden;
	-ms-text-overflow: hidden; }

.bg-dash {
	background: url('../assets/images/dash.png') no-repeat center center;
	background-size: 10px  1px;
	@media (min-width: 990px) {
		background-position: 25% center; } }

.form-control {
	border-radius: 0;
	box-shadow: none;
	color: $gray-light;
	border: 1px solid $gray-light2;
	&[type="checkbox"] {
		width: auto;
		height: auto;
		display: inline-block; }
	&:hover {
		border-color: $gray-light1;
		color: $gray-light1; }
	&:focus, &.focus, &:active, &.active {
		outline: none;
		box-shadow: none;
		color: black;
		border-color: black; }
	&.input-wide {
		min-width: 220px; } }

textarea.form-control {
	border: 1px solid transparent;
	&:focus, &.focus, &:active, &.active {
		border: 1px solid $highlight; } }

.form-control.big-input {
	@include quickanim;
	border: 1px solid transparent;
	z-index: 1;
	font-size: 1.33333333em;
	padding: 0.25em 0 0 0;
	height: auto;
	color: $gray-dark;
	&:hover {
		text-shadow: 1px 1px 1px rgba($gray-dark, 0.2); }
	&:focus, &.focus, &:active, &.active {
		color: darken($highlight, 10%);
		&:hover {
			text-shadow: 1px 1px 1px rgba($highlight, 0.25); } } }

.form-control[disabled].big-input, .form-control[readonly].big-input, fieldset[disabled].form-control.big-input {
	background-color: white;
	&:hover {
		text-shadow: none; }
	&:focus, &.focus, &:active, &.active {
		color: $gray-copy; } }

.form-control.big-input.med-input {
	font-size: 1em;
	padding: 0;
	&:focus, &.focus, &:active, &.active {
		color: $highlight; }
	&.ward-input {
		&:before {
			content: "\f041"; } } }

.form-control.big-input.med-input.select {
	font-size: 1em; }

.form-control.big-input.sm-input {
	font-size: 1.333333333333em;
	margin-bottom: 15px; }

.invalid {
	color: darken($gray-copy, 10%);
	font-style: oblique;
	padding-top: 0.25em;
	display: inline-block;
	opacity: 1;
	height: 22px;
	&, & * {
		@include anim(200ms, ease-in); }
	&.ng-hide {
		opacity: 0;
		height: 0px;
		&, & * {
			@include anim(200ms, ease-out); } } }

.none {
	display: none; }

.cursor-default {
	cursor: default; }

// .MET, .Code.Blue
// 	color: $nimblic-red-dark

.error {
	color: $MET; }

.warning {
	color: $preMET; }

.error, .warning, .required {
	font-weight: 300;
	font-size: 0.86666em;
	font-style: italic;
	padding: 0.33333em 0 0 0.3333em;
	&, & * {
		@include anim(200ms, ease-in); }
	&.ng-hide {
		opacity: 0;
		height: 0px;
		&, & * {
			@include anim(200ms, ease-out); } }
	&.Pre-MET, &.MET, &.Code.Blue {
		color: white;
		display: inline;
		padding: 0.15em 0.3333em 0.2em;
		@include border-radius(3px); }

	&.Pre-MET {
		background-color: $preMET; }
	&.MET {
		background-color: $MET; }
	&.Code.Blue {
		background-color: darken($highlight, 15%); } }


.big-input-label {
	color: $gray-copy;
	font-weight: 400;
	margin-top: 12px;
	margin-bottom: 0px;
	&.med-input-label {
		margin-top: 5px; }
	&.red {
		color: $nimblic-red; } }

.right-input-label {
	margin-top: 12px;
	font-size: 0.8em; }

.sit-right-label {
	position: absolute;
	right: 15px;
	top: 12px;
	font-weight: 400; }


.big-input-advanced {
	position: absolute;
	z-index: 3;
	font-weight: 300;
	top: 0px;
	right: 5px;
	.fa {
		padding-left: 0.5em; } }

.hint-label {
	font-size: 0.8em;
	font-weight: 300;
	display: block;
	color: $gray-light3;
	&.label-lg {
		padding: 5px 16px; }
	&.inline {
		display: inline; } }

.spacer-group {
	padding-left: 4px;
	padding-right: 4px;
	&:first-child {
		padding-left: 0; }
	&:last-child {
		padding-right: 0; } }

section {
	.remove-margin {
		margin-top: 0px; } }

/* UI SELECT Styling */

.ui-select-match {
	overflow: visible;
	padding-left: 1px;
	&.btn-default-focus {
		outline: none;
		box-shadow: none;
		background-color: transparent;
		&, & * {
			color: $highlight; } }
	.btn.form-control {
		@include border-radius(3px);
		border-color: inherit; }
	/*select*/
	.ui-select-placeholder.text-muted {
		font-style: italic;
		opacity: 1;
		color: $gray-light3; }
	&.btn-default-focus {
		.ui-select-placeholder.text-muted {
			color: $highlight; } } }

/* dropdown background color */
.ui-select-bootstrap .ui-select-choices-row.active > a {
	background-color: $highlight; }

.form-control {
	font-weight: 500;
	color: #555;
	&:focus, &.focus, &:active, &.active {
		color: $highlight; } }

.form-control.ui-select-search {
	@include border-radius(3px); }

.text-muted {
	color: lighten($gray-copy, 10%); }

.form-inline .form-control {
	display: inline-block;
	width: auto;
	&.min-width {
		min-width: 60px; }
	&.med-input {
		vertical-align: baseline; } }

.sb-search {
	@include anim(300ms, ease);
	position: relative;
	display: inline-block;
	width: 100%;
	min-width: 36px;
	height: 30px;
	float: right;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	.form-control.big-input.med-input.sb-search-input {
		@include anim(300ms, ease);
		@include border-radius(3px);
		opacity: 1;
		position: absolute;
		top: 0;
		right: 0;
		border: none;
		outline: none;
		width: 100%;
		height: 30px;
		margin: 0;
		z-index: 10;
		padding-right: 20px;
		padding-left: 10px;
		&:focus, &:active {
			width: 100%; } }
	button.sb-icon-search {
		height: 30px;
		z-index: 90;
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		text-align: center;
		cursor: pointer; } }

@media all and (max-width: 1090px) {
	.pad-if-multirow {
		padding-bottom: 20px; }
	.big-input {
		font-size: auto !important; } }

label.label {
	font-family: 'Helvetica Neue', Helvetica, 'Segoe UI', Arial, sans-serif;
	display: inline-block;
	padding: 2px 4px;
	margin-right: 4px;
	font-size: 11.844px;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 14px;
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15);
	white-space: nowrap;
	vertical-align: baseline;
	background-color: rgba(black, 0.35);
	border-radius: 3px;
	&.tiny-outline {
		padding: 1px 4px 3px;
		background-color: $gray-light3;
		float: left;
		margin-right: 6px;
		text-shadow: 0 1px 0 #bdc2c7, 0 2px 0 #b8bdc2, 0 3px 1px rgba(0,0,0,.15), 0 0 3px rgba(0,0,0,.15), 0 1px 3px rgba(0,0,0,.3);
		&.line-height30 {
			margin-top: 6px; }
		&.tiny-fixed-width {
			width: 1.5em;
			text-align: center; } } }

.icon-tiny-sibling {
	margin-left: 4px;
	margin-right: 6px; }

/* Radio buttons */

[type="radio"] {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px; }

label.radio {
	display: inline-block;
	cursor: pointer;
	line-height: 1.5;
	font-size: 1.066666em;
	margin: 0 0.666em 0 0;
	color: $gray-copy; }

/* the basic, unchecked style */
[type="radio"] + span:before {
	content: '';
	display: inline-block;
	width: 1em;
	height: 1em;
	vertical-align: -0.125em;
	border: 0.15em solid #fff;
	margin-right: 0.5em;
	@include border-radius(1em);
	@include box-shadow(0 0 0 0.15em $gray-light5);
	@include anim(300ms, ease); }

/* the checked style using the :checked pseudo class */
[type="radio"]:checked + span:before {
	background: $highlight;
	@include box-shadow(0 0 0 0.25em $gray-light3); }

/* when radio is focused */
[type="radio"]:focus + span:before {
	@include box-shadow-2(0 0 0 0.15em $gray-light5, 0 0 5px 0 rgba($highlight,0.15)); }

[type="radio"]:checked:focus + span:before {
	@include box-shadow-2(0 0 0 0.25em $gray-light4, 0 0 5px 0 rgba($highlight,0.15)); }

/* CHECKBOXES */

/* Base for label styling */
.normCheck[type="checkbox"] {
	&:not(:checked), &:checked {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px; }
	&:not(:checked) + label, &:checked + label {
		position: relative;
		cursor: pointer;
		margin: 0 0.666em 0 0;
		padding-left: 1.5em;
		@include anim(300ms, ease); }

	/* checkbox aspect */
	&:not(:checked) + label:before, &:checked + label:before {
		content: '';
		position: absolute;
		top: 2px;
		left: 0;
		width: 1em;
		height: 1em;
		background: white;
		@include border-radius(3px);
		@include anim(300ms, ease); }

	&:not(:checked) + label:before {
		@include box-shadow(0 0 0 0.15em $gray-light5); }
	&:checked + label:before {
		@include box-shadow(0 0 0 0.25em $gray-light3); }

	/* checked mark aspect */
	&:not(:checked) + label:after, &:checked + label:after {
		content: '\f00c';
		font: normal normal normal 0.75em/1 FontAwesome;
		position: absolute;
		top: 4px;
		left: 2px;
		width: 1em;
		height: 1em;
		color: $highlight;
		@include anim(300ms, ease); }

	/* checked mark aspect changes */
	&:not(:checked) + label:after, &:disabled + label:after {
		opacity: 0;
		transform: scale(0); }
	&:not(:disabled):checked + label:after {
		opacity: 1;
		transform: scale(1); }

	/* disabled checkbox */
	&:disabled {
		+ label, + label:hover {
			color: $gray-light3; }

		&:not(:checked) + label:before {
			@include box-shadow(0 0 0 0.15em $gray-lighter);
			background-color: $gray-lighter; }

		&:checked + label {
			&:before {
				@include box-shadow(0 0 0 0.25em $gray-lighter);
				background-color: $gray-lighter; }
			&:after {
				color: $gray-light3; } } }

	/* accessibility */
	&:checked:focus + label:before {
		@include box-shadow(0 0 0 0.25em $highlight); }
	&:not(:checked):focus + label:before {
		@include box-shadow(0 0 0 0.15em lighten($highlight, 10%)); }

	/* hover style just for information */
	+ label:hover {
		color: $highlight; }
 }	// + label:hover:before

/* Placeholders */

.form-control::-moz-placeholder {
	@include quickanim;
	font-style: italic;
	opacity: 1;
	color: $gray-light3; }

.form-control:-ms-input-placeholder {
	@include quickanim;
	font-style: italic;
	opacity: 1;
	color: $gray-light3; }

.form-control::-webkit-input-placeholder {
	@include quickanim;
	font-style: italic;
	opacity: 1;
	color: $gray-light3; }

.form-control:hover::-moz-placeholder, .form-control.hover::-moz-placeholder {
	opacity: 1;
	color: $gray-light; }

.form-control:hover::-ms-input-placeholder, .form-control.hover::-ms-input-placeholder {
	opacity: 1;
	color: $gray-light; }

.form-control:hover::-webkit-input-placeholder, .form-control.hover::-webkit-input-placeholder {
	opacity: 1;
	color: $gray-light; }

.form-control:focus::-moz-placeholder, .form-control.focus::-moz-placeholder, .form-control:active::-moz-placeholder, .form-control.active::-moz-placeholder {
	opacity: 1;
	color: $highlight; }

.form-control:focus::-ms-input-placeholder, .form-control.focus::-ms-input-placeholder, .form-control:active::-ms-input-placeholder, .form-control.active::-ms-input-placeholder {
	opacity: 1;
	color: $highlight; }

.form-control:focus::-webkit-input-placeholder, .form-control.focus::-webkit-input-placeholder, .form-control:active::-webkit-input-placeholder, .form-control.active::-webkit-input-placeholder {
	opacity: 1;
	color: $highlight; }

/*Placeholders with error on field*/

// .form-control.ng-invalid.ng-dirty::-moz-placeholder
// 	font-style: italic
// 	opacity: 1
// 	color: $nimblic-red-light

// .form-control.ng-invalid.ng-dirty:-ms-input-placeholder
// 	font-style: italic
// 	opacity: 1
// 	color: $nimblic-red-light

// .form-control.ng-invalid.ng-dirty::-webkit-input-placeholder
// 	font-style: italic
// 	opacity: 1
// 	color: $nimblic-red-light

// .form-control.ng-invalid.ng-dirty:focus::-moz-placeholder, .form-control.ng-invalid.ng-dirty.focus::-moz-placeholder, .form-control.ng-invalid.ng-dirty:active::-moz-placeholder, .form-control.ng-invalid.ng-dirty.active::-moz-placeholder
// 	opacity: 1
// 	color: $nimblic-red

// .form-control.ng-invalid.ng-dirty:focus::-ms-input-placeholder, .form-control.ng-invalid.ng-dirty.focus::-ms-input-placeholder, .form-control.ng-invalid.ng-dirty:active::-ms-input-placeholder, .form-control.ng-invalid.ng-dirty.active::-ms-input-placeholder
// 	opacity: 1
// 	color: $nimblic-red

// .form-control.ng-invalid.ng-dirty:focus::-webkit-input-placeholder, .form-control.ng-invalid.ng-dirty.focus::-webkit-input-placeholder, .form-control.ng-invalid.ng-dirty:active::-webkit-input-placeholder, .form-control.ng-invalid.ng-dirty.active::-webkit-input-placeholder
// 	opacity: 1
// 	color: $nimblic-red

