.animate-messages {
  opacity: 0;
  height: 0;
  &.ng-active, &.ng-inactive {
    transition: all 200ms ease-in-out; }
  &.ng-active {
    opacity: 1;
    height: 34px; }
  &.ng-inactive {
    opacity: 0;
    height: 0; } }


.animate-height {
  height: 0px;
  background-color: coral;
  color: white;
  animation: slideHeight 2s infinite; }

/* Chrome, Safari, Opera */
@-webkit-keyframes slideHeight {
    50% {
      height: 200px; } }

/* Standard syntax */
@keyframes slideHeight {
   50% {
    height: 200px; } }
