
@import "common/colors.sass";
@import "common/mixins.sass";

.modal-body {
	// font-size: 1.4em
	.more-info {
		font-size: 1em; }
	a.show-more {
		color: $highlight;
		font-size: 1em;
		display: block; } }

.modal-title {
	color: $gray-dark; }

.modal-icon-header {
	padding: 2em 0;
	text-align: center;
	color: white; }

&.modal-error {
	.modal-icon-header {
		background-color: lighten($nimblic-red, 53%);
		color: $nimblic-red;
		border-radius: 5px 5px 0 0; } }
&.modal-warning {
	.modal-icon-header {
		background-color: lighten($urgent, 53%);
		color: $urgent; } }
&.modal-info, &.modal-sent {
	.modal-icon-header {
		background-color: $highlight; } }
&.modal-cancel, &.modal-fwd, &.modal-annotation {
	.modal-icon-header {
		background-color: $gray; } }

.modal-footer {
	border: none; }
