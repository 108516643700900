// @import "./bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss"
@import "common/colors.sass";
@import "common/mixins.sass";

body {
	// adds padding to allow for navbar
	padding-top: 51px;
	background-color: $gray-light5;
	-webkit-font-smoothing: antialiased; }

h4, h5, h6, .h4, .h5, .h6 {
	// Overrides bootstrap
	font-weight: 700; }

h1.h1-admin {
	color: black;
	padding-top: 20px;
	font-size: 30px;
	margin-top: 0; }

footer {
	background-color: white;
	border-top: 1px solid $gray-lighter;
	padding-top: 2em;
	margin-top: 4em; }

blockquote {
	border-left: 3px solid  $gray-light4;
	font-style: oblique;
	padding-top: 6px;
	margin-top: 1em;
	margin-bottom: 1em; }

a {
	@include quickanim;
	cursor: pointer;
	&:hover, &:focus {
		color: auto;
		text-decoration: underline;
		i.fa.fa-margR {
			text-decoration: none; } }
	i.fa.fa-margR {
		margin-right: 0.2em; }
	&.white {
		color: rgba(white, 0.9);
		&:hover, &:focus, &:active, &.active {
			color: white; } }
	&.red {
		color: $nimblic-red;
		&:hover, &:focus, &:active, &.active {
			color: darken($nimblic-red, 10%); } }
	&.blue {
		color: lighten($highlight, 10%);
		&:hover, &:focus, &:active, &.active {
			color: $highlight;
			text-decoration: none; } } }

/* SVGs */
i.svg-10 svg {
	width: 10px;
	height: 10px;
	margin: 0; }

i.svg-12 svg {
	width: 12px;
	height: 12px;
	margin-left: 3px;
	margin-right: 3px; }

i.svg-16 svg {
	width: 16px;
	height: 16px;
	margin-left: 3px;
	margin-right: 3px; }

i.svg-24 svg {
	width: 24px;
	height: 24px;
	margin-left: 4px;
	margin-right: 4px; }

i.svg-32 svg {
	width: 32px;
	height: 32px;
	margin-left: 4px;
	margin-right: 4px; }

i.svg-48 svg {
	width: 48px;
	height: 48px;
	margin-left: 4px;
	margin-right: 4px; }

i.svg-64 svg {
	width: 64px;
	height: 64px;
	margin-left: 4px;
	margin-right: 4px; }

i svg {
	fill: currentColor;
	display: inline-block; }

i.no-margin svg {
	margin: 0; }

/* helper classes */
.italic {
	font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-style: italic;
	font-weight: 400; }

.normal {
	font-weight: 400; }

.uppercase {
	text-transform: uppercase; }

.red {
	color: $nimblic-red; }

.urgent {
	color: $urgent; }

.base {
	color: $gray-base; }

.nowrap {
	white-space: nowrap; }

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; }

.pad-top {
	padding-top: 1em; }

.pad-bot {
	padding-bottom: 1em; }

.pad-top40 {
	padding-top: 40px; }

.pad-top-bot {
	padding-top: 10px;
	padding-bottom: 10px; }

.pad-sides {
	padding-left: 1em;
	padding-right: 1em; }

.text-medium {
	font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 500; }

.white-bg {
	background-color: white; }

.inline-block {
	display: inline-block; }

.copy {
	color: $gray-copy; }

.dark {
	color: $gray-base; }

.highlight-clr {
	color: $highlight; }

.dark-copy {
	color: darken($gray-copy, 20%); }

.strikethru {
	text-decoration: line-through; }

.no-border {
	border: none; }

.nav-tabs.no-border > li {
	margin-bottom: 0; }

.pointer {
	cursor: pointer; }

.heading-sentence {
	text-transform: capitalize; }

.gray-bg {
	background-color: $gray-light5; }

.highlight-bg {
	background-color: $highlight-med; }

.inline-block {
	display: inline-block; }

.inline {
	display: inline; }

//- TODO: Laura - merge these classes
.margin-0, .no-margin {
	margin: 0; }

.right {
	float: right; }

.text-big {
	font-size: 1.5em; }

h1, h2, h3, h4, h5, h6 {
	&.heading-nmt {
		margin-top: 0; } }

.row-nomargin {
	margin-left: 0 !important;
	margin-right: 0 !important; }

.pad-col {
	padding-top: 10px; }
.pad-col2 {
	padding-top: 6px; }

/* layout classes move this elsewhere */

.gray-container {
	background-color: #F5F5F5;
	padding: 1.5em; }

.trans-container {
	padding: 0 1.5em; }

.white-container-skinny {
	background-color: white;
	padding: 0.5em 1em; }

.gray-container, .task-container, .trans-container, .white-container, .white-container-skinny {
	&.margin-bottom {
		margin-bottom: 0.5em; } }

/* responsive utility classes */

.width80 {
	width: 80%; }

.width100 {
	width: 100%; }

.push-down {
	padding-top: 10px;
	@media all and (min-width: 768px) {
		padding-top: 30px; }
	@media all and (min-width: 992px) {
		padding-top: 50px; }
	@media all and (min-width: 1200px) {
		padding-top: 60px; } }
