/*NAV BAR*/

// TODO: Laura refactor to remove colors
$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;  // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-light1:            lighten($gray-base, 53%) !default; // #888
$gray-light2:            lighten($gray-base, 60%) !default; // #999
$gray-light3:						 lighten($gray-base, 70%) !default; // #b3b3b3
$gray-light4:						 lighten($gray-base, 80%) !default; // #ccc
$gray-light5:						 lighten($gray-base, 88%) !default; // #e0e0e0
$gray-light6:						 lighten($gray-base, 90%) !default; // #e5e5e5
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee
$gray-ultra-light: 			 lighten($gray-base, 96%) !default; // #f5f5f5
$gray-white: 			 			 lighten($gray-base, 98%) !default; // #fafafa

$gray-copy:							 #6c6c70 !default;
// END TODO:

$nimblic-red: #cd1419;
$nimblic-red-bright: #e52c39;
$nimblic-red-light: #eba2a7;
$nimblic-red-dark: #af0b17;
$nimblic-brick: #7d1714;

$MET: 				  #cd1419 !default;
$preMET: 			  #d96616 !default;
$urgent:        #d99800 !default;
$codeBLUE:      #0099cc !default;

$puce-light: #eff2e6;

$turquoise: #099e9e;
$turquoise-bright: #2bb5b7;
$turquoise-light: #def2ee;
$turquoise-very-bright: #1ba5a7;
$turquoise-disabled: #84CFCF;

$blue: #0099cc;
$green: #339901;
$amber: #edb200;
$amber-ultra-light: #f7eed4;
$orange: #eb6f02;
$red: #ff0000;

$blue10per: #f0f8fa;
$green10per: #eef5eb;
$amber10per: #faf8f0;
$orange10per: #faf5f0;
$red10per: #faf0f0;


$but-enabled:        $turquoise-bright;
$but-enabled-hover:  $turquoise-very-bright;
$but-active:         $turquoise-bright;
$but-disabled:       $gray;

$highlight-light: #d8e5f0;
$highlight-med: #b4cbe0;
// $highlight: #428bca
$highlight: #627bcc;
$highlight-muddy: #3d5266;
